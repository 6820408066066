import React, { useState, useEffect } from "react";
import Loader from "../utils/loader";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import userService from "../services/userService";
import { toast, ToastContainer } from "react-toastify";
import CryptoJS from 'crypto-js';
import Group244 from "../static/Group244.png";
import Group5702x from "../static/Group5702x.png";
import { Link, withRouter } from "react-router-dom";
// import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { apiUrl, imgUrl } from "../services/config.json";
import "./LoginForm.css";
import { getHomeCMS } from "../services/adminServices";
import {
    Card,
    InputGroup,
    FormControl,
    Form,
    Button,
    ButtonGroup,
    Carousel,
} from "react-bootstrap";
import loginBackground from "../static/loginBackground.png";
import PetLogo from "../static/POET_Logo_Mid.png"
import googleSignin from "../static/signup_login/googleSignin.png"
import facebookSignin from "../static/signup_login/facebookSignin.png"
import tick from "../static/signup_login/Check.png"

import Login1 from "../static/login-3.png";
import InputBoxMui from "./ReusableComponents/InputBoxMui";
import CheckboxMui from "./ReusableComponents/CheckBoxMui";
import InputBoxPasswordMui from "./ReusableComponents/InputBoxPasswordMui";
import InputBoxMobile from "./ReusableComponents/InputBoxforMobile";
import SelectDropdownMui from "./ReusableComponents/SelectDropdownMui";
import OtpInputBox from "./ReusableComponents/OtpInputBox";
import { sendOtp, verifyOtp } from "../services/authService";
import { countries } from "./ReusableComponents/countryList";



const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: "100%",
    },
    dense: {
        marginTop: 19,
    },
}));

function SignUp({ history }) {
    const classes = useStyles();
    const [email, setEmail] = useState("");
    const [values, setValues] = useState("");
    const [username, setUserName] = useState("");
    const [organizationName, setOrganizationName] = useState("");
    const [organizationId, setOrganizationId] = useState("");
    const [password, setPassword] = useState("");
    const [isTermsAccepted, setAcceptTerms] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState("");
    const [disable, setdisable] = useState(true);
    const [showPassword] = useState(false);
    const [cms, setCms] = useState({});
    const [roleList, setRoleList] = useState([
        {
            "value": "Individual",
            "name": "Individual"
        },
        {
            "value": "Organization",
            "name": "Organization"
        }
    ]);
    const [role, setRole] = useState("")
    const [userRoleList, setUserRoleList] = useState([
        {
            "value": "Team Member",
            "name": "Team Member"
        },
        {
            "value": "Team Leader",
            "name": "Team Leader"
        },
        {
            "value": "Manager",
            "name": "Manager"
        },
    ])
    const [userRole, setUserRole] = useState("")
    const [region, setRegion] = useState("")
    const [countryList, setCountryList] = useState([])
    const [otpValue, setOtpValue] = useState("")
    const [countryCode, setCountryCode] = useState("")
    const [mobileNo, setMobileNo] = useState("")
    const [isOtpShown, setIsOtpShown] = useState(false)
    const [isOtpVerify, setIsOtpVerify] = useState(false)
    const [verifyDisable, setVerifyDisable] = useState(true)
    const [timer, setTimer] = useState(0);
    const [isResendCodeDisable, setisResendCodeDisable] = useState(true)
    const [isResendButton, setIsResendButton] = useState(false)
    const [isPasswordValid, setIsPasswordValid] = useState(true);

    const selectedCountry = countries.find(country => country.phone === countryCode.slice(1));
    const countryName = selectedCountry ? selectedCountry.label : "";
    const getCms = async () => {
        let cms = await getHomeCMS();
        setCms(cms.data);
        console.log(cms);
    };

    useEffect(() => {
        getCms();
    }, []);

    async function handleRegisterSubmit() {
        if (!username) {
            toast.warn("Please enter the username");
            return;
        }
        if (!email) {
            toast.warn("Please enter the email");
            return;
        }
        if (role == "Organization") {
            // if (!region) {
            //     setLoading(false);
            //     toast.warn("Please enter Region");
            //     return
            // }
            if (!organizationName) {
                toast.warn("Please enter Organization Name");
                return;
            }
            if (!organizationId) {
                toast.warn("Please enter Organization ID");
                return;
            }
            if (!userRole) {
                toast.warn("Please select User Role");
                return;
            }
        }
        if (!password) {
            toast.warn("Please enter the password");
            return;
        }
        if (!mobileNo) {
            toast.warn("Please enter the Mobile Number");
            return;
        }
        if (!isTermsAccepted) {
            toast.warn("Please accept the Terms & Conditions");
            return;
        }
        if (password.length < 6) {
            toast.warn("Password must contain at least 6 characters");
        } else {
            const hashedPassword = CryptoJS.SHA256(password).toString();
            let user = {
                username: username,
                email: email,
                // password: password,
                password: hashedPassword,
                phone: countryCode + mobileNo,
                // organizationId: organizationId,
                // organizationName: organizationName,
                referedEmail:
                    localStorage.getItem("referedEmail") !== "undefined" || null
                        ? localStorage.getItem("referedEmail")
                        : "",
                Role: role,
                organizationName: role == "Organization" ? organizationName : "",
                organizationId: role == "Organization" ? organizationId : "",
                userRole: role == "Organization" ? userRole : "",
                // region: role == "Organization" && region,
                country: countryName,
            };
            console.log("user_", user);
            setLoading(true)
            const result = await userService.register(user);
            setLoading(false)
            console.log(result);
            if (result.status !== 200) {
                toast.warn(result.message);
            } else {
                localStorage.removeItem("referedEmail");
                localStorage.removeItem("accountType");
                toast.info(result.message);
                history.push({
                    pathname: `/afterRegistration`,
                    state: { detail: user },
                });
                // toast.info("Please check your email to verify");
            }
        }
    }
    function handleAcceptTerms(e) {
        console.log("value", e.target.checked);
        const isChecked = e.target.checked;
        setAcceptTerms(isChecked);
    }
    function handlePassword(e) {
        let pass = e.target.value;
    
        if (pass.length > 0) {
            if (pass.length <= 7) {
                setErrors("Password contains minimum 8 Characters");
                setIsPasswordValid(false);
            } else {
                setErrors("");
    
                var upperCaseLetters = /[A-Z]/g;
                var numbers = /[0-9]/g;
                var specialCharacters = /[!@#$%^&*(),.?":{}|<>]/g; // Add your desired special characters inside the brackets
    
                if (!pass.match(upperCaseLetters)) {
                    setErrors("Password contains at least 1 Capital letter");
                    setIsPasswordValid(false);
                } else if (!pass.match(numbers)) {
                    setErrors("Password contains at least 1 number");
                    setIsPasswordValid(false);
                } else if (!pass.match(specialCharacters)) {
                    setErrors("Password contains at least 1 special character");
                    setIsPasswordValid(false);
                } else {
                    setErrors("");
                    setIsPasswordValid(true);
                    setPassword(pass);
                }
            }
        } else {
            setErrors("");
            setIsPasswordValid(true);
        }
    }
    
    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleRole = (e) => {
        console.log("eData_", e)
        setRole(e.target.value)
    }

    const handleUserRole = (e) => {
        console.log("eData_", e)
        setUserRole(e.target.value)
    }

    const handleRegion = (e) => {
        console.log("handleRegion", e, region)
        setRegion(e.target.value)
    }

    const handleCountry = (event, value) => {
        console.log("params_", event, value)
        if (value.phone !== "" && mobileNo !== "") {
            setVerifyDisable(false)
            setIsOtpShown(false)
            setIsResendButton(false)
        } else {
            setVerifyDisable(true)
        }
        setCountryCode(`+${value.phone}`)
    }

    const handleNumber = (e) => {
        console.log("eData_", e, e.length, countryCode);
    
        // Map for country-specific phone number lengths
        const countryLengthMap = {
            "+1": 10,  // United States, Canada
            "+44": 10, // United Kingdom
            "+61": 9,  // Australia
            "+91": 10, // India
            "+86": 11, // China
            "+49": 11, // Germany
            "+33": 9,  // France
            "+81": 11, // Japan
            "+82": 10, // South Korea
            "+55": 11, // Brazil
            "+52": 10, // Mexico
            "+7": 10,  // Russia
            "+27": 9,  // South Africa
            "+966": 9, // Saudi Arabia
            "+39": 10, // Italy
            "+34": 9,  // Spain
            "+54": 10, // Argentina
            "+62": 11, // Indonesia
            "+234": 10, // Nigeria
            "+20": 9,  // Egypt
            "+90": 10, // Turkey
            "+971": 9, // UAE
            "+63": 10, // Philippines
            "+92": 10, // Pakistan
            "+880": 10, // Bangladesh
            "+60": 10, // Malaysia
            "+66": 9,  // Thailand
            "+84": 10, // Vietnam
            "+254": 9, // Kenya
            "+57": 10, // Colombia
            "+56": 9,  // Chile
            "+51": 9,  // Peru
            "+58": 10, // Venezuela
            "+972": 9, // Israel
            "+64": 9,  // New Zealand
            "+65": 8,  // Singapore
            "+41": 9,  // Switzerland
            "+31": 9,  // Netherlands
            "+30": 10, // Greece
            "+46": 10, // Sweden
            "+47": 8,  // Norway
            "+45": 8,  // Denmark
            "+358": 10, // Finland
            "+351": 9, // Portugal
            "+48": 9,  // Poland
            "+32": 9,  // Belgium
            "+43": 11, // Austria
            "+420": 9, // Czech Republic
            "+36": 9,  // Hungary
            "+40": 9   // Romania
        };
    
        // Get the required length for the given country code
        const maxLength = countryLengthMap[countryCode] || 10; // Default to 10 if country code isn't found
    
        // Validation based on country-specific length
        if (e.length > maxLength) {
            return;
        }
    
        // Check for valid lengths for China or other countries
        let isChina = e.length === 11 && countryCode === "+86";
        let isOtherCountries = e.length === maxLength && countryCode !== "+86" && countryCode !== "";
    
        if (isChina || isOtherCountries) {
            setVerifyDisable(false);
            setIsOtpShown(false);
            setIsResendButton(false);
        } else {
            setVerifyDisable(true);
        }
    
        setMobileNo(e);
    };
    

    const handleOTP = async (e) => {
        console.log("otpdata_", e.length, e)
        setOtpValue(e)

        if (e.length == 6) {
            setLoading(true)
            let payload = {
                "phone": countryCode + mobileNo,
                "otp": e
            }
            let verifyOtpresponse = await verifyOtp(payload)
            console.log("verifyOtp_", verifyOtpresponse)
            if (verifyOtpresponse.status == 200) {
                setLoading(false)
                setIsOtpShown(false)
                setIsOtpVerify(true)
                setdisable(false)
                setisResendCodeDisable(false)
                setIsResendButton(false)
            } else {
                setLoading(false)
                setIsOtpVerify(false)
                setIsOtpShown(true)
                setisResendCodeDisable(true)
                setIsResendButton(true)
            }
        }
    }

    // useEffect(()=>{
    //     if(role !=="" && username !== "" && email !== "" && mobileNo !== "" && password !== ""){
    //         setdisable(false)
    //     } else{
    //         setdisable(true)
    //     }
    // },[role,username,email,mobileNo,password])
    
    
    const handleVerify = async () => {
        console.log("resendOTP")
        setOtpValue("")
        setTimer(60)
        // setVerifyDisable(true)
        setIsOtpVerify(false)
        // setIsOtpShown(false)
        
        if (!isPasswordValid) {
            toast.warn("Please enter a valid password.");
            return;
        } 

        let payload = {
            // "email": email,
            "phone": countryCode + mobileNo
        }
        
        let sentresponse = await sendOtp(payload);
        console.log("sentresponse_", sentresponse.message)

        if (sentresponse.status == 200) {
            toast.success("OTP Sent Successfully")
            setIsResendButton(true)
            setIsOtpShown(true)
        } else {
            toast.warn("Invalid Phone Number")
            return
        }
    }

    useEffect(() => {
        const timerId = setTimeout(() => {
            if (timer > 0) {
                setTimer(timer - 1);
            }
        }, 1000);
        if (timer == 0) {
            setisResendCodeDisable(false);
            setIsOtpShown(false)
        } else {
            setisResendCodeDisable(true);

        }
        return () => {
            clearTimeout(timerId);
        };
    }, [timer]);

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    useEffect(() => {
        fetchCountryList()
    }, [])

    const fetchCountryList = () => {
        let finalArr = []
        countries.map((obj) => {
            finalArr.push({
                name: obj.label,
                value: obj.label
            })
        })
        setCountryList(finalArr)
    }

    return (
        <div className="container-fluid"
            style={{
                backgroundImage: `url(${Login1})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                height: "auto",
                minHeight: "-webkit-fill-available",
                position: "absolute",
                top: 0,
                left: 0,
                // position: "relative"
            }}
        >
            <ToastContainer />
            <Loader loading={loading} />
            <div className="row mt-2 px-3 py-2 col-md-12">
                <div className="d-flex col-5">
                    <Link to="/">
                        <img
                            className="petLogo"
                            alt="Group570"
                            src={PetLogo}
                            style={{width:'35%'}}
                        // src={
                        //     cms && cms.Navbar_logo
                        //         ? cms.Navbar_logo.Image
                        //         : `${imgUrl}/pet-website-static-images/Group+571.png`
                        // }
                        ></img>
                    </Link>
                </div>

                <div className="d-flex justify-content-center col-7">
                    <Card
                        className="justify-content-center card-comp px-5 p-3"
                    >
                        <div className="text-center">
                            <h3 className="mainHeading">Signup</h3>
                        </div>
                        <div className="mt-3 py-1">
                            <SelectDropdownMui
                                optionsList={roleList}
                                selectLabel={"Role"}
                                onChange={handleRole}
                                value={role}
                            />
                        </div>

                        {/* <div className="py-2">
                            <InputBoxMui
                                fullWidth
                                type="text"
                                label="Role"
                                // value={username}
                                onChange={(e) => setUserName(e.target.value)}
                            // placeholder="Username"
                            />
                        </div> */}

                        {role == "Organization" &&
                            <>
                                {/* <div className="mt-2 py-1">
                                    <SelectDropdownMui
                                        optionsList={countryList}
                                        selectLabel={"Region*"}
                                        onChange={handleRegion}
                                        value={region}
                                    />
                                </div> */}

                                <div className="mt-2 py-1">
                                    <InputBoxMui
                                        fullWidth
                                        type="text"
                                        label="Organization*"
                                        // value={username}
                                        onChange={(e) => setOrganizationName(e.target.value)}
                                    // placeholder="Username"
                                    />
                                </div>

                                <div className="mt-2 py-1">
                                    <InputBoxMui
                                        fullWidth
                                        type="text"
                                        label="Organization ID*"
                                        // value={username}
                                        onChange={(e) => setOrganizationId(e.target.value)}
                                    // placeholder="Username"
                                    />
                                </div>

                                <div className="mt-2 py-1">
                                    <SelectDropdownMui
                                        optionsList={userRoleList}
                                        selectLabel={"User Role"}
                                        onChange={handleUserRole}
                                        value={userRole}
                                    />
                                </div>
                            </>
                        }

                        <div className="mt-2 py-1">
                            <InputBoxMui
                                fullWidth
                                type="text"
                                label="Username*"
                                // value={username}
                                onChange={(e) => setUserName(e.target.value)}
                            // placeholder="Username"
                            />
                        </div>



                        <div className="mt-2 py-1">
                            <InputBoxMui
                                fullWidth
                                type="email"
                                label="Email*"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            // placeholder="Email"
                            />
                            {/* <p className="p-2" style={{ color: "red", fontSize: "10px", textAlign: "left" }}>
                                {emailerrors}
                            </p> */}
                        </div>


                        <div className="mt-2 py-1">
                            <InputBoxPasswordMui
                                fullWidth
                                label="Password"
                                type={values.showPassword ? "text" : "password"}
                                value={values.password}
                                onChange={(e) => handlePassword(e)}
                            // placeholder="Password"
                            />
                            <p className="p-2" style={{ color: "red", fontSize: "10px", textAlign: "left" }}>
                                {errors}
                            </p>
                        </div>

                        <div className="mt-2 py-1 row">
                            <div className="col-md-9">
                                <InputBoxMobile
                                    onChange={(e) => handleNumber(e)}
                                    handleCountryCode={handleCountry}
                                    value={mobileNo}
                                />
                            </div>
                            {/* <div className=""> */}
                            <Button
                                variant="primary"
                                className="loginBtnVerify"
                                // disabled={disable}
                                disabled={verifyDisable}
                                onClick={handleVerify}
                            >
                                Verify
                            </Button>
                            {/* </div> */}
                        </div>
                        {isOtpShown &&
                            <>
                                <div className="mt-2 d-flex justify-content-center">
                                    <OtpInputBox value={otpValue} onChange={(e) => handleOTP(e)} />
                                </div>
                                {(!isOtpVerify && otpValue?.length == 6) && <div>
                                    <p className="errorMsg mt-1">Please enter the correct OTP </p>
                                </div>
                                }
                            </>}

                        <div className="mt-2 d-flex">
                            {isResendButton && <Button className="otpresend p-0" onClick={handleVerify} disabled={isResendCodeDisable}>Resend Code</Button>}
                            {/* <a href="handleResend">Resend Code</a> */}
                            {isOtpShown && <p className="ml-5"> {isOtpShown && formatTime(timer)}</p>}
                        </div>

                        {(isOtpVerify) &&
                            <div className="d-flex mt-2">
                                <p className="verifyMsg">Phone Number Verified </p><img className="tickicon ml-2 mt-1" src={tick}></img>
                            </div>
                        }

                        <div className="">
                            <CheckboxMui
                                label="I agree with terms and conditions"
                                isChecked={isTermsAccepted}
                                onChange={(e) => handleAcceptTerms(e)}
                            />
                        </div>

                        <div className="text-center"
                        // style={{ marginTop: "2%" }}
                        >
                            <Button
                                variant="primary"
                                className="loginBtnRegister"
                                disabled={disable}
                                onClick={handleRegisterSubmit}
                            >
                                Sign up
                            </Button>
                        </div>

                        <div className="text-center mt-1">
                            <p
                                style={{
                                    // fontSize: "10px",
                                    fontSize: "0.9rem",
                                    lineHeight: "1.67",
                                    textAlign: "center",
                                    color: "#43425d",
                                }}
                            >
                                Already have an account?{" "}
                                <Link
                                    to="/login"
                                    className="signinLink"
                                    style={{ cursor: "pointer", fontSize: "0.9rem" }}
                                >
                                    Login
                                </Link>
                            </p>
                        </div>

                        {/* {role !== "Organization" &&
                            <>
                                <p className="hr-lines ">OR</p>
                                <div className="text-center">
                                    <Button
                                        variant="primary"
                                        className="smBtn"
                                        href="https://api.mypoet360.com/api/auth/google"
                                        // href={`${apiUrl}/auth/google`}
                                    >
                                        <img
                                            className="smImg"
                                            src={googleSignin}
                                        />
                                        <a
                                            href="https://api.mypoet360.com/api/auth/google"
                                            // className="googlefblink"
                                        >Sign up with <strong>Google</strong></a>

                                    </Button>
                                </div>

                                <div className="mt-2 text-center">
                                    <Button
                                        variant="primary"
                                        className="smBtn"
                                        // href="https://api.mypoet360.com/api/auth/facebook"
                                        href={`${apiUrl}/auth/facebook`}
                                    >
                                        <img
                                            className="smImg"
                                            src={facebookSignin}
                                        />
                                        <a
                                            // href="https://www.professionaleditingtool.com/api/auth/facebook"
                                            // href="https://api.mypoet360.com/api/auth/facebook"
                                            className="googlefblink"
                                        >Sign up with <strong>Facebook</strong></a>

                                    </Button>
                                </div>
                            </>
                        } */}
                        <div className="text-center mt-2">
                            <Link to="/privacypolicy" target={"_blank"} className="privacyLink">
                                Term of use. Privacy policy
                            </Link>
                        </div>

                    </Card>
                </div>
            </div >
        </div >
    );
}
export default withRouter(SignUp);

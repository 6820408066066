import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";

export const dummyData = [
    ["Element", "Density", { role: "style" }],
    ["Copper", 8.94, "#b87333"], // RGB value
    ["Silver", 10.49, "silver"], // English color name
    ["Gold", 19.3, "gold"],
    ["Platinum", 21.45, "color: #e5e4e2"], // CSS-style declaration
];

export function BarChartGoogle(props) {
    console.log("BarChartGoogleprops", props.data)
    const [chartData, setChartData] = useState()
    const [minDensity, setMinDensity] = useState()
    const [maxDensity, setMaxDensity] = useState()
    const [ticks, setTicks] = useState()

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
 
  // Update windowWidth on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
 
    window.addEventListener('resize', handleResize);
    // if(window.innerWidth <768){
    //   setSidebarOpen(!isSidebarOpen)
    // }
 
    return () => window.removeEventListener('resize', handleResize);
  }, []);

    const convertDataFormat = () => {
        let rawData = { props }
        let barColors = ['#FF0000', '#00FF00', '#0000FF', '#FFFF00', '#FF00FF', '#00FFFF', '#000000']
        rawData.props.data["colors"] = barColors
        console.log("rawData", rawData)
        let finalData = []
        // finalData.push([props.hAxisTitle, props.vAxisTitle, { role: "style" }]);
        let { xAxisList, xAxisValues, colors } = rawData.props.data
        for (let i = 0; i < xAxisList.length; i++) {
            let hAxisBar = xAxisList[i]
            let hAxisValue = xAxisValues[i]
            let style = colors[i]
            finalData.push([hAxisBar, hAxisValue, style])
        }
        // return data
        let densities = finalData.map((obj) => obj[1])
        let minDensity = Math.min(...densities)
        let maxDensity = Math.max(...densities)
        let step = (maxDensity - minDensity) / 5
        let ticks = Array.from({ length: 6 }, (_, i) => minDensity + i * step)
        console.log("densities", densities, minDensity, maxDensity, step, ticks, finalData)
        setMinDensity(minDensity)
        setMaxDensity(maxDensity)
        setTicks(ticks)
        finalData.unshift([props.hAxisTitle, props.vAxisTitle, { role: "style" }]);
        console.log("finalData", finalData)
        setChartData(finalData)
    }

    useEffect(() => {
        convertDataFormat()
    }, [props])

    useEffect(() => {
        console.log("chartdata", chartData, dummyData)
    }, [chartData])

    return (
        <div className=""
            style={{
                backgroundColor: props.isDarkMode?"#2A2A2A": "#ffffff",
                borderRadius: "8px",
                boxShadow:windowWidth < 768 ? "2px 2px 5px -2px #000000" : "",
                padding: "2px",
                height:windowWidth < 768 ? "25rem" : "20rem",
                width:windowWidth < 768 ? "90vw" : "80vw"
            }}
        >
            <div className="row" style={{width:"100%"}}>
                <div className="col-md-7">
                    <h6 className='p-2'
                        style={{
                            color: props.isDarkMode?"white": "#171717",
                            fontFamily: "Poppins, sans-serif",
                            fontWeight: 600,
                            fontSize: "0.9rem"

                        }}
                    >{props.chartHeading}</h6>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="container-fluid"
                        style={{ overflowX: "scroll", overflowY: "hidden" }}
                    >
                        {undefined !== chartData &&
                           <Chart
                           chartType="ColumnChart"
                           width="100%"
                           data={chartData}
                           options={{
                               height: 200,
                               legend: 'none',
                               backgroundColor: props.isDarkMode ? "#2A2A2A" : "#ffffff", 
                               hAxis: {
                                   title: props.hAxisTitle,
                                   textStyle: { color: props.isDarkMode ? "#ffffff" : "#171717" }, 
                                   titleTextStyle: {
                                       bold: true,
                                       color: props.isDarkMode ? "#ffffff" : "#171717", 
                                   }
                               },
                               vAxis: {
                                   title: props.vAxisTitle,
                                   titleTextStyle: {
                                       bold: true,
                                       color: props.isDarkMode ? "#ffffff" : "#171717"  
                                   },
                                   viewWindow: {
                                       min: minDensity,
                                       max: maxDensity,
                                   },
                                   ticks: ticks,
                                   textStyle: { color: props.isDarkMode ? "#ffffff" : "#171717" } 
                               },
                               chartArea: {
                                   left: '10%',
                                   width: '90%'
                               }
                           }}/>
                            }
                    </div>
                </div>
            </div>
        </div>

    );
}
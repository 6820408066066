import React, { Component, useState, useEffect } from "react";
import Loader from "../utils/loader";
import { Link } from "react-router-dom";
import NumberWidget from "../components/NumberWidget";
import AdminServices from "../services/adminServices";
import Apex from "./ApexCharts";
import { Dropdown } from "react-bootstrap";
import DashboardCard from "../components/Cards/DasboardCard";
import Doc from ".././static/Doc.png";
import Group from ".././static/Group.png";
import Member from ".././static/Member.png";
import Rate from ".././static/Rate.png";
import Total from ".././static/Total.png";
import { toast, ToastContainer } from "react-toastify";
import {
    Card,
} from "react-bootstrap";
import PieChartComp from "./ReusableComponents/PieChartComp";
import PieChartMui from "./ReusableComponents/PieChartMui";
// import MonthYearPicker from "../utils/MonthYearPicker";
// import Chart from "../components/Stats";
import total_doc from ".././static/admin_dashboard/total_doc.png"
import individual_user from ".././static/admin_dashboard/users_subadmin.png"
// import registered_organization from ".././static/admin_dashboard/admin_org.png"
import { useSelector } from "react-redux";
import ImageCard from "./ReusableComponents/ImageCard";
import ApexChartsComp from "./ReusableComponents/ApexChartsComp";
import { getOrgUserPlanBreakdown, getOrgUsersForBarChart, getOrganisationData, getSuperAdminDashCards, getUserCountryCodeBreakdown, getUserPlanBreakdown, getYearList } from "../services/dashboardServices";
// import BarChartComp from "./ReusableComponents/BarChartComp";
import registered_organization from ".././static/admin_dashboard/Admin_image.png"

export function AdminDashboard() {
    const [loading, setLoading] = useState(false)

    // -----
    const [state, setState] = useState({
        loading: true,
        totalPaidUser: 0,
        totalDocuments: 0,
        totalUsers: 0,
        monthWiseDocuments: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        monthWiseUser: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        MonthWise: [],
        UserWise: [],
        selectYear: new Date().getFullYear(),
        selectMonth: new Date().getMonth() + 1,
        years: [...Array(Number(new Date().getFullYear()) - 2018)].map((year, i) => 2019 + i),
        monthList: [
            "Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"
        ],
        cardmargin: 10,
        admindashdetail: [],
        monthwisePaidusers: [],
        monthwiseFreeusers: []
    });

    const [imageCardData, setImageCardData] = useState({
        individualUsers: "0",
        registeredOrganization: "0",totalOrgUsers:"0"
    })
    const userDetails = useSelector((user) => user)
    const userID = userDetails.user.currentUser._id
    const [yearList, setYearList] = useState([])
    const [barChartYear, setBarChartYear] = useState()
    const [pieChartOrg, setPieChartOrg] = useState()
    const [organizationList, setOrganizationList] = useState([])
    const [isBarChartDropdown, setIsBarChartDropdown] = useState(false)
    const [countryCodePieChart, setCountryCodePieChart] = useState([])
    const [userPlanBreakdownPieChart, setUserPlanBreakdownPieChart] = useState([])
    const [orgUsersPlanBreakdown, setOrgUsersPlanBreakdown] = useState([
        { title: "", value: 0, color: "white" },
        { title: "", value: 0, color: "white" },
        { title: "", value: 0, color: "white" }
    ])
    const [isOrgSelectDropdown, setIsOrgSelectDropdown] = useState(false)
    const [orgUserBarChart, setOrgUserBarChart] = useState({
        xAxisBar: "", xAxisList: [], lineData: [], bar: [],
    })

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isDarkMode, setIsDarkMode] = useState(false);
    useEffect(() => {
        const updateTheme = () => {
          const theme = localStorage.getItem('ThemeSwitch');
          setIsDarkMode(theme === 'true');
        };
        updateTheme();
        window.addEventListener('storage', updateTheme);
        const originalSetItem = localStorage.setItem;
        localStorage.setItem = function (key, value) {
          originalSetItem.apply(this, arguments);
          if (key === 'ThemeSwitch') {
            updateTheme(); 
          }
        };
        return () => {
          window.removeEventListener('storage', updateTheme);
          localStorage.setItem = originalSetItem; 
        };
      }, []);
    // Update windowWidth on resize
    useEffect(() => {
      const handleResize = () => setWindowWidth(window.innerWidth);
   
      window.addEventListener('resize', handleResize);
      // if(window.innerWidth <768){
      //   setSidebarOpen(!isSidebarOpen)
      // }
   
      return () => window.removeEventListener('resize', handleResize);
    }, []);


    const data2 = [
        { title: "Onesdsad", value: 20, color: "blue" },
        { title: "Two", value: 60, color: "skyblue" },
        { title: "Three", value: 20, color: "green" }
    ]

    useEffect(() => {
        const year = new Date().getFullYear();
        fetchYearList()
        fetchOrganizationList()
        getImageCardDetails()
        getUserPlanPieChart()
        getUserCountryBreakdown()
        getDetailsAdminDash(year);
        getmonthwisePaidusers(year);
        getmonthwiseFreeusers(year);
        getOrgUserBarChartData(year);
    }, []); // Empty dependency array ensures this effect runs once on mount

    useEffect(() => {
        console.log("state", state)
    }, [state])

    const convertForPieChart = (arr) => {
        let finalArr = []
        arr.map((obj) => {
            if (obj.value > 0) {
                finalArr.push({
                    title: obj.title,
                    value: Number(obj.value),
                    color: obj.color
                })
            }
        })
        console.log("finalarr", finalArr)
        return finalArr
    }

    const fetchYearList = async () => {
        setLoading(true)
        try {
            let response = await getYearList()
            console.log("fetchYearList", response)
            if (response.status == 200) {
                let finalArr = []
                response.yearList.map((obj) => finalArr.push({ name: obj, value: obj }))
                setYearList(finalArr)
                setIsBarChartDropdown(true)
            }
        }
        catch (error) {
            console.log("error", error)
        }
        setLoading(false)
    }

    const fetchOrganizationList = async () => {
        setLoading(true)
        try {
            let response = await getOrganisationData(userID)
            console.log("fetchOrganizationList", response)
            if (response.status == 200) {
                let finalArr = []
                response.orgDetails.map((obj) => finalArr.push({ name: obj.orgName, value: obj.orgId }))
                setOrganizationList(finalArr)
                setIsOrgSelectDropdown(true)
            }
        }
        catch (error) {
            console.log("error", error)
        }
        setLoading(false)
    }

    const getImageCardDetails = async () => {
        try {
            let response = await getSuperAdminDashCards(userID)
            console.log("getImageCardDetails", response)
            let { individualUsers, registeredOrg, orgnizationUsers } = response
            setImageCardData({
                "individualUsers": individualUsers, "registeredOrganization": registeredOrg,
                "totalOrgUsers": orgnizationUsers
            })
        }
        catch (e) {
            console.log("e", e)
        }
    }

    const getUserPlanPieChart = async () => {
        try {
            let response = await getUserPlanBreakdown(userID)
            console.log("getUserPlanPieChart", response)
            let finalData = convertForPieChart(response.data)
            setUserPlanBreakdownPieChart(finalData)
            setOrgUsersPlanBreakdown(finalData)
        }
        catch (e) {
            console.log("e", e)
        }
    }

    const getUserCountryBreakdown = async () => {
        try {
            let response = await getUserCountryCodeBreakdown(userID)
            console.log("getUserCountryBreakdown", response)
            let finalData = convertForPieChart(response.data)
            setCountryCodePieChart(finalData)
        }
        catch (e) {
            console.log("e", e)
        }
    }

    const getOrgUserBarChartData = async (year) => {
        setLoading(true)
        try {
            let response = await getOrgUsersForBarChart(userID, year)
            console.log("getOrgUserBarChartData", response)
            // if (response.status == 200) {
            let finalData = response.data
            let { xAxisBar, xAxisList, bar } = finalData
            setOrgUserBarChart({
                ...orgUserBarChart,
                "xAxisBar": xAxisBar, "xAxisList": xAxisList, "bar": bar
            })
            // }

            if (bar.length == 0) {
                toast.warn("No data available")
            }

            // if (response.status !== 200) {
            //     toast.warn(response.message)
            // }
        }
        catch (e) {
            console.log("getOrgUserBarChartDataError", e)
            toast.warn("No data available")
            setOrgUserBarChart({
                ...orgUserBarChart,
                "xAxisBar": "", "xAxisList": [], "bar": []
            })
        }
        setLoading(false)
    }

    useEffect(() => {
        console.log("orgUserBarChart", orgUserBarChart)
    }, [orgUserBarChart])

    const handleChangeBarChart = (e) => {
        console.log("handleChangeBarChart", e, e.target.value)
        let value = e.target.value
        setBarChartYear(value)
        getOrgUserBarChartData(value)
    }

    const handleBarChartYear = (year) =>{
        console.log("handleBarChartYear", year)
        let currentYear = new Date().getFullYear()
        if(year > currentYear){
            toast.warn("Please enter current or past year")
            return
        }
        getOrgUserBarChartData(year)
    }

    const getOrgUserPlanPieChartData = async (orgID) => {
        setLoading(true)
        try {
            let response = await getOrgUserPlanBreakdown(userID, orgID)
            console.log("getOrgUserPlanPieChartData", response)
            // if (response.status == 200) {
            
            let finalData = convertForPieChart(response.data)
            // if (finalData.length > 0) {
            //     setOrgUsersPlanBreakdown(finalData)
            // }
            // else {
            //     toast.warn("No data available")
            // }

            // }
        }
        catch (e) {
            console.log("e", e)
            toast.warn(e.message)
        }
        setLoading(false)
    }
    useEffect(() => {
        console.log("orgUsersPlanBreakdown", orgUsersPlanBreakdown)
    }, [orgUsersPlanBreakdown])

    const handleChangePieChart = (e) => {
        console.log("handleChangePieChart", e, e.target.value)
        let value = e.target.value
        setPieChartOrg(value)
        getOrgUserPlanPieChartData(value)
    }

    const getDetailsAdminDash = async (year) => {
        setState((prevState) => ({ ...prevState, loading: true }));
        try {
            const {
                totalDocuments,
                totalFreeuser,
                totalPaidUser,
                monthWiseDocuments,
                monthWiseUser
            } = await AdminServices.getDetailsAdminDash(year);

            const dashData = [
                {
                    title: "Total Free Users",
                    count: totalFreeuser,
                    colors: "#ff3a29",
                    img: Doc,
                    sparkData: state.monthwiseFreeusers
                },
                {
                    title: "Total Paid Users",
                    count: totalPaidUser,
                    colors: "#ffb200",
                    img: Rate,
                    sparkData: state.monthwisePaidusers
                },
                {
                    title: "Total Documents",
                    count: totalDocuments,
                    colors: "#34b53a",
                    img: Group,
                    sparkData: monthWiseDocuments
                }
            ];
            console.log("dashdata", dashData)
            setState((prevState) => ({
                ...prevState,
                totalPaidUser,
                totalDocuments,
                totalUsers: totalFreeuser,
                monthWiseDocuments,
                monthWiseUser,
                MonthWise: monthWiseDocuments,
                UserWise: monthWiseUser,
                selectYear: year,
                admindashdetail: dashData,
                loading: false
            }));

            getmonthwisePaidusers(year);
            getmonthwiseFreeusers(year);
        } catch (error) {
            console.error("Error fetching data:", error);
            setState((prevState) => ({ ...prevState, loading: false }));
        }
    };

    const getmonthwisePaidusers = async (year) => {
        try {
            const admindata = await AdminServices.getPaidUserDetails(year);
            console.log(admindata);
            setState((prevState) => ({ ...prevState, monthwisePaidusers: admindata.monthWisePaidUser }));
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const getmonthwiseFreeusers = async (year) => {
        try {
            const admindata = await AdminServices.getFreeUserDetails(year);
            console.log(admindata);
            setState((prevState) => ({ ...prevState, monthwiseFreeusers: admindata.monthWiseFreeUser }));
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const getMonthYear = async (value) => {
        console.log(value);
        const year = value;

        const full = [
            "Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"
        ];

        if (year !== state.selectYear) {
            await getDetailsAdminDash(year);
            await getmonthwisePaidusers(year);
            await getmonthwiseFreeusers(year);
            setState((prevState) => ({ ...prevState, selectYear: year, monthList: full }));
        }
    };

    const increment = () => {
        const selectYear = state.selectYear;
        const Year = selectYear + 1;
        console.log(Year);
        setState((prevState) => ({ ...prevState, selectYear: Year }));
        getDetailsAdminDash(Year);
    };

    const decrement = () => {
        const selectYear = state.selectYear;
        const Year = selectYear - 1;
        console.log(Year);
        setState((prevState) => ({ ...prevState, selectYear: Year }));
        getDetailsAdminDash(Year);
    };

    const _changeYear = async (year) => {
        setState((prevState) => ({ ...prevState, selectYear: year }));
        getDetailsAdminDash(year);
        getmonthwisePaidusers(year);
        getmonthwiseFreeusers(year);
    };

    // ------

    return (
        <div style={{width:"100%",marginLeft:windowWidth < 768 ? "" : "-20px"}}>
            <ToastContainer /> 
            <Loader loading={loading} />
            <div style={{width:"100%",display:"flex",flexDirection:windowWidth < 768 ? "column" : "row" ,justifyContent:"space-between"}}>
                <div style={{width:windowWidth < 768 ? "100%" : "15%",display:"flex",justifyContent:"space-between",flexDirection:windowWidth < 768 ? "row" : "column"}}>
                    <div style={{height:windowWidth < 768 ? "6rem" : "45%",width:windowWidth < 768 ? "49%" : "100%",display:"flex",justifyContent:"center",alignContent:"center",alignItems:"center",backgroundColor:isDarkMode?"#2A2A2A":"white",boxShadow:"1px 1px 2px -1px #000000", borderRadius:'8px'}}>
                   
                    <Link to="/manageuser">
                        <ImageCard
                            bgColor={isDarkMode?"#2A2A2A":"white"}
                            txtColor='#46A57F'
                            altImg="total doc"
                            imgSrc={individual_user}
                            cardName="Individual Users"
                            number={imageCardData.individualUsers}
                           
                        />
                    </Link>

                    </div>
                    <div style={{height:windowWidth < 768 ? "6rem" : "45%",width:windowWidth < 768 ? "49%" : "100%",display:"flex",justifyContent:"center",alignContent:"center",alignItems:"center",backgroundColor:isDarkMode?"#2A2A2A":"white",boxShadow:"1px 1px 3px -1px #000000", borderRadius:'8px'}}>
                    <Link to="/manageorganizations">
                        <ImageCard
                            divclassName=""
                            bgColor={isDarkMode?"#2A2A2A":"white"}
                            txtColor='#4676A5'
                            altImg="Registered Organization"
                            imgSrc={registered_organization}
                            cardName="Registered Organization"
                            number={imageCardData.registeredOrganization}
                            additionalData={true}
                            totalUsers={imageCardData.totalOrgUsers}
                        />
                    </Link>
                    </div>
                </div>

                <div style={{width:windowWidth < 768 ? "100%" : "33%",marginTop:windowWidth < 768 ? "15px" : "",boxShadow:"2px 2px 5px -2px #000000", borderRadius:'8px'}}>
                    {userPlanBreakdownPieChart.length > 0 ?
                    <div style={{width:"100%"}} >
                        <PieChartComp
                            pieChartData={userPlanBreakdownPieChart}
                            chartHeading="User Plans Breakdown"
                            isDarkMode={isDarkMode}
                        />
                         </div>
                        :
                        <div style={{ fontWeight: "bold" }}>
                            No Pie Chart data available
                        </div>
                    }
                </div>

                <div style={{width:windowWidth < 768 ? "100%" : "50.5%",marginTop:windowWidth < 768 ? "15px" : "",boxShadow:"2px 2px 5px -2px #000000", borderRadius:'8px'}}>
                    {countryCodePieChart.length > 0 ?
                      <div style={{width:"100%"}} >
                        <PieChartComp
                            pieChartData={countryCodePieChart}
                            chartHeading="Overall User Country Breakdown"
                            isDarkMode={isDarkMode}
                        />
                        </div>
                        :
                        <div
                            // className='align-items-center'
                            style={{ fontWeight: "bold" }}
                        >No Pie Chart data available</div>
                    }
                </div>
            </div>


            <div style={{width:"100%",display:"flex",flexDirection:windowWidth < 768 ? "column" : "row", justifyContent:"space-between",marginTop:windowWidth < 768 ? "" : "15px"}}>
                <div style={{width:windowWidth < 768 ? "100%" : "48.8%",backgroundColor:windowWidth <= 768 ? (isDarkMode ? "#2A2A2A" : "white")  : (isDarkMode ? "#2A2A2A" : ""),boxShadow:"2px 2px 5px -2px #000000",marginTop:windowWidth < 768 ? "15px" : "", borderRadius:'8px'}}>
                  
                    {isBarChartDropdown ?
                      <div style={{width:windowWidth < 768 ? "95%" : "100%"}}>
                        <ApexChartsComp
                            chartHeading="Organization Users"
                            cmp1={orgUserBarChart.xAxisBar}
                            cmp2=""
                            cmp3=""
                            bar1={orgUserBarChart.bar}
                            xAxisList={orgUserBarChart.xAxisList}
                            isSelectDropdownShown={isBarChartDropdown}
                            barChartSelectOptions={yearList}
                            // barChartHandleChange={handleChangeBarChart}
                            onChangeBarChartYear={handleBarChartYear}
                            barChartValue={barChartYear}
                            barChartSelectLabel="Year"
                            isDarkMode={isDarkMode}
                        />
                        </div>
                        :
                        <div style={{ fontWeight: "bold" }}>
                            No Bar Chart data available
                        </div>
                    }
                </div>
                <div style={{width:windowWidth < 768 ? "100%" : "50%",backgroundColor:windowWidth <= 768 ? (isDarkMode ? "#2A2A2A" : "white")  : (isDarkMode ? "#2A2A2A" : ""),boxShadow:"2px 2px 5px -2px #000000",marginTop:windowWidth < 768 ? "15px" : "", borderRadius:'8px'}}>
                    {isOrgSelectDropdown ?
                     <div style={{width:windowWidth < 768 ? "95%" : "100%"}}>
                        <PieChartComp
                            pieChartData={orgUsersPlanBreakdown}
                            chartHeading="Organization Users Plan Breakdown"
                            isSelectDropdownShown={isOrgSelectDropdown}
                            pieChartSelectOptions={organizationList}
                            pieChartHandleChange={handleChangePieChart}
                            pieChartValue={pieChartOrg}
                            pieChartSelectLabel="Organization"
                            isDarkMode={isDarkMode}
                        />
                        </div>
                        :
                        <div
                            style={{ fontWeight: "bold" }}
                        >No Pie Chart data available</div>
                    }
                </div>
            </div>

            <br />
            {/* <div className="d-flex justify-content-between align-items-center">
          <h4 className="ft_sm m-0" style={{ color: "#4d4f5c" }}>
            Documents and Reviews
          </h4>
          <div className="d-flex align-items-center">
            <h4 className="ft_sm m-0" style={{ color: "#4d4f5c" }}>
              Select Year :{" "}
            </h4>
            <Dropdown
              onMouseDown={e => {
                e.preventDefault();
              }}
            >
              <Dropdown.Toggle variant="white">{selectYear}</Dropdown.Toggle>

              <Dropdown.Menu
                style={{ width: "auto", minWidth: "auto" }}
                className="m-0"
              >
                {years &&
                  years.map(year => (
                    <Dropdown.Item onMouseDown={() => this._changeYear(year)}>
                      {" "}
                      {year}{" "}
                    </Dropdown.Item>
                  ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div> */}
            <br />

            {/* <ApexChartsComp
                cmp1="Free Users"
                cmp2="Documents"
                cmp3="Paid Users"
                bar1={state.monthwiseFreeusers}
                bar2={state.monthWiseDocuments}
                linedata={state.monthwisePaidusers}
                monthList={state.monthList}
            /> */}
        </div>
    );
}


export default AdminDashboard;

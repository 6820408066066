import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import './Plagiarism_1.css'
import Button from '@mui/material/Button';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import { ContentState, Editor, EditorState, Modifier, RichUtils, SelectionState, convertToRaw, CompositeDecorator } from 'draft-js';
import { checkPlagiarism, getPlagiarismDetails, getUniqueSentence } from "../../services/plagiarismServices";
import { Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import { Modal, Button as B_Button } from 'react-bootstrap';

import 'react-circular-progressbar/dist/styles.css';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { Card, CardContent, Typography } from '@mui/material';
import Loader from "../../utils/loader";
import { toast, ToastContainer } from "react-toastify";
import SuggestionCard from "./SuggestionCard";
import HeadingB from "../Cards/HeadingB";
import _ from "lodash";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import styled from "styled-components";
import DataGridMui from "../ReusableComponents/DataGridMui";

import 'react-toastify/dist/ReactToastify.css';


import createCounterPlugin from "draft-js-counter-plugin";
import 'draft-js/dist/Draft.css';
import { useHistory } from 'react-router-dom';
import ScanPlagarismservice from "../../services/ScanPlagarismservice";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";


const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'blue',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});


const findWithRegex = (regex, contentBlock, callback) => {
  const text = contentBlock.getText();
  let match;
  while ((match = regex.exec(text)) !== null) {
    callback(match.index, match.index + match[0].length);
  }
};

const createDecorator = (outputArray) => {
  return new CompositeDecorator(
    outputArray.map((item) => ({
      strategy: (contentBlock, callback, contentState) => {
        const regex = new RegExp(item.sentenceDetails.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'g');
        findWithRegex(regex, contentBlock, callback);
      },
      component: (props) => (
        <span style={{ backgroundColor: '#ff4242' }} data-url={item.url}>
          {props.children}
        </span>
      ),
    }))
  );
};

// Utility function to highlight text
const highlightText = (editorState, outputArray) => {
  const decorator = createDecorator(outputArray);
  return EditorState.set(editorState, { decorator });
};

const counterPlugin = createCounterPlugin();
const { CharCounter, WordCounter, LineCounter, CustomCounter } = counterPlugin;

const plugins = [counterPlugin];


const Plagiarism_1 = ({userId}) => {
  const userDetails = useSelector((user) => user)
const userID = userId ? userId : userDetails.user.currentUser._id
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty())
  const [sentenceCount, setSentenceCount] = useState(0);
  const [paraCount, setParaCount] = useState(0);
  const [uniqueWordCount, setUniqueWordCount] = useState("0");
  const [uniqueWordPercent, setUniqueWordPercent] = useState();
  const [speakTime, setSpeakTime] = useState(0);
  const [plagiarozedProgress, setPlagiarozedProgress] = useState(0);
  const [uniqueProgress, setUniqueProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [sourceCount, setSourceCount] = useState(0)
  const [outputArray, setOutputArray] = useState([])
  const [selectedSentence, setSelectedSentence] = useState("")
  const [isUniqueButton, setIsUniqueButton] = useState(false);
  const [wordsCount, setWordsCount] = useState(0);
  const [editorState_Wcount, setEditorState_Wcount] = useState(EditorState.createEmpty());
  const [timeDisplay, setTimeDisplay] = useState("0 sec");
  const speakingStartTimeRef = useRef(null);
  const [scanHistory, setScanHistory] = useState([]);
  const [isUploadButtonDisabled, setIsUploadButtonDisabled] = useState(false);
  const [P_File, setP_File] = useState("File");
  const [inputKey, setInputKey] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
 
  // Update windowWidth on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
 
    window.addEventListener('resize', handleResize);
  
 
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const history = useHistory();
  let maxWords = 1500;


  const calculateSpeakTime = (countstats, isTable = false) => {
    const contentState = editorState.getCurrentContent();
    const rawText = convertToRaw(contentState);
    const text = rawText.blocks.map((block) => block.text).join(" ");

    if (text == "") {
      setTimeDisplay(`0 sec`)
    }

    // Estimate speaking speed (words per minute)
    const wordsPerMinute = 150; // Adjust as needed

    // Calculate the number of words in the text
    const wordCount = text.split(/\s+/).length;

    // Calculate the time it would take to speak in minutes
    const speakingTimeMinutes = wordCount / wordsPerMinute;

    // Convert minutes to minutes and seconds
    const minutes = Math.floor(speakingTimeMinutes);
    const seconds = Math.round((speakingTimeMinutes % 1) * 60);
    let finalTime = ""
    // Set the time display based on conditions
    if (speakingTimeMinutes < 1) {
      setTimeDisplay(`${seconds} sec`);
      finalTime = `${seconds} sec`

    } else {
      setTimeDisplay(`${minutes} min ${seconds} sec`);
      // addScanHistory(countstats,`${seconds} sec`)
      finalTime = `${minutes} min ${seconds} sec`

    }
    console.log("countstats_", countstats)
    if (!isTable) {
      addScanHistory(countstats, finalTime)
    }
  };

  const handleDeleteClick = async (id) => {
    const toastId = showConfirmationToast("Are you sure you want to delete this scan history?",
      () => deleteHistory(id), // onConfirm
      () => toast.dismiss(toastId) // onCancel
    );
  };

  

  const showConfirmationToast = (message, onConfirm, onCancel) => {
    const toastId = toast.info(
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{marginBottom:'10px'}}><h4>Delete Scan History</h4></div>
        <p style={{ marginBottom: '10px' }}>{message}</p>
        <div style={{ display: 'flex', gap: '10px' }}>
          <button
            onClick={() => {
              onConfirm();
              toast.dismiss(toastId);
            }}
            style={{
              padding: '8px 12px',
              backgroundColor: 'white',
              color: '#1976D2',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
          >
            Yes
          </button>
          <button
            onClick={() => {
              onCancel();
              toast.dismiss(toastId);
            }}
            style={{
              padding: '8px 12px',
              backgroundColor: 'white',
              color: '#1976D2',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
          >
            No
          </button>
        </div>
      </div>,
      {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false, // Do not auto-close the toast
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
 
    return toastId;
  };



  // const [timeDisplay, setTimeDisplay] = useState("");

  // let initialEditorState = EditorState.createEmpty(); // Set default as empty

  // // Check if singleHistoryData?.content exists and create EditorState accordingly
  // if (singleHistoryData?.content) {
  //   const contentState = convertFromRaw(singleHistoryData.content);
  //   initialEditorState = EditorState.createWithContent(contentState);
  // }




  // const calculateSpeakTime = () => {
  //   const contentState = editorState.getCurrentContent();
  //   const rawText = convertToRaw(contentState);
  //   const text = rawText.blocks.map((block) => block.text).join(" ");

  //   if (text == "") {
  //     setTimeDisplay(`0 sec`)
  //   }

  //   // Estimate speaking speed (words per minute)
  //   const wordsPerMinute = 150; // Adjust as needed

  //   // Calculate the number of words in the text
  //   const wordCount = text.split(/\s+/).length;

  //   // Calculate the time it would take to speak in minutes
  //   const speakingTimeMinutes = wordCount / wordsPerMinute;

  //   // Convert minutes to minutes and seconds
  //   const minutes = Math.floor(speakingTimeMinutes);
  //   const seconds = Math.round((speakingTimeMinutes % 1) * 60);

  //   // Set the time display based on conditions
  //   if (speakingTimeMinutes < 1) {
  //     setTimeDisplay(`${seconds} sec`);
  //   } else {
  //     setTimeDisplay(`${minutes} min ${seconds} sec`);
  //   }
  // };






  const handleChange = (newEditorState) => {
    //console.log("editorstate_", newEditorState, newEditorState.getCurrentContent().getPlainText())
    // setInputText(newEditorState.getCurrentContent().getPlainText())
    // let words = newEditorState.getCurrentContent().getPlainText().trim().split(/\s+/).length
    // let newText = newEditorState.getCurrentContent().getPlainText()
    // if (words <= maxWords) {
    //   setEditorState(newEditorState);
    // }
    setEditorState(newEditorState);
    console.log(newEditorState, "newwww")

    if (newEditorState.getCurrentContent().getPlainText() !== "") {
      let words = newEditorState.getCurrentContent().getPlainText().split(/[ -]+/).length
      console.log("words_", words)
      setWordsCount(words)
      setIsUniqueButton(false)
    } else {
      setWordsCount(0)
      setParaCount(0)
      setSentenceCount(0)
      setUniqueWordCount("0")
      setSourceCount(0)
      setTimeDisplay("0 sec")
      setIsUniqueButton(false)
      setPlagiarozedProgress(0)
      setUniqueProgress(0)
    }




    const selectionState = newEditorState.getSelection();
    if (!selectionState.isCollapsed()) {
      const anchorKey = selectionState.getAnchorKey();
      const currentContent = newEditorState.getCurrentContent();
      const currentContentBlock = currentContent.getBlockForKey(anchorKey);
      const start = selectionState.getStartOffset();
      const end = selectionState.getEndOffset();
      const selectedText = currentContentBlock.getText().slice(start, end);
      //console.log('Selected Text:', selectedText);
      setSelectedSentence(selectedText)
      if (sourceCount !== 0) {
        setIsUniqueButton(true)
      }
    }
    // let current_Content = newEditorState.getCurrentContent().getPlainText();
    // let length = current_Content.length;
    // if (characters.max < length)
    //   length = characters.max
    // setCharacters({ ...characters, now: length });
  };



  const handleBeforeInput1 = (chars, editorState) => {
    const contentState = editorState.getCurrentContent();
    const currentText = contentState.getPlainText();
    const wordCount = currentText.trim().split(/\s+/).length;

    if (wordCount > maxWords) {
      toast.warn("Word limit exceeded", {
        position: toast.POSITION.TOP_RIGHT
      });
      return 'handled';

    }
    return 'not-handled';
  };





  const checkPlagiarismSentence = async (outputSentence = "", isTable) => {



    //console.log("checkSentence_", outputSentence);
    let sentence = editorState.getCurrentContent().getPlainText()
    setLoading(true)
    setSourceCount(0)
    countTextStats(isTable)

    // getParaDetails()
    let data = {
      text: outputSentence !== "" ? outputSentence : sentence
    }
    let userId = sessionStorage.getItem("userId")
    let isCoAuthor = false
    let plagiarismResponse = await checkPlagiarism(data,userId,isCoAuthor)
    //console.log("plagiarismResponse_", plagiarismResponse.data)
    if (plagiarismResponse.status == 400) {
      setLoading(false)
      toast.warn(plagiarismResponse.message)
      return
    }
    if (plagiarismResponse?.data?.details?.plagPercent !== 0) {
      setUniqueProgress(plagiarismResponse.data.uniquePercent)
      setPlagiarozedProgress(plagiarismResponse.data.plagPercent)
      let plagDetails = plagiarismResponse.data.details
      let sourceDetails = plagiarismResponse.data.sources
      let sentenceArray = []

      let filterPlagDetails = _.filter(plagDetails, (item, i) => item.unique == "false")

      //console.log("plagDetails_", plagDetails, sourceDetails.length, filterPlagDetails)
      setSourceCount(sourceDetails.length)
      filterPlagDetails.map((obj) => {
        //console.log("obj_", obj);
        let outObject = {
          sentenceDetails: obj.query,
          url: obj?.display?.url
        }
        sentenceArray.push(outObject)
      })
      //console.log("outputArray_", sentenceArray)
      setOutputArray(sentenceArray)

      setLoading(false)
    }
  }



  const handleCheckPlagiarism = () => {


    if (editorState.getCurrentContent().getPlainText() == "") {
      toast.warn(`You don't have text, to Plagiarize`);
      return
    } else if (editorState.getCurrentContent().getPlainText().split(/[ -]+/).length > maxWords) {
      toast.warn(`Words limit exceeded`);
      return
    }
    checkPlagiarismSentence()
    // addScanHistory()
  }


  const handleUnique = async () => {
    setLoading(true);

    let payload = {
        text: selectedSentence
    };

    let uniqueSentenceResponse = await getUniqueSentence(payload);
    let newSentence = uniqueSentenceResponse.data.output[0];

    let currentContent = editorState.getCurrentContent().getPlainText();

    console.log(selectedSentence, "jojojo", newSentence, "lolololo", currentContent);

    // Find the index of the selected sentence in the current content
    let startIndex = currentContent.indexOf(selectedSentence);
    if (startIndex === -1) {
        console.error("Selected sentence not found in the current content");
        setLoading(false);
        return;
    }

    let endIndex = startIndex + selectedSentence.length;

    // Create a selection state for the selected sentence
    const selectionState = SelectionState.createEmpty(editorState.getSelection().getAnchorKey())
        .merge({
            anchorOffset: startIndex,
            focusOffset: endIndex
        });

    // Replace the selected sentence with the new sentence
    let newContentState = Modifier.replaceText(
        editorState.getCurrentContent(),
        selectionState,
        newSentence
    );

    // Update the editor state with the new content
    let newEditorState = EditorState.push(editorState, newContentState, 'replace-text');
    setEditorState(newEditorState);
    setLoading(false);
    checkPlagiarismSentence(newEditorState.getCurrentContent().getPlainText());
    setIsUniqueButton(false)
};

// const handleUnique = async () => {
//     //console.log("uniqueSentence", selectedSentence)
//     setLoading(true)
//         let payload = {
//             text: selectedSentence
//     }
//     let uniqueSentenceResponse = await getUniqueSentence(payload)
//     //console.log("uniqueSentenceResponse_", uniqueSentenceResponse)
//     let newSentence = uniqueSentenceResponse.data.output
//     // const plagiarisedSplit = selectedSentence.split(/[ -]+/);
//     // if(uniqueSentenceResponse !== 200){
//     //   toast.warn(uniqueSentenceResponse.message)
//     //   setLoading(false)
//     //   return
//     // }
//     // Get the current content and blocks from the EditorState
//     const currentContent = editorState.getCurrentContent();
//     const currentBlocks = currentContent.getBlocksAsArray();

//     // Find the block containing the old sentence
//     const blockWithOldSentence = currentBlocks.find(block =>
//       block.getText().includes(selectedSentence)
//     );

//     if (blockWithOldSentence) {
//       // Find the start and end offsets of the old sentence within the block
//       const startOffset = blockWithOldSentence.getText().indexOf(selectedSentence);
//       const endOffset = startOffset + selectedSentence.length;

//       // Create a SelectionState to specify the range of text to replace
//       const selection = SelectionState.createEmpty(blockWithOldSentence.getKey())
//         .set('anchorOffset', startOffset)
//         .set('focusOffset', endOffset);

//       // Create a new ContentState with the replaced text
//         const replacedContent = Modifier.replaceText(
//             currentContent,
//             selection,
//             newSentence
//         );
        
//       // Update the EditorState with the replaced content
//         const newEditorState = EditorState.push(
//         editorState,
//             replacedContent,
//             'replace-text'
//         );
        
//       // Set the new EditorState
//         setEditorState(newEditorState);
//       setLoading(false)
//         checkPlagiarismSentence(newEditorState.getCurrentContent().getPlainText());
//       setIsUniqueButton(false)
        
//     }
// };

  const getParaDetails = async () => {
    //console.log("checkSentenceWords_");
    // alert("working")
    console.log("checkSentenceWords_");
    let payload = {
      text: editorState.getCurrentContent().getPlainText(),
    }
    let plagiarismDetailsResponse = await getPlagiarismDetails(payload)
    //console.log("plagiarismDetailsResponse_", plagiarismDetailsResponse)
    if (plagiarismDetailsResponse.status == 200) {
      setSentenceCount(plagiarismDetailsResponse?.numberOfSentences)
      setParaCount(plagiarismDetailsResponse?.paragraphCount)
      setUniqueWordCount(plagiarismDetailsResponse?.uniqueWords)
      setUniqueWordPercent(plagiarismDetailsResponse?.uniqueWordsPercent)
      setSpeakTime(plagiarismDetailsResponse?.speakTime)
    } else {
      return
    }
    // setLoading(false)
    // let splitSpeakTime = plagiarismDetailsResponse.speakTime?.split(":")
  }

  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    //console.log("editorstate_", newState)
    if (newState) {
      handleChange(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  useEffect(() => {
    //console.log("outputArray_", outputArray.length)
    const newEditorState = highlightText(editorState, outputArray);
    setEditorState(newEditorState);
  }, [outputArray])

  const handleDelete = () => {
    setEditorState(EditorState.createEmpty());
    setIsUniqueButton(false)
    setSourceCount(0)
    setWordsCount(0)
  }

  const handleDownload = () => {
    // const contentState = outputSentence;
    // const rawText = outputSentence
    // const textToDownload = rawText.blocks.map(block => block.text).join('\n');
    if (!editorState.getCurrentContent().getPlainText()) {
      toast.warn(`You don't have text, to download`);
      return;
    }
    const blob = new Blob([editorState.getCurrentContent().getPlainText()], { type: 'text/plain' });

    const anchor = document.createElement('a');
    anchor.href = URL.createObjectURL(blob);
    anchor.download = 'text_content.doc';
    anchor.click();
    URL.revokeObjectURL(anchor.href);
  };

  const handleScanDownload = (content) => {
    if (!content) {
      toast.warn(`No content available for download`);
      return;
    }

    const blob = new Blob([content], { type: 'text/plain' });

    const anchor = document.createElement('a');
    anchor.href = URL.createObjectURL(blob);
    anchor.download = 'text_content.doc';
    anchor.click();
    URL.revokeObjectURL(anchor.href);
  };




  const handlecopy = () => {
    let sentence = editorState.getCurrentContent().getPlainText()
    if (sentence == "") {
      toast.warn(`You don't have text, to copy`);
      return;
    }
    navigator.clipboard.writeText(sentence).then(
      function () {
        //console.log("Async: Copying to clipboard was successful!");
        if (sentence) toast.info("Your text Copied!");
      },
      function (err) {
        // console.error("Async: Could not copy text: ", err);
      }
    );
  }

  const handleUpload1 = () => {
    if (editorState.getCurrentContent().getPlainText().split(/[ -]+/).length > maxWords) {
      toast.warn(`Words limit exceeded`)

      setP_File("")
      return
    }
    setP_File("File")
  }

  const handleFileChange = (e) => {

    const file = e.target.files[0];

    if (file) {

      const reader = new FileReader();

      reader.onload = (event) => {
        const content = event.target.result;
        const text = content.toString();

        let words = text.split(/[ -]+/).length
        if (words > 1500) {
          toast.warn("Words limit exceeded")
          return
        }
        console.log("wordssssssssssss_", words, "textttttttttttttttttttttttttttttttttttttttttttt", text)
        setWordsCount(words)

        const contentState = ContentState.createFromText(text);
        const newEditorState = EditorState.createWithContent(contentState);
        setEditorState(EditorState.createEmpty())
        setInputKey((prevKey) => prevKey + 1);
        setEditorState(newEditorState);
      };

      reader.readAsText(file);

    }
  };

  const handlePastedText = (text, html, editorState) => {
    // Example: Convert pasted text to uppercase before insertion
    // const modifiedText = text.toUpperCase();

    // Create a new editor state with the modified text
    const newContentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      text
    );

    // Update the editor state with the modified content
    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      'insert-characters'
    );

    setEditorState(newEditorState);

    // Return 'handled' to prevent default paste behavior
    return 'handled';
  };

  const handleBeforeInput = (chars, editorState) => {
    if (chars === '--') {
      // Replace '--' with '—' (em dash)
      const newContentState = Modifier.replaceText(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        '—'
      );

      const newEditorState = EditorState.push(
        editorState,
        newContentState,
        'insert-characters'
      );

      setEditorState(newEditorState);

      return 'handled';
    }

    return 'not-handled';
  };

  const countTextStats = (isTable) => {
    let inputText = editorState.getCurrentContent().getPlainText()
    // Count paragraphs
    const paragraphs = inputText.split('\n').filter((line) => line.trim() !== '').length;

    // Count sentences (a basic example)
    const sentences = inputText.split(/[.!?]/).filter((sentence) => sentence.trim() !== '').length;

    // Count unique words
    const words = inputText
      .toLowerCase()
      .split(/\W+/)
      .filter((word) => word.trim() !== '');
    const uniqueWords = [...new Set(words)].length;

    setParaCount(paragraphs);
    setSentenceCount(sentences);
    setUniqueWordCount(uniqueWords);
    let counts = {
      "paragraph": paragraphs,
      "sentence": sentences,
      "uniqueWords": uniqueWords
    }
    calculateSpeakTime(counts, isTable)
  };


  const handleButtonClick = () => {
    // Perform any necessary actions

    // Navigate to the specified path ("/translator" in this case)
    history.push('/scanhistory');
  };


  const checkStatus = () => {
    history.push('/plagiarism');
  }


  const addScanHistory = async (countStats, speaktime) => {
    let inputText = editorState.getCurrentContent().getPlainText();
    const email = sessionStorage.getItem("currentUserEmail")
    console.log("countStats_", countStats, speaktime)
    let data = {
      "content": inputText,
      "paraCount": countStats.paragraph,
      "sentenceCount": countStats.sentence,
      "uniqueWordCount": countStats.uniqueWords,
      "email": email,
      "timeDisplay": speaktime

    }
    let response = await ScanPlagarismservice.addScanHistory(data);
    // console.log(response,"addedd response");
    fetchData();
  }

  const deleteHistory = async (params) => {
    const response = await ScanPlagarismservice.deleteHistory(params);
    //  console.log(response)
    fetchData();
    toast.error("Scan history deleted.")
  }

  const fetchData = async () => {
    try {
      const email = sessionStorage.getItem("currentUserEmail")
      let data = { email }
      const response = await ScanPlagarismservice.getScanHistory(data);
      const responsedata = response?.data;
      // console.log(response,"pagarism response")
      if (response) {
        setScanHistory(responsedata);
      }
    } catch (error) {
      console.error('Error fetching scan history:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);


  const handleStatusClick = async (params) => {
    const response = await ScanPlagarismservice.getScanData(params);
    const responseData = response?.data?.content;
    // console.log(responseData,"data response")
    if (response) {
      const contentState = ContentState.createFromText(responseData);
      const newEditorState = EditorState.createWithContent(contentState);
      setEditorState(newEditorState);
      checkPlagiarismSentence(newEditorState.getCurrentContent().getPlainText(), true)
      setSentenceCount(response?.data?.sentences);
      setParaCount(response?.data?.paragraphs);
      setUniqueWordCount(response?.data?.Score);
      setTimeDisplay(response?.data?.speakTime)
      let words = newEditorState.getCurrentContent().getPlainText().split(/[ -]+/).length
      setWordsCount(words)
    }
  }

  // console.log(singleHistoryData?.content,"content")

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      type: 'text',
      flex: 0.2,
      width: !windowWidth ? 240 : 180
    },
    {
      field: 'createdDate',
      headerName: 'Date',
      flex: 0.2,
      width: !windowWidth ? 240 : 180,
      valueGetter: (params) => {
        const dateTime = new Date(params.row.createdDate);
        return dateTime.toLocaleDateString(); // Display only the date part
      },
    },
    {
      field: 'createdTime',
      headerName: 'Time',
      flex: 0.1,
      width: !windowWidth ? 240 : 180,
      valueGetter: (params) => {
        const dateTime = new Date(params.row.createdTime);
        return dateTime.toLocaleTimeString(); // Display only the time part
      },
    },

    {
      field: 'status',
      headerName: 'Status',
      flex: 0.1,
      width: !windowWidth ? 240 : 180,
      renderCell: (params) => (
        <button
          style={{
            backgroundColor: "#1976D2",
            color: "white",
            border: "none",
            borderRadius: "15px",
            padding: "5px 10px",
            cursor: "pointer",
            textAlign: "center",
          }}
          onClick={() => handleStatusClick(params.row._id)}
        >
          {params.value}
        </button>
      ),
    },
    {
      field: 'paragraphs',
      headerName: 'Paragraphs',
      flex: 0.1,
      width: !windowWidth ? 240 : 180
    },
    {
      field: 'Score',
      headerName: 'Score',
      flex: 0.1,
      width: !windowWidth ? 240 : 180
    },

    {
      field: '',
      headerName: '',
      width: !windowWidth ? 240 : 180,
      // flex: 0.1,
      renderCell: (params) => {
        console.log("params", params)
        return (
          <div style={{display:"flex",flexDirection:"row"}}>
            <div class="col-md-2 mr-2" onClick={()=>handleDeleteClick(params.row._id)}>
              <LightTooltip title="Delete">
                <DeleteIcon style={{ color: "red", cursor: "pointer" }} />
              </LightTooltip>
            </div>
            <div class="col-md-2" onClick={() => handleScanDownload(params.row.content)}>
              <LightTooltip title="Download">
                <DownloadIcon style={{ color: "green" }} />
              </LightTooltip>
            </div>
          </div>
        )
      },
    }
  ];
  console.log(editorState, "edit text here")
  const loginEmail = sessionStorage.getItem("currentUserEmail");
  const checkUser = scanHistory?.some((historyItem) => historyItem.email === loginEmail);



  return (
    <div style={{marginLeft:windowWidth < 960 ? "-5px" : '',marginTop:"-11px",borderRadius:"10px", padding:windowWidth < 768 ? "5px 5px 5px 0px" : ""}}>
      <Loader loading={loading} />
      {/* {userId &&
      <div>
        <HeadingB text="Plagiarismmmm"></HeadingB>
      </div>
} */}
      <div className="top-bar" style={{width:windowWidth < 768 ? "100%" : "65%",display:"flex",flexDirection:windowWidth < 768 ? "column" : "row"}}>
    
     <div style={{width:windowWidth < 768 ? "100%" : "49%",display:"flex"}}>
     <div style={{width:windowWidth < 768 ? "100%" : "50%",borderRight:"1px solid #ccc"}}>
     <Card >
          <CardContent >
      
            <div style={{ width: 100, height: 100, textAlign: "center" }}>
              <CircularProgressbar
                value={plagiarozedProgress}
                // text={`${plagiarozedProgress}%`}
                counterClockwise={true}
                circleRatio={0.75}
                styles={buildStyles({
                  rotation: 0.37,
                  strokeLinecap: "butt",
                  pathColor: 'red',
                  trailColor: '#F6B6B6',
                  textColor: 'red'
                })}
              />
            </div>
            <p className="progressText" style={{ color: "red" }}>{plagiarozedProgress}% Plagiarized</p>
          </CardContent>
        </Card>
     </div>
     <div style={{width:windowWidth < 768 ? "100%" : "50%",borderRight:"1px solid #ccc"}}>
 
        <Card >
          <CardContent >
            <div style={{ width: 100, height: 100, textAlign: "center" }}>
              <CircularProgressbar
                value={uniqueProgress}
                // text={`${uniqueProgress}%`}
                // counterClockwise={true}
                circleRatio={0.75}
                styles={buildStyles({
                  rotation: 0.63,
                  strokeLinecap: "butt",
                  pathColor: '#32BC7A',
                  trailColor: '#7FF1BA',
                  textColor: '#32BC7A'
                })}
              />
            </div>
            <p className="progressText ml-3" style={{ color: "#32BC7A" }}>{uniqueProgress}% Unique</p>
          </CardContent>
        </Card>
        </div>
        </div>
        <div style={{width:windowWidth < 768 ? "100%" : "49%"}}>

        <Card >
          <CardContent>
            <div>
              <div>
                <p className="countCardheader">Extra Word Count Statistics</p>
              </div>
              <div className="countCardValues d-flex justify-content-between">
                <div className="d-flex mt-3">
                  <div className="d-flex flex-column" style={{ fontWeight: 600 }}>
                    <p className="zzz">Sentences</p>
                    <p className="zzz">Unique Word(s)</p>
                  </div>
                  <div className="d-flex flex-column ml-2">
                    <p className="zzz">{sentenceCount}</p>
                    {/* <p>{sen}</p> */}
                    {/* <p>{uniqueWordCount + " " + "(" + uniqueWordPercent + ")"}</p> */}
                    <p className="zzz">{uniqueWordCount}</p>
                  </div>
                </div>
                <div className="d-flex mt-3">
                  <div className="d-flex flex-column" style={{ fontWeight: 600 }}>
                    <p className="zzz">Paragraph(s)</p>
                    <p className="zzz">Speak Time</p>
                  </div>
                  <div className="d-flex flex-column ml-2">
                    <p className="zzz">{paraCount}</p>
                    {/* <p>1</p> */}
                    <p className="zzz">{timeDisplay}</p>
                    {/* <p>{speakTime}</p> */}
                  </div>
                </div>

              </div>
            </div>
          </CardContent>
        </Card>
      </div>
      </div>
      {/* } */}
      <div  className="row" style={{width:windowWidth < 768 ? "100%" : "70%", height:'25vh',marginLeft:"1px",marginTop:"10px",marginBottom:"10px", flexWrap: windowWidth < 768 ? "" : "nowrap"  }}>
       
        <div className="editortext col-md-12 px-3 editor ft_md"  style={{width:"100%", height:'24vh'}}>
          <Editor
            editorState={editorState}
            placeholder={"Please enter your text and check plagiarism "}
            onChange={handleChange}
            plugins={plugins}
            handleKeyCommand={handleKeyCommand}
          //  handleBeforeInput={handleBeforeInput1} 

          />
        </div>

        {sourceCount !== 0 && windowWidth > 768 &&
          <div className="col-md-4">
            <SuggestionCard sentenceArray={outputArray} sourceCount={sourceCount} />
          </div>
        }
      </div>
      <div className="row" style={{width:windowWidth < 768 ? "" : "100%",display:"flex",flexDirection:windowWidth < 768 ? "column" :"row"}}>
        <div className="col-md-8" style={{display:"flex", width:windowWidth < 768 ? "" :"30%"}}>
          <Button component="label" variant="outlined" onChange={handleFileChange} id="PmainBtn1" style={{color: "blue"}} startIcon={<CloudUploadIcon />}>
            Upload file
            <input key={inputKey} style={{ display: "none" }} type={P_File} />
          </Button>
          {!isUniqueButton && <Button variant="contained" id="PmainBtn2" onClick={handleCheckPlagiarism}>Check Plagiarism</Button>}
          {isUniqueButton && <Button className="px-2" id="PmainBtn2" variant="contained" onClick={handleUnique}>Make it unique</Button>}
        </div>
       
      </div>
      <div className='' style={{}}>
          {/* <div className='iconDiv'>
            <CheckBoxIcon className="icon" color="primary" />
          </div> */}
         
            <button className='iconButton px-2 mx-2' onClick={handlecopy}>
            <LightTooltip title="Copy">
              <ContentCopyIcon style={{color:"blue"}} />
              </LightTooltip>
            </button>
         
          
          <button className='iconButton px-2 mx-2' onClick={handleDownload}>
          <LightTooltip title="Download">
            <DownloadIcon style={{color:"green"}} />
            </LightTooltip>
          </button>
         
         
          <button className='iconButton px-2 mx-2' onClick={handleDelete}>
          <LightTooltip title="Delete">
            <DeleteIcon id="dlt" style={{color:"red"}} />
            </LightTooltip>
          </button>
          




        </div>
     
      <p  style={{ color: "#3295F7",marginTop:"10px",marginLeft:"10px",fontSize:windowWidth < 768 ? "10px" : "16px", }}><b><span style={{width:windowWidth < 768 ? "10px" : "15px",height:windowWidth < 768 ? "10px" :"15px",borderRadius:"10px",color:"#3295F7", backgroundColor:"#3295F7"}}>-</span> 1500 Words limit per search | Total words: {wordsCount} </b></p>
      {sourceCount !== 0 && windowWidth < 768 &&
          <div className="col-md-4" style={{width:"100%"}}>
            <SuggestionCard sentenceArray={outputArray} sourceCount={sourceCount} />
          </div>
        }
      <div >
        {/* <HeadingB text="Scan History" /> */}
        <p style={{ color: "grey", fontWeight: "700", fontSize: "20px", lineHeight: "30px",marginBottom:"5px",marginLeft:"10px"  }}>Scan History</p>
      </div>
      {checkUser ? <>
      <div style={{width:"100%"}}>
      <div style={{ width:windowWidth < 768 ? "90vw" : "80vw"}}>
<DataGridMui
                  height="25vh"
                  getRowId={(data) => data._id}
                  columns={columns}
                  rows={scanHistory}
                  checkboxSelection={false}
                  bgWhite={true}
                  width={windowWidth < 768 ? "90vw" : "80vw"}
                />
</div>
      </div>
   
      </> : <></>}
    </div>
  );
}
export default Plagiarism_1;

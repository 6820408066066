import React, { Component } from "react";
import Loader from "../utils/loader";
import userService from "../services/userService";
import teamService from "../services/teamService";
import Swal from "sweetalert2";
import TeamCard from "./Cards/Teamcard";
import { toast, ToastContainer } from "react-toastify";
import PaginatioN from "react-js-pagination";
import { paginate } from "../utils/paginate";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { InputGroup, FormControl, Button } from "react-bootstrap";
import "./teamMembers.css";
import BreadCrumb from "./Cards/BreadCrumbs";
import HeadingB from "./Cards/HeadingB";
import Avatars from "./Avatar";
import Divider from "@material-ui/core/Divider";
import team from "../static/noteams.png"
import isEmptyObject from "../utils/isEmptyObject";
import { connect } from "react-redux";
import { setCurrentUser } from "../redux/user/user.actions";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import "react-multi-email/style.css";
import Checkbox from "@material-ui/core/Checkbox";
import _ from "lodash";
import ReactTour from "./ReactTour";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { withRouter } from 'react-router-dom';
import ChatServices from "../services/ChatServices";
import { Badge } from "@mui/material";
import BasicAvatar from "./BasicAvatar";
import { ChatContext } from "../contexts/ChatContext";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles'
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import { Avatar } from "@material-ui/core";


const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'blue',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const steps = [
  {
    selector: '.first-step',
    content: 'Create new group to add members',
    position: 'left',
  },
  {
    selector: '.second-step',
    content: 'Enter email to send invite request to member',
    position: 'bottom',
  },
];

const stepsone = [
  {
    selector: '.first-step',
    content: 'Create new group to add members',
    position: 'left',
  },
  {
    selector: '.second-step',
    content: 'Enter email to send invite request to member',
    position: 'bottom',
  },
  {
    selector: '.third-step',
    content: 'Click to view/add members to the group',
    position: 'bottom',
  },
];

const colors = [
  "#bff603", "yellow", "#80d4ff", "orange",
  "#ff8b94", "#d3ffce", "#9bb7d4", "#ffdb58",
  "#f1a7fe", "#ff6961", "#ffb347", "#add8e6",
  "#a0bfe6", "#89cff0", "#4f86f7", "#67e6ec",
  "#7fffd4", "#77dd77", "#b19cd9", "#ff9999"
];


const styles = muiBaseTheme => ({
  card: {
    maxWidth: 300,
    margin: "auto",
    transition: "0.3s",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)"
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  media: {
    paddingTop: "50.25%"
  },
  content: {
    textAlign: "center",
    padding: muiBaseTheme.spacing.unit * 2,
    // eslint-disable-next-line no-dupe-keys
    textAlign: 'center',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'column',
  },
  divider: {
    margin: `${muiBaseTheme.spacing.unit * 2}px 0`
  },
  heading: {
    fontWeight: "bold"
  },
  subheading: {
    lineHeight: 1.1,
  },
  avatar: {
    backgroundColor: "#80d4ff",
    color: "black",
    display: "inline-block",
    border: "2px solid white",
    "&:not(:first-of-type)": {
      marginLeft: -muiBaseTheme.spacing.unit
    }
  }
});

export class Myteam extends Component {
  state = {
    loading: true,
    userProfile: "",
    inviteEmail: "",
    inviteEmails: [],
    teamMembers: [],
    totalMemberCount: "",
    currentPage: 1,
    pageSize: 8,
    searchQuery: "",
    groups: [],
    otherGroups: [],
    groupMembers: [],
    groupMemberIDs: [],
    groupName: "",
    groupId: "",
    colorID: 0,
    back: [],
    color: ["#bff603", "yellow", "#80d4ff", "orange"],
    filterTeam: [],
    filterGroup: [],
    filterGroupName: [],
    filterOtherGroup: [],
    selectedMembers: [],
    userFeatures: {},
    myGroupPage: 1,
    otherGroupPage: 1,
    reactTour: false,
  };

  static contextType = ChatContext;

  searchOnChange = (e) => {
    if (e.target.value === "") {
      this.setState({
        filterTeam: this.state.teamMembers,
        filterGroup: this.state.groupMembers,
      });
    } else {
      let value = e.target.value;
      let teamMembers = this.state.teamMembers;
      let groupMembers = this.state.groupMembers;
      let result2 = [];
      let result1 = [];
      result1 = teamMembers.filter((member) => {
        console.log(member);
        if (member.name.toLowerCase().search(value.toLowerCase()) !== -1) {
          return member;
        }
      });
      result2 = groupMembers.filter((group) => {
        console.log(group);
        if (group.username.toLowerCase().search(value.toLowerCase()) !== -1) {
          return group;
        }
      });

      this.setState({ filterTeam: result1, filterGroup: result2 });
    }
  };

  handleSearch = (e) => {
    // this.setState({ searchQuery: e.target.value });

    if (e.target.value === "") {
      this.setState({
        filterTeam: this.state.teamMembers,
        filterGroupName: this.state.groups,
        filterOtherGroup: this.state.otherGroups,
      });
    } else {
      let value = e.target.value;

      let teamMembers = this.state.teamMembers;
      let groups = this.state.groups;
      let otherGroups = this.state.otherGroups;

      let result1 = [];
      let result2 = [];
      let result3 = [];

      result1 = teamMembers.filter((member) => {
        console.log(member);
        if (member.name.toLowerCase().indexOf(value.toLowerCase()) > -1) {
          return member;
        }
      });

      result2 = groups.filter((groupmember) => {
        console.log(groupmember);
        if (groupmember.name.toLowerCase().indexOf(value.toLowerCase()) > -1) {
          return groupmember;
        }
      });

      result3 = otherGroups.filter((othergroupmember) => {
        console.log(othergroupmember);
        if (
          othergroupmember.name.toLowerCase().indexOf(value.toLowerCase()) > -1
        ) {
          return othergroupmember;
        }
      });

      this.setState({
        filterTeam: result1,
        filterGroupName: result2,
        filterOtherGroup: result3,
      });
    }
  };

  async componentDidMount() {
    this.getTeam();
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.groupId !== this.state.groupId) {
      this.setState({ selectedMembers: [] });
    }
  }

  getProfile = async () => {
    let user;
    const { currentUser, setCurrentUser, features } = this.props;
    if (currentUser && !isEmptyObject(currentUser)) {
      // user = { ...currentUser };
      user = await userService.getProfile();
      user = user.data;
    } else {
      user = await userService.getProfile();
      console.log("currentUser", user);
      user = user.data;
      setCurrentUser(user);
    }
    this.setState({ userProfile: user, userFeatures: features }, () => {
      this.getOtherGroups();
    });
  };

  getOtherGroups = async () => {
    let other = this.state.userProfile.groups;

    if (other.length > 0) {
      const othergroups = await teamService.getGroupById(other);
      console.log(othergroups);
      this.setState({
        otherGroups: othergroups.data,
        filterOtherGroup: othergroups.data,
      });
    } else {
      this.setState({ otherGroups: [] });
    }
    this.setState({ loading: false });
  };

  //  Get All Team Members
  getTeam = async () => {
    const teamMembers = await userService.getTeam();
    if (teamMembers.status !== 200) {
      if (!this.state.teamMembers.length > 0) {
        // toast.error(teamMembers.message);
        this.setState({ teamMembers: [], filterTeam: [] }, () => {
          this.getGroup();
        });
      } else {
        this.setState({ teamMembers: [], filterTeam: [] }, () => {
          this.getGroup();
        });
      }
    } else {
      this.setState(
        {
          filterTeam: teamMembers.data.user,
          teamMembers: teamMembers.data.user,
          totalMemberCount: teamMembers.data.user.length,
        },
        () => {
          this.getGroup();
        }
      );
    }
  };

  // Get All Groups
  getGroup = async () => {
    const group = await teamService.getAllGroups();
    if (group.status === 200) {
      this.setState({ groups: group.data, filterGroupName: group.data }, () => {
        this.getProfile();
      });
    } else {
      console.log(group.message);
      this.getProfile();
    }
  };

  // Delete Member
  handleDelete = async (user) => {
    Swal.fire({
      title: "Are you sure?",
      text: `"Do you want to remove this team member"`,
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then(async (result) => {
      if (result.value === true) {
        this.setState({ loading: true });
        let data = {
          id: user.id,
        };
        const team = await userService.deleteTeam(data);

        if (team.status === 200) {
          this.getTeam();
          Swal.fire("Deleted!", `${team.message}`, "success");
        } else {
          this.setState({ loading: false });
          Swal.fire("Cancelled", `${team.message}`, "error");
        }
      } else {
        Swal.fire("Cancelled", "Your team member is safe :)", "error");
      }
    });




    // if (team.status !== 200) {
    //   this.setState({ loading: false });
    //   toast.error(team.message);
    // } else {
    //   toast.success(team.message);
    //   this.getTeam();
    // }
  };

  // Pagination Page
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  // Handle Email Invite
  handleSendInvite = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  // Handle Group Name
  handleGroupName = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  // Send Invite
  sendInvite = async (e) => {
    if (this.state.userFeatures.TeamManagement === false) {
      toast("Please upgrade your plan to enable team member features");
      return;
    }
    this.setState({ loading: true });
    e.preventDefault();
    let email = this.state.inviteEmails;
    if (email.length < 1) {
      this.setState({ loading: false });
      toast.error("Please enter valid email !");
    } else {
      const team = await teamService.inviteMember(email);
      if (team.status !== 200) {
        this.setState({ loading: false });
        toast.error(team.message);
      } else {
        this.setState({ loading: false });
        toast.success(team.message, {});
        // this.getTeam();
      }
    }
    this.setState({ inviteEmails: [] });
  };

  // Set Group
  setGroup = async (groupid, groupmembers) => {
    let team = this.state.filterTeam;
    this.setState(
      {
        filterTeam: [],
        groupMembers: [],
        filterGroup: [],
        groupId: groupid,
        loading: true,
      },
      () => this.setState({ filterTeam: team })
    );

    if (groupmembers.length > 0) {
      const groupdata = await teamService.getGroupMembers(groupmembers);
      if (groupdata.status === 200) {
        this.setState({
          groupMembers: groupdata.data,
          filterGroup: groupdata.data,
        });
      }
    }
    this.setState({ loading: false, groupMemberIDs: groupmembers });
  };

  // Create Group
  createGroup = async (e) => {
    e.preventDefault();
    if (this.state.userFeatures.TeamManagement === false) {
      toast("Please upgrade your plan to enable team member features");
      return;
    }

    this.setState({ loading: true });
    let currentGroup = this.state.groups;
    let groupName = this.state.groupName;

    let userProfile = this.state.userProfile;
    let match = 0;

    if (groupName === "") {
      this.setState({ loading: false });
      toast.error(`Invalid Group Name!`);
      return;
    }

    if (currentGroup.length > 0) {
      for (let i = 0; i < currentGroup.length; i++) {
        if (groupName === currentGroup[i].name) {
          this.setState({ loading: false, groupName: "" });
          toast(`${groupName} Already exists!`);
          match = 1;
          return;
        }
      }
    }

    if (match === 0) {
      let data = {
        groupname: groupName,
        ownerName: userProfile.username,
      };
      const team = await teamService.createGroup(data);
      if (team.status === 200) {
        this.getGroup();
        toast.success(team.message);
      } else {
        this.setState({ loading: false });
        toast.error(team.message);
      }
    }

    this.setState({ groupName: "" });
  };



  onChangeSelectionBox = (team) => (e) => {
    let selected = this.state.selectedMembers;
    if (e.target.checked === true) {
      selected.push(team.id);
    } else {
      selected.splice(selected.indexOf(team.id), 1);
    }
    this.setState({ selectedMembers: selected });
  };

  // Add Members To Group
  addMembersToGroup = async () => {
    let groupMembers = this.state.groupMemberIDs;
    let selectedMembers = this.state.selectedMembers;

    if (selectedMembers.length < 1) {
      toast("No Member selected");
      return;
    } else {
      let presents = _.intersectionWith(
        groupMembers,
        selectedMembers,
        _.isEqual
      );
      if (presents.length > 0) {
        for (let i = 0; i < presents.length; i++) {
          selectedMembers.splice(selectedMembers.indexOf(presents[i]), 1);
        }
      }
    }

    if (selectedMembers.length > 0) {
      let data = {
        memberArray: selectedMembers,
        groupID: this.state.groupId,
      };

      const res = await teamService.addMemberToGroup(data);
      if (res.status === 200) {
        this.getGroup();
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
      this.setState({ selectedMembers: [] });
      return;
    } else {
      toast("Member already exists");
      return;
    }
  };

  // Add Members To Group
  // addMemberToGroup = async team => {
  //   let groupMembers = this.state.groupMembers;

  //   for (let i = 0; i < groupMembers.length; i++) {
  //     if (team.id === groupMembers[i]._id) {
  //       toast("Member Already Exist in Group !");
  //       return;
  //     }
  //   }

  //   let data = {
  //     memberID: team.id,
  //     groupID: this.state.groupId
  //   };

  //   const res = await teamService.addMemberToGroup(data);
  //   if (res.status === 200) {
  //     this.getGroup();
  //     toast.success(res.message);
  //   } else {
  //     toast.error(res.message);
  //   }
  // };

  // Remove Member from Group
  removeMemberFromGroup = async (id) => {
    let data = {
      memberID: id,
      groupID: this.state.groupId,
    };

    const res = await teamService.removeMemberFromGroup(data);
    if (res.status === 200) {
      this.getGroup();
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
  };

  // Leave Group
  leaveGroup = async () => {
    this.setState({ loading: true });
    let data = {
      _id: this.state.groupId,
    };
    const response = await teamService.leaveGroup(data);
    if (response.status === 200) {
      this.getProfile();
      toast.success(response.message);
    } else {
      toast.error(response.message);
      this.setState({ loading: false });
    }
  };



  // Delete Group
  deleteGroup = async (group) => {
    Swal.fire({
      title: "Are you sure?",
      text: `"Do you want to delete ${group.name}"`,
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then(async (result) => {
      if (result.value === true) {
        this.setState({ loading: true });
        let data = {
          _id: group._id,
          members: group.members,
        };

        const response = await teamService.deleteGroup(data);
        if (response.status === 200) {
          this.getGroup();
          Swal.fire("Deleted!", `${response.message}`, "success");
        } else {
          this.setState({ loading: false });
          Swal.fire("Cancelled", `${response.message}`, "error");
        }
      } else {
        Swal.fire("Cancelled", "Your Group is safe :)", "error");
      }
    });
  };

  getPagedData = () => {
    let {
      teamMembers,
      currentPage,
      pageSize,
      searchQuery,
      filterGroupName,
      filterOtherGroup,
    } = this.state;

    let filteredMembers = teamMembers;

    if (searchQuery) {
      filteredMembers = teamMembers.filter((team) =>
        team.name.toLowerCase().startsWith(searchQuery.toLowerCase())
      );
      filterGroupName = filterGroupName.filter((team) =>
        team.name.toLowerCase().startsWith(searchQuery.toLowerCase())
      );
      filterOtherGroup = filterOtherGroup.filter((team) =>
        team.name.toLowerCase().startsWith(searchQuery.toLowerCase())
      );
    }

    const team = paginate(filteredMembers, currentPage, pageSize);
    const myGroups = paginate(filterGroupName, this.state.myGroupPage, 9);
    const otherGroups = paginate(
      filterOtherGroup,
      this.state.otherGroupPage,
      9
    );

    return {
      totalMemberCount: filteredMembers.length,
      teamMembers: team,
      myGroups: myGroups,
      paginateOtherGroups: otherGroups,
      myGroupsCount: filterGroupName.length,
      otherGroupsCount: filterOtherGroup.length,
    };
  };

  handleOpenChat = async (id) => {

    var userProfile = this.state.userProfile;
    try {
      const response = await ChatServices.createContact(userProfile._id, id);
      if (response.status === 200) {
        const { history } = this.props;
        history.push({
          pathname: `/chat`,
        });
      } else {
        console.error("Error Opening Chat:", response.message);
      }
    } catch (error) {
      console.error("Error calling backend API:", error.message);
    }
  };


  handleOpenGroupChat = async (groupName, selectedContacts, groupId) => {
    try {
      const response = await ChatServices.createGroup(groupName, selectedContacts, groupId);
      if (response.status === 200) {
        const { history } = this.props;
        history.push({
          pathname: `/chat`,
        });
      } else {
        console.error("Error Opening Chat:", response.message);
      }
    } catch (error) {
      console.error("Error calling backend API:", error.message);
    }
  };




  render() {
    let {
      currentPage,
      groups,
      otherGroups,
      filterTeam,
      filterGroup,
      filterGroupName,
      filterOtherGroup,
    } = this.state;

    let {
      teamMembers,
      totalMemberCount,
      myGroups,
      paginateOtherGroups,
      myGroupsCount,
      otherGroupsCount,
    } = this.getPagedData();

    const popover = (
      <Popover id="popover-basic" style={{ zIndex: "9999", padding: "1px" }} className="popover-teams">
        <Popover.Content>

          <ReactMultiEmail
            placeholder="Enter Email id"
            emails={this.state.inviteEmails}
            onChange={(_emails) => {
              this.setState({ inviteEmails: _emails });
            }}
            validateEmail={(email) => {
              return isEmail(email); // return boolean
            }}
            getLabel={(email, index, removeEmail) => {
              return (
                <div data-tag key={index} style={{ fontSize: "10px", color: "white", backgroundColor: "#007bff" }}>
                  {email}
                  <span data-tag-handle onClick={() => removeEmail(index)}>
                    ×
                  </span>
                </div>
              );
            }}
          />
          <small className="creategroup-text">*Multiple invites can be sent</small>
          <button
            className="btn btn-outline w-100 mt-2"
            onClick={this.sendInvite}
            id="btncolor" style={{width:'60px', border:'none', borderRadius:"3px", color:'white'}}
          >
            Send Invite
          </button>

          {/* <form onSubmit={this.sendInvite}>
            <InputGroup className="mb-3">
              <FormControl
                type="email"
                name="inviteEmail"
                value={this.state.inviteEmail}
                onChange={this.handleSendInvite}
                placeholder="Member Email"
                aria-label="Member Email"
                aria-describedby="basic-addon2"
              />

              <InputGroup.Append>
                <Button variant="outline-primary" type="submit">
                  Invite
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </form> */}
        </Popover.Content>
      </Popover>
    );

    const pop = (
      <Popover id="popover-basic" style={{ zIndex: "1010", padding: "1px" }} className="popover-teams">
        <Popover.Content>
          <h6 className="creategroup-text">Create a new group</h6>
          {/* <button className="btn btn-outline-danger" onClick={this.ok}>Create Group</button> */}
          <form onSubmit={this.createGroup}>
            <InputGroup className="mb-3">
              <FormControl
                type="text"
                name="groupName"
                value={this.state.groupName}
                onChange={this.handleGroupName}
                placeholder="Group Title"
                aria-label="Group Title"
                aria-describedby="basic-addon2"
                className="groupname"
              />
              {/* <InputGroup.Append> */}
                <button type="submit" id="btncolor" style={{width:'60px', border:'none', borderRadius:"3px", color:'white'}}>
                  Create
                </button>
              {/* </InputGroup.Append> */}
            </InputGroup>
          </form>
        </Popover.Content>
      </Popover>
    );
    console.log(myGroupsCount, "myGroupsCount");

    return (
      <div>
        <Loader loading={this.state.loading} />
        <ToastContainer />

        <div className="" >
          {/* <BreadCrumb l1="Home" l2="My Team" dashboardlink="/dashboard" /> */}

          <div className="row ">
            {/* Title */}

            {/* <div className="col-md-1"> */}

            {/* <HeadingB text="Teams" /> */}

            {/* <p className="ft_lg text-e">Teams</p> */}

            {/* </div> */}

            {/* Search Bar */}


            <div className="col-md-6" >
              <TextField InputLabelProps={{ shrink: true }}
                label="Serach"
                placeholder="Enter Group name or Team member"
                name="searchQuery"
                onChange={this.handleSearch}
                className="custom-textfield"
                sx={{
                  width: '80%',
                  '& .MuiInputBase-root': {
                    padding: '0',
                    margin: '0',
                    height: "35px",
                    paddingLeft: "-5px"
                  },
                }}
              />
            </div>



            {/* Create Group Button */}
            <div className="first-step col-sm-2 text-right">
              <OverlayTrigger
                trigger="click"
                placement="bottom"
                overlay={pop}
                rootClose={true}
              >
                <button
                  className="btn btn-primary createGroup"
                  style={{ width: "90%" , border: "#3f51b5"}}
                  id="btncolor"
                >
                  Create group
                </button>
              </OverlayTrigger>
            </div>

            {/* Send Invite Button */}
            <div className="second-step col-sm-2">
              <OverlayTrigger
                trigger="click"
                placement="bottom"
                overlay={popover}
                rootClose={true}
              >
                <button
                  className="btn btn-primary inviteMember"
                  style={{ width: "90%", border: "#3f51b5" }}
                  id="btncolor"
                >
                  Invite
                  {/* <i className="fa fa-plus-circle" aria-hidden="true">
                    &nbsp; 
                  </i> */}
                </button>
              </OverlayTrigger>
            </div>

            <div className="col-md-1">
              <LightTooltip title="Need Help ?">
                <i
                  className="fa fa-question-circle mt-2"
                  aria-hidden="true"
                  // data-toggle="tooltip"
                  // title="Need Help ?"
                  style={{
                    cursor: "pointer",
                    fontSize: "15px"
                  }}
                  id="text-color"
                  onClick={() => this.setState({ reactTour: true })}
                ></i>
              </LightTooltip>
            </div>

          </div>
          <br />
          <Divider light />

          {teamMembers.length === 0 && groups.length === 0 && otherGroups.length === 0 ? (
            <div className="text-center">
              <h1 style={{ marginTop: "15%" }}>
                <img src={team} style={{ marginTop: "-65px" }} />
              </h1>
            </div>
          ) : (
            <>
              {teamMembers.length > 0 && (
                <p className="mt-4 mb-2 ft_md ft-wt-500" id="text-color">Team Members</p>
              )}
              {/* User Cards */}
              {/* <TeamCard teamdata={teamMembers} grpData={groups} /> */}
              <div className="row pl-2">
                {filterTeam.map((team) => (
                  <TeamCard
                    teamdata={team}
                    grpData={groups}
                    deleteMember={() => this.handleDelete(team)}
                    openChat={() => this.handleOpenChat(team?.id)}
                  />
                ))}
              </div>

              <div className="row">
                {/* Pagination */}
                {totalMemberCount > 8 ? (
                  <div
                    className="row ml-auto"
                    style={{ margin: "2% 1% 0% 0%" }}
                  >
                    <PaginatioN
                      activePage={currentPage}
                      itemsCountPerPage={this.state.pageSize}
                      totalItemsCount={totalMemberCount}
                      pageRangeDisplayed={3}
                      onChange={this.handlePageChange}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>

              {groups.length > 0 && (
                <p className="mb-2 ft_md ft-wt-500" style={{ marginTop: '30px' }}id="text-color">My Groups</p>
              )}

              {/* Group Cards */}
              <div className="row">
                {filterGroupName.length > 0 &&
                  myGroups.map((group) => (
                    <div
                      className="col-md-3 res-Group-card DocumentCardHover third-step"
                      key={group._id}
                      style={{ marginTop: "1%" }}
                    >
                      <div className="card setShadow" style={{ borderRadius: "10px", height: 230, }}>
                        <div className="card-body" style={{ height: 190 }} >
                          <div className="row" style={{ flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <div className="ml-2" >
                              <BasicAvatar
                                onClick={() =>
                                  this.setGroup(group._id, group.members)
                                }
                                name={group.name.charAt(0).toUpperCase()}
                                isTeam="team"
                                colorIndex={groups.indexOf(group) % 4}

                              />
                            </div>
                            {/* Group Detail */}
                            <div
                              onClick={() =>
                                this.setGroup(group._id, group.members)
                              }
                              data-toggle="modal"
                              data-target="#myModal"
                              style={{
                                cursor: "pointer",
                                paddingRight: "3%",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                width: "100%"
                              }}
                            >
                              <div className="ft_lg m-0 text-center" style={{width: "100%"}}>
                                <small>
                                  <strong>{group.name}</strong>
                                </small>
                              </div>
                              <div className="ft_sm text-a m-0 text-center" style={{width: "100%"}}>
                                <small>
                                  <strong style={{ color: "gray" }}>Admin : </strong>
                                </small>
                                <small id="text-color">{this.state.userProfile.username}</small>
                              </div>
                              {/* <p className="ft_sm text-a m-0">
                                <small>{group.members.length} Members</small>{" "}
                              </p> */}
                            </div>
                            <div style={{ display: "flex", justifyContent: "center", marginTop: "10px", position: "relative" }} onClick={() =>
                              this.setGroup(group._id, group.members)
                            } data-toggle="modal"
                              data-target="#myModal">
                              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", alignContent: "center" }} >
                                {group.members.slice(0, 4).map((member, index) => (
                                  <Avatar
                                    key={index}
                                    isGroupMember = "groupMember" 
                                    className={styles.avatar}
                                    style={{
                                      backgroundColor: colors[index % colors.length],
                                      marginLeft: index > 0 ? "-10px" : "0", // Adjust margin for overlapping horizontally
                                      position: index > 0 ? "relative" : "static", // Set position to relative for overlapped avatars
                                      zIndex: index > 0 ? "1" : "0", // Ensure the second avatar appears above the first one
                                    }}
                                  >
                                    <LightTooltip title={member.username}>
                                    <h5 style={{ margin: "4px 0" }} >
                                      {member.username.charAt(0).toUpperCase()}
                                    </h5>
                                    </LightTooltip>
                                  </Avatar>
                                ))}
                                {group.members.length > 4 && <span style={{ cursor: "pointer", color: "#007bff" }}>+View all</span>}
                              </div>
                            </div>
                          </div>

                        </div>
                        <div style={{ width: "100%", display: 'flex', justifyContent: 'center', flexDirection: 'row-reverse', alignItems: "center", alignContent: "center", borderTop: "1px solid #D3D3D3" }}>
                          <div style={{ padding: "12px 0", width: "50%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <LightTooltip title="Delete Group">
                              <svg
                                style={{
                                  cursor: "pointer",
                                  color: "red"
                                }}
                                data-placement="right"
                                aria-hidden="true"
                                onClick={() => this.deleteGroup(group)}
                                xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                                <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                              </svg>
                            </LightTooltip>

                          </div>

                          <div style={{ padding: "12px 0", borderRight: "1px solid #D3D3D3 ", width: "50%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                            <LightTooltip title="ChatBox">
                              <Badge badgeContent={group.unreadCount} color="secondary">
                                <ChatBubbleOutlineOutlinedIcon
                                  className="fa fa-commenting"
                                  style={{ color: "dodgerblue", fontSize: "19px", cursor: "pointer", }}
                                  aria-hidden="true"
                                  data-placement="right"
                                  onClick={() => this.handleOpenGroupChat(group.name, group.members, group._id)}
                                  data-dismiss="modal"
                                ></ChatBubbleOutlineOutlinedIcon>
                              </Badge>
                            </LightTooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>

              {/* My Groups Pagination */}
              <div className="row">
                {myGroupsCount > 9 ? (
                  <div
                    className="row ml-auto"
                    style={{ margin: "2% 1% 0% 0%" }}
                  >
                    <PaginatioN
                      activePage={this.state.myGroupPage}
                      itemsCountPerPage={9}
                      totalItemsCount={myGroupsCount}
                      pageRangeDisplayed={3}
                      onChange={(page) => this.setState({ myGroupPage: page })}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>

              {otherGroups.length > 0 && (
                <p className="mt-4 mb-2 ft_md ft-wt-500" id="text-color">Other Groups</p>
              )}

              {/* Other Group Cards otherGroups */}
              <div className="row">
                {filterOtherGroup.length > 0 &&
                  paginateOtherGroups.map((group) => (
                    <div
                      className="col-md-3 res-Group-card DocumentCardHover"
                      key={group._id}
                      style={{ marginTop: "1%" }}
                    >
                      <div className="card setShadow" style={{ borderRadius: "10px", height: 230, }}>
                        <div className="card-body" style={{ height: 190 }}>
                          <div className="row" style={{ flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <div className="ml-2">
                              <BasicAvatar
                                name={group.name.charAt(0).toUpperCase()}
                                colorIndex={otherGroups.indexOf(group) % 4}
                                isTeam="team"
                              />
                            </div>

                            {/* Group Detail */}
                            <div
                              onClick={() =>
                                this.setGroup(group._id, group.members)
                              }
                              data-toggle="modal"
                              data-target="#SharedGroupModal"
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <div className="ft_lg m-0 text-center">
                                <small>
                                  <strong>{group.name}</strong>
                                </small>
                              </div>
                              {/* <p className="text-a m-0">
                                <small>{group.members.length} Members</small>
                              </p> */}
                              <div className="text-a m-0 text-center">
                                <small>
                                  <strong style={{ color: "gray" }}>Admin : </strong>
                                </small>
                                <small id="text-color">{group.owner ? group.ownerName : 'PET User'}</small>
                              </div>
                            </div>

                            <div style={{ display: "flex", justifyContent: "center", marginTop: "10px", position: "relative" }} onClick={() =>
                              this.setGroup(group._id, group.members)
                            } data-toggle="modal"
                              data-target="#SharedGroupModal">
                              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", alignContent: "center" }} >
                                {group.members.slice(0, 4).map((member, index) => (
                                  <Avatar
                                    key={index}
                                    className={styles.avatar}
                                    style={{
                                      backgroundColor: colors[index % colors.length],
                                      marginLeft: index > 0 ? "-10px" : "0", // Adjust margin for overlapping horizontally
                                      position: index > 0 ? "relative" : "static", // Set position to relative for overlapped avatars
                                      zIndex: index > 0 ? "1" : "0", // Ensure the second avatar appears above the first one
                                    }}
                                  >
                                    <LightTooltip title={member.username}>
                                    <h5 style={{ margin: "4px 0" }} >
                                      {member.username.charAt(0).toUpperCase()}
                                    </h5>
                                    </LightTooltip>
                                  </Avatar>
                                ))}
                                {group.members.length > 4 && <span style={{ cursor: "pointer", color: "#007bff" }}>+View all</span>}
                              </div>
                            </div>

                          </div>
                        </div>
                        <div style={{ width: "100%", display: 'flex', justifyContent: 'center', flexDirection: 'row-reverse', alignItems: "center", alignContent: "center", borderTop: "1px solid #D3D3D3" }}>
                          <div style={{ padding: "12px 0", width: "50%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <LightTooltip title="Leave Group">
                              <svg
                                style={{
                                  cursor: "pointer",
                                  color: "red"
                                }}
                                data-placement="right"
                                aria-hidden="true"
                                onClick={() =>
                                  this.setGroup(group._id, group.members)
                                }
                                data-toggle="modal"
                                data-target="#SharedGroupModal"
                                xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                                <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                              </svg>
                            </LightTooltip>

                          </div>

                          <div style={{ padding: "12px 0", borderRight: "1px solid #D3D3D3 ", width: "50%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                            <LightTooltip title="ChatBox">
                              <Badge badgeContent={group.unreadCount} color="secondary">
                                <ChatBubbleOutlineOutlinedIcon
                                  className="fa fa-commenting"
                                  style={{ color: "dodgerblue", fontSize: "19px", cursor: "pointer", }}
                                  aria-hidden="true"
                                  data-placement="right"
                                  onClick={() => this.handleOpenGroupChat(group.name, group.members, group._id)}
                                  data-dismiss="modal"
                                ></ChatBubbleOutlineOutlinedIcon>
                              </Badge>
                            </LightTooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>

              {/* Other Groups Pagination */}
              <div className="row">
                {otherGroupsCount > 9 ? (
                  <div
                    className="row ml-auto"
                    style={{ margin: "2% 1% 0% 0%" }}
                  >
                    <PaginatioN
                      activePage={this.state.otherGroupPage}
                      itemsCountPerPage={9}
                      totalItemsCount={otherGroupsCount}
                      pageRangeDisplayed={3}
                      onChange={(page) =>
                        this.setState({ otherGroupPage: page })
                      }
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </>
          )}
        </div>

        {/* <!-- Admin Group Modal --> */}
        <div class="modal fade" id="myModal">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              {/* <!-- Modal Header --> */}
              <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              {/* <!-- Modal body --> */}
              <div class="modal-body">
                <div className="row">
                  <input
                    className="group-modal"
                    type="text"
                    onChange={this.searchOnChange}
                    placeholder="&#xF002; Search Username / Group"
                    aria-label="Search"
                    style={{
                      fontFamily: "FontAwesome",
                      height: "50px",
                      marginTop: "-16px",
                      paddingLeft: "25px",
                      marginLeft: "1px",
                      width: "498px",
                    }}
                  />
                </div>
                <div className="row pt-2">
                  <div className="col">
                    <h6 className="teamHead">Group Members</h6>
                  </div>
                </div>

                <div
                  style={{
                    overflowY: "auto",
                    overflowX: "hidden",
                    height: "20vh",
                  }}
                >
                  {filterGroup.length > 0 &&
                    filterGroup.map((group) => (
                      <div
                        className="row"
                        key={group._id}
                        style={{ marginTop: "5%" }}
                      >
                        <div className="col-3">
                          <Avatars
                            name={group.username.charAt(0).toUpperCase()}
                            colorIndex={filterGroup.indexOf(group) % 4}
                            size={30}
                            status={group.status}
                          />

                        </div>
                        <div className="col-7">
                          {/* <p className="ft_lg m-0"> */}
                          <small>
                            <strong className="adminGrpUserName">
                              {group.username}
                            </strong>
                          </small>
                          {/* </p> */}
                        </div>

                        {/* Delete Button */}
                        <div className="col-2">
                          <i
                            className="fa fa-trash adminGrpDeleteBtn"
                            style={{ color: "red" }}
                            aria-hidden="true"
                            onClick={() =>
                              this.removeMemberFromGroup(group._id)
                            }
                            data-dismiss="modal"
                          ></i>
                        </div>
                      </div>
                    ))}
                </div>

                <hr />

                <div className="row">
                  <div className="col-md-4">
                    <h6 className="teamHead">Friend List</h6>
                  </div>
                  <div className="col-md-4"></div>
                  <div className="col-md-4" style={{ paddingLeft: "100px" }}>
                    <button
                      className="btn btn-primary"
                      onClick={this.addMembersToGroup}
                      data-dismiss="modal"
                    >
                      ADD
                    </button>
                  </div>
                </div>

                <div
                  style={{
                    overflowY: "auto",
                    overflowX: "hidden",
                    height: "20vh",
                  }}
                >
                  {filterTeam.length > 0 &&
                    filterTeam.map((team) => (
                      <div
                        className="row"
                        key={team._id}
                        style={{ marginTop: "5%" }}
                      >
                        <div className="col-3">
                          {/* User Display Picture */}
                          <Avatars
                            name={team.name.charAt(0).toUpperCase()}
                            status={team.status}
                            colorIndex={filterTeam.indexOf(team) % 4}
                          />
                        </div>
                        <div className="col-7">
                          <p className="">
                            <small>
                              <strong className="adminGrpUserName">
                                {team.name || "-"}
                              </strong>
                            </small>
                          </p>
                        </div>

                        {/* ADD Button */}
                        <div className="col-2">
                          <Checkbox
                            color="primary"
                            onChange={this.onChangeSelectionBox(team)}
                          />
                          {/* <i
                            className="fa fa-plus adminGrpDeleteBtn"
                            style={{ color: "#007bff" }}
                            aria-hidden="true"
                            onClick={() => this.addMemberToGroup(team)}
                            data-dismiss="modal"
                          ></i> */}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Admin Group Modal */}

        {/* Shared Group Modal */}
        <div class="modal fade" id="SharedGroupModal">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              {/* <!-- Modal Header --> */}
              <div class="modal-header">
                <h4 className="adminGrpUserName">Group Members</h4>
                <button type="button" class="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              {/* <!-- Modal body --> */}
              <div class="modal-body">
                {/* Search Bar */}
                <div className="row">
                  <input
                    className="group-modal"
                    type="text"
                    onChange={this.searchOnChange}
                    placeholder="&#xF002; Search Username / Group"
                    aria-label="Search"
                    style={{
                      fontFamily: "FontAwesome",
                      height: "50px",
                      marginTop: "-16px",
                      paddingLeft: "50px",
                      marginLeft: "1px",
                      width: "498px",
                    }}
                  />
                </div>

                {/* Members */}
                <div
                  style={{
                    overflowY: "auto",
                    overflowX: "hidden",
                    height: "20vh",
                  }}
                >
                  {filterGroup.length > 0 &&
                    filterGroup.map((group) => (
                      <div
                        className="row"
                        key={group._id}
                        style={{ marginTop: "5%" }}
                      >
                        <div className="col-3">
                          {/* User Display Picture */}
                          {/* <img
                            className=""
                            src={group.display_picture}
                            alt=""
                            style={{
                              width: "30px",
                              height: "30px",
                              borderRadius: "50%",
                            }}
                          ></img> */}
                          <Avatars
                            name={group.username.charAt(0).toUpperCase()}
                            status={group.status}
                            colorIndex={filterGroup.indexOf(group) % 4}
                            size={30}
                          />
                        </div>
                        <div className="col-9  mt-0 grp_mem_modal_text">
                          <small>
                            <strong className="adminGrpUserName">
                              {group.username}
                            </strong>
                          </small>
                        </div>
                      </div>
                    ))}
                </div>
              </div>

              <div className="modal-footer">
                <button
                  className="btn btn-danger mr-auto"
                  onClick={() => this.leaveGroup()}
                  data-dismiss="modal"
                >
                  <i class="fa fa-sign-out" aria-hidden="true"></i> Leave Group
                </button>
                <button className="btn btn-secondary" data-dismiss="modal">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Shared Group Modal */}
        <ReactTour
          steps={groups.length > 0 ? stepsone : steps}
          isOpen={this.state.reactTour}
          closeTour={() => this.setState({ reactTour: false })}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  features: state.features.features,
});
const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Myteam));

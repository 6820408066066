import React, { useEffect, useState } from 'react';
import { Editor, EditorState, ContentState, RichUtils } from 'draft-js';
import SelectDropdownMui from '../../ReusableComponents/SelectDropdownMui';
import { checkTranslator } from '../../../services/translatorServices';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import 'draft-js/dist/Draft.css';
import CloseIcon from '@mui/icons-material/Close';
import { toast, ToastContainer } from "react-toastify";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import TranslateIcon from '@mui/icons-material/Translate';
import { Button, IconButton, Typography, Modal } from '@material-ui/core';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ArticleIcon from '@mui/icons-material/Article';
import CoAuther_TranslatorPage from './CoAuther_Translator/CoAuther_TranslatorPage';
import CoAuther_ParaphraserPage from './CoAuther_Paraphraser/CoAuther_ParaphraserPage';
import CoAuther_SummariserPage from './CoAuther_Summariser/CoAuther_SummariserPage';
import { FaBookOpen } from "react-icons/fa";
import paraphrase_logo from "../../../static/Paraphrase 1.png"
import paraphrase_logo1 from "../../../static/Paraphrase_1purple.png"



import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { closeLeftNavbar, closeRightModal, closeRightModalAndOpenLeftNavbar, openLeftNavbar, openLowerModalAndCloseLeftNavbar, openRightModal, openRightModalAndCloseLeftNavbar } from '../../../redux/handleSideNavBarAndModel/handleStatus.action';


const CustomTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'blue',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);






const CoAuthorLowerModel = (props) => {
  
  const [translatedText, setTranslatedText] = useState('');
  const [secondaryeditorStateTranslator, setSecondaryEditorStateTranslator] = useState(EditorState.createEmpty());
  const [showTranslateContent, setShowTranslateContent] = useState(true);
  const [showResearchContent, setShowResearchContent] = useState(false);
  const [showTextSnippetContent, setShowTextSnippetContent] = useState(false);
  const [showFormatQuoteContent, setShowFormatQuoteContent] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);
  // const [callSummarise, setCallSummarise] = useState(false);
  const dispatch = useDispatch();
  const { rightModalOpen, leftNavbarOpen, lowerModelOpen } = useSelector(state => state.handleModelAndSideBavBar);

  const deleteEditorFroSummarizer = (value) => {
    if (value === true) {
      props.handleDeleteinSummarizer(true)
    }
  }

  const [isDarkMode, setIsDarkMode] = useState(false);
  useEffect(() => {
      const updateTheme = () => {
        const theme = localStorage.getItem('ThemeSwitch');
        setIsDarkMode(theme === 'true');
      };
      updateTheme();
      window.addEventListener('storage', updateTheme);
      const originalSetItem = localStorage.setItem;
      localStorage.setItem = function (key, value) {
        originalSetItem.apply(this, arguments);
        if (key === 'ThemeSwitch') {
          updateTheme(); 
        }
      };
      return () => {
        window.removeEventListener('storage', updateTheme);
        localStorage.setItem = originalSetItem; 
      };
    }, []);


    console.log(isDarkMode,"HHH")

  const handleCloseTranslator = () => {
    props.handleCloseTranslate(false)
  };


  function handleParaphrase() {
    props.setTrackIsModalOpen(true);
    props.setTranslator(false)
    props.setSummariser(false)
    props.setParaphraser(true)
    console.log("inside para");
    dispatch(openLowerModalAndCloseLeftNavbar())

  }

  function handleSummarise() {
    console.log("inside summariser");
    props.setTrackIsModalOpen(true);
    dispatch(openLowerModalAndCloseLeftNavbar())
    props.setTranslator(false)
    props.setSummariser(true)
    props.setParaphraser(false)

    // if(callSummarise == false){
    //   setCallSummarise(true)
    // }else {
    //   setCallSummarise(false)

    // }
  }

  function handleTranslate() {
    props.setTrackIsModalOpen(true);
    dispatch(openLowerModalAndCloseLeftNavbar())
    props.setTranslator(true)
    props.setSummariser(false)
    props.setParaphraser(false)
    console.log("inside translator");

  }



useEffect(()=>{
  console.log("isDarkMode",isDarkMode)
},[isDarkMode])


  return (

    !props.mobileView ?

    <>


      {lowerModelOpen && (
        
        <div className="Serive_div"
          style={{ width:'468px', zIndex: '1000', height: '90vh', position: "fixed", top: "65px", right: "0px", border: '1px solid  grey', backgroundColor: isDarkMode?"black":"white" }}
        > 






          <div style={{ display: 'flex', width: '100%', justifyContent: "space-between", alignItems: "center", border: "1px solid grey ", position: "absolute", top: "0px" }}>
            <div style={{ display: 'flex' }}>
              <div style={{ border: "1px solid grey", borderLeft: 'none', borderTop: 'none', borderBottom: 'none', borderRight: 'none', alignItems: "center" }}>
                {props.paraphraser ? (
                  <IconButton style={{ outline: 'none', color: props.paraphraser ? 'red' : '#3d83fa',padding:"6px 10px"}} onClick={() => handleParaphrase()}>
                   <img
                    src={paraphrase_logo1}
                    alt="Paraphrase"
                    style={{width:'21px'}}
                />
                    <div style={{ color: props.paraphraser ? '#3d83fa' : 'grey' }}></div>
                    {props.paraphraser &&
                      <div><h6 style={{ color: isDarkMode ? "#ECE6F0" : '#5C02A9', marginTop: "7px", marginLeft: '2px' }}>Paraphrase</h6></div>
                    }
                  </IconButton>
                ) : (
                  <CustomTooltip title='Paraphrase'>
                    <IconButton style={{ outline: 'none', color: props.paraphraser ? 'red' : '#3d83fa' ,marginTop:"6px",padding:"6px 10px"}} onClick={() => handleParaphrase()}>
                    <img
                    src={paraphrase_logo}
                    alt="Paraphrase" 
                    style={{width:'21px'}}
                />
                      <div style={{ color: props.paraphraser ? '#3d83fa' : 'grey' }}></div>
                      {props.paraphraser &&
                        <div><h6 style={{ color: isDarkMode ? "#ECE6F0" :  'indigo', marginTop: "7px", marginLeft: '2px' }}>Paraphrase</h6></div>
                      }
                    </IconButton>
                  </CustomTooltip>
                )}
              </div>

              <div style={{ border: "1px solid grey", borderTop: 'none', borderRight: 'none', borderBottom: 'none' }}>
                {props.summariser ? (
                  <IconButton style={{ outline: 'none', color: props.summariser ? 'red' : '#3d83fa',padding:"6px 10px" }} onClick={() => handleSummarise()}>
                    <FaBookOpen style={{ marginRight: '3px', width: '18px', height: '18px' }} />
                    <div style={{ color: props.summariser ? '#3d83fa' : 'grey' }}></div>
                    {props.summariser &&
                      <div>
                        <h6 style={{ color: isDarkMode ? "#ECE6F0" : 'red', marginTop: "7px", marginLeft: '2px' }}>Summarize</h6>
                      </div>
                    }
                  </IconButton>
                ) : (
                  <CustomTooltip title='Summarize'>
                    <IconButton style={{ outline: 'none', color: props.summariser ? 'red' : '#3d83fa' ,marginTop:"6px",padding:"6px 10px"}} onClick={() => handleSummarise()}>
                      <FaBookOpen style={{ marginRight: '3px', width: '18px', height: '18px' }} />
                      <div style={{ color: props.summariser ? '#3d83fa' : 'grey' }}></div>
                      {props.summariser &&
                        <div>
                          <h6 style={{ color: isDarkMode ? "#ECE6F0" :  'red', marginTop: "7px", marginLeft: '2px' }}>Summarize</h6>
                        </div>
                      }
                    </IconButton>
                  </CustomTooltip>
                )}
              </div>

              <div style={{ border: "1px solid grey", borderTop: 'none', borderBottom: 'none', borderRight: 'none' }}>
                {props.translator ? (
                  <IconButton style={{ outline: 'none', color: props.translator ? 'orange' : '#3d83fa' ,padding:"6px 10px"}} onClick={() => handleTranslate()}>
                    <TranslateIcon />
                    <div style={{ color: props.translator ? '#3d83fa' : 'grey' }}></div>
                    {props.translator &&
                      <div>
                        <h6 style={{ color: 'orange', marginTop: "7px", marginLeft: '2px' }}>Translator</h6>
                      </div>
                    }
                  </IconButton>
                ) : (
                  <CustomTooltip title="Translator">
                    <IconButton style={{ outline: 'none', color: props.translator ? 'orange' : '#3d83fa' ,marginTop:"6px",padding:"6px 10px"}} onClick={() => handleTranslate()}>
                      <TranslateIcon />
                      <div style={{ color: props.translator ? '#3d83fa' : 'grey' }}></div>
                      {props.translator &&
                        <div>
                          <h6 style={{ color: 'orange', marginTop: "7px", marginLeft: '2px' }}>Translator</h6>
                        </div>
                      }
                    </IconButton>
                  </CustomTooltip>
                )}
              </div>



            </div>
            <div  style={{marginRight:"5px"}} onClick={props.handleCloseTranslate}>
              <CloseIcon  style={{color:isDarkMode?"red":""}}/>
            </div>


          </div>

          <div style={{ position: "relative", marginTop: "50px", justifyContent: "center", width: "90%", margin: "auto" }}>


            {props.translator && (

              <CoAuther_TranslatorPage
                isDarkMode={isDarkMode}
                editorState={props.editorState}
                setEditorState={props.setEditorState}
                onGrandchildStateChange={props.onChildStateChange}

              />

            )}
            {props.summariser && (

              <CoAuther_SummariserPage
              isDarkMode={isDarkMode}
                righteditorContent={props.righteditorContent}
                handleDeleteinSummarizer={deleteEditorFroSummarizer}
                CallSummarise={props.callSummarise}
                editorState={props.editorState}
                setEditorState={props.setEditorState} />
            )}

            {props.paraphraser && (

              <CoAuther_ParaphraserPage
                isDarkMode={isDarkMode}
                editorState={props.editorState}
                setEditorState={props.setEditorState}
                callGrammerCheck={props.callGrammerCheck}
                firstTime={props.firstTime}
                setFirstTime={props.setFirstTime}
                sharedState={props.sharedState}
              />

            )}
          </div>
        </div>
      )}
    </>

    :
    <>


{lowerModelOpen && (
    <div className='right-modal' style={{backgroundColor: isDarkMode?"black":"white", height: '80vh', width:'90%', position: "fixed", top: "135px", right: "5px", border: '1px solid  grey',zIndex: '1000', overflowY:'auto', overflowX:'hidden'}}>

<div style={{ display: 'flex', width: '100%', justifyContent: "space-between", alignItems: "center", border: "1px solid grey ", position: "absolute", top: "0px" }}>
            <div style={{ display: 'flex' }}>
              <div style={{ border: "1px solid grey", alignItems: "center" }}>
                {props.paraphraser ? (
                  <IconButton style={{ outline: 'none', color: props.paraphraser ? 'red' : '#3d83fa',padding:"6px 10px"}} onClick={() => handleParaphrase()}>
                   <img
                    src={paraphrase_logo1}
                    alt="Paraphrase"
                    style={{width:'21px'}}
                />
                    <div style={{ color: props.paraphraser ? '#3d83fa' : 'grey' }}></div>
                    {props.paraphraser &&
                      <div><h6 style={{ color: isDarkMode ? "#ECE6F0" :  '#5C02A9', marginTop: "7px", marginLeft: '2px', fontSize:'12px' }}>Paraphrase</h6></div>
                    }
                  </IconButton>
                ) : (
                  <CustomTooltip title='Paraphrase'>
                    <IconButton style={{ outline: 'none', color: props.paraphraser ? 'red' : '#3d83fa',marginTop:"6px",padding:"6px 10px" }} onClick={() => handleParaphrase()}>
                    <img
                    src={paraphrase_logo}
                    alt="Paraphrase"
                    style={{width:'16px'}}
                />
                      <div style={{ color: props.paraphraser ? '#3d83fa' : 'grey' }}></div>
                      {props.paraphraser &&
                        <div><h6 style={{ color: isDarkMode ? "#ECE6F0" :  'indigo', marginTop: "7px", marginLeft: '2px', fontSize:'12px' }}>Paraphrase</h6></div>
                      }
                    </IconButton>
                  </CustomTooltip>
                )}
              </div>

              <div style={{ border: "1px solid grey", borderTop: 'none', borderRight: 'none', borderBottom: 'none' }}>
                {props.summariser ? (
                  <IconButton style={{ outline: 'none', color: props.summariser ? 'red' : '#3d83fa' ,padding:"6px 10px"}} onClick={() => handleSummarise()}>
                    <FaBookOpen style={{ marginRight: '3px', width: '14px', height: '18px' }} />
                    <div style={{ color: props.summariser ? '#3d83fa' : 'grey' }}></div>
                    {props.summariser &&
                      <div>
                        <h6 style={{ color: isDarkMode ? "#ECE6F0" :  'red', marginTop: "7px", marginLeft: '2px', fontSize:'12px' }}>Summarize</h6>
                      </div>
                    }
                  </IconButton>
                ) : (
                  <CustomTooltip title='Summarize'>
                    <IconButton style={{ outline: 'none', color: props.summariser ? 'red' : '#3d83fa' ,marginTop:"6px",padding:"6px 10px"}} onClick={() => handleSummarise()}>
                      <FaBookOpen style={{ marginRight: '3px', width: '14px', height: '18px' }} />
                      <div style={{ color: props.summariser ? '#3d83fa' : 'grey' }}></div>
                      {props.summariser &&
                        <div>
                          <h6 style={{ color: isDarkMode ? "#ECE6F0" :  'red', marginTop: "7px", marginLeft: '2px', fontSize:'12px' }}>Summarize</h6>
                        </div>
                      }
                    </IconButton>
                  </CustomTooltip>
                )}
              </div>

              <div style={{ border: "1px solid grey", borderTop: 'none', borderBottom: 'none', borderRight: 'none' }}>
                {props.translator ? (
                  <IconButton style={{ outline: 'none', color: props.translator ? 'orange' : '#3d83fa',padding:"6px 10px" }} onClick={() => handleTranslate()}>
                    <TranslateIcon style={{width:'18px'}} />
                    <div style={{ color: props.translator ? '#3d83fa' : 'grey' }}></div>
                    {props.translator &&
                      <div>
                        <h6 style={{ color: 'orange', marginTop: "7px", marginLeft: '2px', fontSize:'12px' }}>Translator</h6>
                      </div>
                    }
                  </IconButton>
                ) : (
                  <CustomTooltip title="Translator">
                    <IconButton style={{ outline: 'none', color: props.translator ? 'orange' : '#3d83fa' ,marginTop:"6px",padding:"6px 10px"}} onClick={() => handleTranslate()}>
                      <TranslateIcon style={{width:'18px'}} />
                      <div style={{ color: props.translator ? '#3d83fa' : 'grey' }}></div>
                      {props.translator &&
                        <div>
                          <h6 style={{ color: 'orange', marginTop: "7px", marginLeft: '2px', fontSize:'12px' }}>Translator</h6>
                        </div>
                      }
                    </IconButton>
                  </CustomTooltip>
                )}
              </div>



            </div>
            <div style={{marginRight:"5px"}} onClick={props.handleCloseTranslate}>
              <CloseIcon style={{color:isDarkMode?"red":""}} />
            </div>


          </div>

          <div style={{ position: "relative", marginTop: "50px", justifyContent: "center", width: "90%", margin: "auto" }}>


            {props.translator && (

              <CoAuther_TranslatorPage
                isDarkMode={isDarkMode}
                mobileView = {props.mobileView}
                editorState={props.editorState}
                setEditorState={props.setEditorState}
                onGrandchildStateChange={props.onChildStateChange}

              />

            )}
            {props.summariser && (

              <CoAuther_SummariserPage
                isDarkMode={isDarkMode}
                mobileView = {props.mobileView}
                righteditorContent={props.righteditorContent}
                handleDeleteinSummarizer={deleteEditorFroSummarizer}
                CallSummarise={props.callSummarise}
                editorState={props.editorState}
                setEditorState={props.setEditorState} />
            )}

            {props.paraphraser && (

              <CoAuther_ParaphraserPage
              isDarkMode={isDarkMode}
              mobileView = {props.mobileView}
                editorState={props.editorState}
                setEditorState={props.setEditorState}
                callGrammerCheck={props.callGrammerCheck}
                firstTime={props.firstTime}
                setFirstTime={props.setFirstTime}
                sharedState={props.sharedState}
              />

            )}
          </div>

    </div>

)}

    </>
  )
}

export default CoAuthorLowerModel;
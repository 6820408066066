import React, { useEffect, useState } from "react";
import SideNavBar from "../SideNavBar";
import ChatContextProvider from "../../contexts/ChatContext";
import Contacts from "../ChatBox/Contacts/Contacts";
import Main from "../ChatBox/Main/Main";
import { Box , Button} from "@mui/material";
import { QueryClientProvider, QueryClient } from "react-query";
import VideoState from "../../contexts/VideoState";
import CallModal from "../ChatBox/options/CallModal";
import VideoChat from "../ChatBox/Calling/VideoChat";
import { withRouter } from 'react-router-dom';
import { useSelector } from "react-redux";

const queryClient = new QueryClient();

const ChatBoxPage = () => {
  const { showChats, showContacts } = useSelector((state) => state.manageChat);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  console.log('windowWidthjj:', windowWidth, showChats);

  // const { InboxId = '', GroupInboxId = '' } = (history.location.state || {});

  return (
    <SideNavBar>
         <ChatContextProvider>
        <QueryClientProvider client={queryClient}>
          <Box display='flex' flexDirection={windowWidth < 769 ? "column" : "row"} maxHeight='80vh' style={{ overflowY: "auto" }}>
          <CallModal />
          <VideoChat />
          {windowWidth < 769 && showChats ? ( null ) : ( <Contacts />)}
            <Main/>
          </Box>
        </QueryClientProvider>
      </ChatContextProvider>
    </SideNavBar>
  );
};

export default ChatBoxPage;

import React, { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
function SelectDropdownSearch({
  label,
  id,
  name,
  value,
  onChange,
  optionsList,
  margin,
  disabled,
  isDarkMode
}) {
  const itemsPerRow = 5;

  const containerStyle = {
    display: "grid",
    gridTemplateColumns: `repeat(${itemsPerRow}, 1fr)`,
    gap: "8px",
  };

  const menuStyle = {
    maxHeight: "400px",
  };
  const getNativeWord = (selectedLanguage) => {
    
    const nativeWords = [
      "(العربية)", // Arabic
      "(অসমীয়া)", // Assamese
      "(বাংলা)", // Bengali
      "(Cebuano)", // Cebuano
      "(中文)", // Chinese
      "(Čeština)", // Czech
      "(Dansk)", // Danish
      "(Nederlands)", // Dutch
      "(English)", // English
      "(Suomi)", // Finnish
      "(Français)", // French
      "(Deutsch)", // German
      "(Ελληνικά)", // Greek
      "(ગુજરાતી)", // Gujarati
      "(עברית)", // Hebrew
      "(हिन्दी)", // Hindi
      "(Magyar)", // Hungarian
      "(Indonesia)", // Indonesian
      "(Italiano)", // Italian
      "(日本語)", // Japanese
      "(ಕನ್ನಡ)", // Kannada
      "(한국어)", // Korean
      "(Malay)", // Malay
      "(മലയാളം)", // Malayalam
      "(मराठी)", // Marathi
      "(नेपाली)", // Nepali
      "(Norsk)", // Norwegian
      "(Odia)", // Odia
      "(فارسی)", // Persian
      "(Polski)", // Polish
      "(Português)", // Portuguese
      "(ਪੰਜਾਬੀ)", // Punjabi
      "(Română)", // Romanian
      "(Русский)", // Russian
      "(संस्कृत)", // Sanskrit
      "(Español)", // Spanish
      "(Svenska)", // Swedish
      "(Tagalog)", // Tagalog
      "(தமிழ்)", // Tamil
      "(తెలుగు)", // Telugu
      "(ไทย)", // Thai
      "(Türkçe)", // Turkish
      "(اردو)", // Urdu
      "(Українська)", // Ukrainian
      "(Tiếng Việt)", // Vietnamese
      ];

    const index = optionsList.indexOf(selectedLanguage);
    return nativeWords[index] || ""; 
  };

  return (
    <>
      <div className="col-md-12 p-0">
        <Autocomplete
          id="country-select-demo"
          options={optionsList}
          autoHighlight
          disableClearable
          onChange={(_, newValue) => onChange(_, newValue)}
          value={value}
          // getOptionLabel={(option) => option}
          style={{backgroundColor:isDarkMode?'black':'', color:isDarkMode?'white':'',}}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              label={"Translate to..."}
              // InputLabelProps={{ shrink: !!value }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {value && (
                      <span  style={{ color: isDarkMode ? "white" : ""}}>{getNativeWord(value)}</span>
                    )}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
  
            />
          )}
          sx={{
            color:isDarkMode?'white':'',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: isDarkMode ? 'white' : '', // Default outline color
              },
              '&:hover fieldset': {
                borderColor: isDarkMode ? 'white' : '', // Hover outline color
              },
              '&.Mui-focused fieldset': {
                borderColor: isDarkMode ? 'white' : '', // Focused outline color
              },
              '& .MuiSvgIcon-root': {
                color: isDarkMode ? 'white' : '', // Change the dropdown arrow color based on dark mode
              },

            },
          }}
        />
      </div>
    </>
  );}

export default SelectDropdownSearch;

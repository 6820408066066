const initialState = {
    showChats: false,
    showContacts: true,
};

const manageChatReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SHOW_CHAT_AREA':
            return {
                ...state,
                showChats: true,
                showContacts: false
            };
        case 'SHOW_CONTACTS':
            return {
                ...state,
                showChats: false,
                showContacts: true
            };
     
        default:
            return state;
    }
};

export default manageChatReducer;

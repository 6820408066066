import React, { useState, useEffect, useRef } from "react";
import { Editor, EditorState, ContentState, RichUtils } from "draft-js";
// import SelectDropdownMui from "../../../ReusableComponents/SelectDropdownMui";
import LanguageDropdown from './LanguageDropdown';
import { checkTranslator } from "../../../../services/translatorServices";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import "draft-js/dist/Draft.css";
import CloseIcon from "@mui/icons-material/Close";
import { toast, ToastContainer } from "react-toastify";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import TranslateIcon from "@mui/icons-material/Translate";
import { Button, IconButton, Typography, Modal } from "@material-ui/core";
import { VolumeUp, Pause, PlayArrow } from "@mui/icons-material";
import Slider from "@mui/material/Slider";
import Loader from '../../../../utils/loader';
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useSelector } from "react-redux";
import JoditEditorComp from "../../../ReusableComponents/JoditEditorComp";
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from "@mui/icons-material/Delete";
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'blue',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const CoAuther_TranslatorPage = (props) => {
  //--------- jodit changes ----------
  const translateeditor = useRef(null);
  const [editorContent, setEditorContent] = useState("")
  //--------- end ------------

  const [translatedText, setTranslatedText] = useState("");
  const [secondaryeditorStateTranslator, setSecondaryEditorStateTranslator] =
    useState(EditorState.createEmpty());
  const [showTranslateContent, setShowTranslateContent] = useState(true);
  const [nativeWord, setNativeWord] = useState(""); // new state for native word
  const userDetails = useSelector((user) => user)
  const userID = userDetails.user.currentUser._id
  // const [showTranslateModel, setShowTranslateModel] = useState(false);
  // const [showtranslate, setShowTranslate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalWords, setTotalWords] = useState(0)
  const maxWords = 1000;
  const [languageList, setLanguageList] = useState([
    {
      value: "",
      name: "Select Language",
      nativeWord: ''
    },
    { value: "English", name: "English" },
    { value: "Spanish", name: "Spanish", nativeWord: '(Español)' },
    { value: "German", name: "German", nativeWord: '(Deutsch)' },
    { value: "Chinese", name: "Chinese", nativeWord: '(中文)' },
    { value: "Japanese", name: "Japanese", nativeWord: '(日本語)' },
    { value: "Russian", name: "Russian", nativeWord: '(Русский)' },
    { value: "Arabic", name: "Arabic", nativeWord: '(العربية)' },
    { value: "French", name: "French", nativeWord: '(Français)' },
    { value: "Portuguese", name: "Portuguese", nativeWord: '(Português)' },
    { value: "Czech", name: "Czech", nativeWord: '(Čeština)' },
    { value: "Danish", name: "Danish", nativeWord: '(Dansk)' },
    { value: "Dutch", name: "Dutch", nativeWord: '(Nederlands)' },
    { value: "Finnish", name: "Finnish", nativeWord: '(Suomi)' },
    { value: "Greek", name: "Greek", nativeWord: '(Ελληνικά)' },
    { value: "Gujarati", name: "Gujarati", nativeWord: '(ગુજરાતી)' },
    { value: "Hebrew", name: "Hebrew", nativeWord: '(עברית)' },
    { value: "Kannada", name: "Kannada", nativeWord: '(ಕನ್ನಡ)' },
    { value: "Korean", name: "Korean", nativeWord: '(한국어)' },
    { value: "Malay", name: "Malay", nativeWord: '(Bahasa Melayu)' },
    { value: "Malayalam", name: "Malayalam", nativeWord: '(മലയാളം)' },
    { value: "Marathi", name: "Marathi", nativeWord: '(मराठी)' },
    { value: "Nepali", name: "Nepali", nativeWord: '(नेपाली)' },
    { value: "Norwegian", name: "Norwegian", nativeWord: '(Norsk)' },
    { value: "Odia", name: "Odia", nativeWord: '(ଓଡ଼ିଆ)' },
    { value: "Persian", name: "Persian", nativeWord: '(فارسی)' },
    { value: "Polish", name: "Polish", nativeWord: '(Polski)' },
    { value: "Sanskrit", name: "Sanskrit", nativeWord: '(संस्कृत)' },
    { value: "Swedish", name: "Swedish", nativeWord: '(Svenska)' },
    { value: "Tagalog", name: "Tagalog", nativeWord: '(Tagalog)' },
    { value: "Tamil", name: "Tamil", nativeWord: '(தமிழ்)' },
    { value: "Telugu", name: "Telugu", nativeWord: '(తెలుగు)' },
    { value: "Thai", name: "Thai", nativeWord: '(ไทย)' },
    { value: "Turkish", name: "Turkish", nativeWord: '(Türkçe)' },
    { value: "Urdu", name: "Urdu", nativeWord: '(اردو)' },
    { value: "Ukrainian", name: "Ukrainian", nativeWord: '(Українська)' },
    { value: "Vietnamese", name: "Vietnamese", nativeWord: '(Tiếng Việt)' },
    { value: "Hindi", name: "Hindi", nativeWord: '(हिन्दी)' },
    { value: "Punjabi", name: "Punjabi", nativeWord: '(ਪੰਜਾਬੀ)' },
    { value: "Italian", name: "Italian", nativeWord: '(Italiano)' },
    { value: "Bengali", name: "Bengali", nativeWord: '(বাংলা)' },
    { value: "Indonesian", name: "Indonesian", nativeWord: '(Indonesia)' },
    { value: "Romanian", name: "Romanian", nativeWord: '(Română)' },
    { value: "Cebuano", name: "Cebuano", nativeWord: '(Cebuano)' },
    { value: "Assamese", name: "Assamese", nativeWord: '(অসমীয়া)' },
    { value: "Hungarian", name: "Hungarian", nativeWord: '(Magyar)' }


  ]);
  languageList.sort((a, b) => a.name.localeCompare(b.name));

  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [speakLanguage, setSpeakLanguage] = useState("");
  const [isPlaying, setIsPlaying] = useState(false);
  const [speechSynthesis, setSpeechSynthesis] = useState(null);
  const [showPauseResumeToggle, setShowPauseResumeToggle] = useState(false);
  const [speed, setSpeed] = useState(1);
  const [currentSpeed, setCurrentSpeed] = useState(speed);
  const [isSliderMoving, setIsSliderMoving] = useState(false);

  useEffect(() => {
    return () => {
      window.speechSynthesis.cancel();
    };
  }, []);

  const handleListen = () => {
    if ("speechSynthesis" in window) {
      window.speechSynthesis.cancel();
      const textToSpeak = secondaryeditorStateTranslator
        .getCurrentContent()
        .getPlainText();
      const utterance = new SpeechSynthesisUtterance(textToSpeak);

      const voices = window.speechSynthesis.getVoices();
      console.log("Available Voices:", voices);

      if (voices.length > 3) {
        utterance.voice = voices[2];
      } else {
        console.warn("Female voice not found");
      }

      utterance.lang = speakLanguage;
      utterance.rate = currentSpeed;

      utterance.onend = () => {
        console.log("Speech synthesis completed");
        setIsPlaying(false);
        setShowPauseResumeToggle(false);
      };

      if (!isPlaying) {
        window.speechSynthesis.speak(utterance);
        setIsPlaying(true);
        setShowPauseResumeToggle(true);
      } else {
        window.speechSynthesis.pause();
        setIsPlaying(false);
        setShowPauseResumeToggle(false);
      }
    } else {
      console.error("Speech synthesis not supported in this browser.");
    }
  };

  const handleSpeedChange = (_, newValue) => {
    if (!isSliderMoving) {
      setSpeed(newValue);
      setCurrentSpeed(newValue);

      if (isPlaying) {
        const textToSpeak = secondaryeditorStateTranslator
          .getCurrentContent()
          .getPlainText();
        // const textToSpeak = editorContent
        const utterance = new SpeechSynthesisUtterance(textToSpeak);

        const voices = window.speechSynthesis.getVoices();

        if (voices.length > 3) {
          utterance.voice = voices[2];
        } else {
          console.warn("Female voice not found. Using default voice.");
        }

        utterance.lang = speakLanguage;
        utterance.rate = newValue;

        window.speechSynthesis.cancel();
        window.speechSynthesis.speak(utterance);
      }
    }
  };

  const handleTogglePlay = () => {
    if (isPlaying) {
      window.speechSynthesis.pause();
    } else {
      window.speechSynthesis.resume();
    }
    setIsPlaying(!isPlaying);
  };
  const clearListenFunctionality = () => {
    window.speechSynthesis.cancel();
    setIsPlaying(false);
    setShowPauseResumeToggle(false);
  };


  const handleLanguage = async (e) => {
    try {
      const value = e.target.value;
      console.log("Selected Language:", value, e.target);
      // let newValue = languageList.filter((obj) => {
      //   if (obj.value == value) {
      //     return obj;
      //   }
      // });
      // let finalValue = newValue[0]
      // let finalValue2 = `${finalValue.value} (${finalValue.native})`
      // console.log("newValue", newValue,finalValue2);

      let speakLanguage = languageList.filter((data) => data.value == value);
      console.log("speakLanguage", speakLanguage);
      setSpeakLanguage(speakLanguage[0].speakLang);
      if (value?.value !== selectedLanguage) {
        setSecondaryEditorStateTranslator(EditorState.createEmpty());
        // setEditorContent("")
        clearListenFunctionality();
      }
      setSelectedLanguage(value);
      // setSelectedLanguage(finalValue.value);

      setLoading(true);

      // let input = props.editorState.getCurrentContent().getPlainText();
      let input = props.editorContent;
      if (input === "") {
        toast.warn("Please enter text to translate");
        setLoading(false);
        return;
      }

      if (value === "") {
        toast.warn("Please Select the Language to translate");
        setLoading(false);
        return;
      }
      let data = {
        // text: `Please%20translate%20the%20following%20text%20to%20${value}%20text-${input}`,
        selectedLanguage: value,
        input : props.editorState,
        userId: userID,
        isCoAuthor: true,

      };
      console.log(value, "valueee")
      const response = await checkTranslator(data);
      const translatedText = response?.data?.output;
      const wordCount = translatedText[0].trim().split(/\s+/).length
      setTotalWords(wordCount)

      console.log("Translatedddddddddddd", translatedText[0], wordCount);
      if (response.status === 400) {
        toast.warn(response.message);
        return;
      }

      const contentState = ContentState.createFromText(translatedText[0]);
      const newEditorState = EditorState.createWithContent(contentState);

      setTranslatedText(translatedText);
      // setEditorContent(translatedText[0])
      setSecondaryEditorStateTranslator(newEditorState);
      setLoading(false);
    } catch (error) {
      console.error("Error calling backend API:", error.message);
      toast.warn(error.message);
      setLoading(false);
    }
  };

  const handleDelete = () => {
    setSecondaryEditorStateTranslator(EditorState.createEmpty());
    setTotalWords(0)
    clearListenFunctionality();
  };

  const handleReplace = () => {
    const contentState = secondaryeditorStateTranslator.getCurrentContent();
    const contentText = contentState.getPlainText();

    // const contentText = editorContent
    if (contentText == "") {
      toast.warn("Nothing to replace");
      return;
    }
    // Pass the data to the parent component
    console.log("props sending from translator page", contentText);
    props.onGrandchildStateChange(contentText);
  };
  const handleBeforeInput = (chars, editorState) => {
    console.log(editorState, "handelbeforInput function data");
    const contentState = editorState.getCurrentContent();
    const currentText = contentState.getPlainText();
    const wordCount = currentText.trim().split(/\s+/).length;

    if (wordCount >= maxWords) {
      return "handled";
    }
    return "not-handled";
  };

  const handleOutputChangeTranslator = (newEditorState) => {
    const contentState = newEditorState.getCurrentContent();
    setSecondaryEditorStateTranslator(newEditorState);

    if (contentState.hasText() === false) {
      clearListenFunctionality();
    }
  };

  const handleCopyEditorTranslator = () => {
    let sentence = secondaryeditorStateTranslator
      .getCurrentContent()
      .getPlainText();
    // let sentence = editorContent
    if (sentence == "") {
      toast.warn(`You don't have text, to copy`);
      return;
    }
    navigator.clipboard.writeText(sentence).then(
      function () {
        console.log("Async: Copying to clipboard was successful!");
        if (sentence) toast.info("Your text Copied!");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  };

  
  const handleDownloadEditorTranslator = () => {
    let sentence = secondaryeditorStateTranslator
      .getCurrentContent()
      .getPlainText();
    // let sentence = editorContent
    if (sentence == "") {
      toast.warn(`You don't have text, to copy`);
      return;
    }
    const blob = new Blob([sentence], { type: 'text/plain' });
    console.log(blob, "blob");
    const anchor = document.createElement('a');
    anchor.href = URL.createObjectURL(blob);
    anchor.download = 'text_content.doc';
    anchor.click();
    URL.revokeObjectURL(anchor.href);
  };

  // ----------- jodit editor changes --------------

  const handleChangeEditor = () => {
    if (translateeditor.current) {
      console.log("editor_", translateeditor.current?.value);
      const htmlContent = translateeditor.current.value; // Access the HTML content
      const tempElement = document.createElement('div');
      tempElement.innerHTML = htmlContent;
      // Extract text content
      const textContent = tempElement.textContent || tempElement.innerText;
      console.log("textContent", textContent);
      let wordCount
      if (textContent !== 0) {
        wordCount = textContent.trim().split(/\s+/).length;
        // setTotalWords(wordCount)
      } else {
        // setTotalWords(0)
      }
      // const pContent = editor.current?.value.match(/<p>(.*?)<\/p>/)[1]; // Extract text in brackets
      // console.log("pContent__", pContent);
      setEditorContent(textContent)
    }
  }

  return (
    <div>
      <Loader loading={loading} />
      <div style={{ marginTop: "100px" }}>
        <p className="btnTextColor">Translate to</p>
        <LanguageDropdown
          optionsList={languageList}
          selectLabel={"Select Language"}
          onChange={(e) => handleLanguage(e)}
          value={selectedLanguage}
          id="languageDropdown"
          isDarkMode={props.isDarkMode}
        />
      </div>
      {/* New Editor Code */}
      <div style={{ display: "flex", flexDirection: "space-between" }}>
        <div style={{ minWidth: "95%", maxWidth: "95%" }}>
          <div
            style={{
              boxShadow: "0 0px 0px rgba(0, 0, 0, 0.1)",
              padding: "20px 5px",
              borderRadius: "8px 8px 0px 0px",
              background: props.isDarkMode?"grey":"white",
              minHeight: "360px",
              maxHeight: "360px",
              overflowY: "auto",
              marginTop: "15px",
              color:props.isDarkMode?"white":"black"
            }}
          >
            <Editor
              editorState={secondaryeditorStateTranslator}
              onChange={handleOutputChangeTranslator}
              handleBeforeInput={handleBeforeInput}
              // placeholder="Please enter your text."
              readOnly={true}
            />
          </div>
          {/* <JoditEditorComp editorHeight={300} onChange={handleChangeEditor} editorContent={editorContent} editor={translateeditor} isMain={false}/> */}
        </div>
      </div>
      <div>
        <div
          style={{
            width: "100%",
            height: "10%",
            position: "absolute",
            top: "100%",
            display: "flex",
            justifyContent:'space-between',
            alignItems: "center",
            marginTop:'30px'
            
          }}
        >

          <div className='px-2'>
            <h6 style={{ color: '#1976D2', fontSize: props.mobileView ? '10px' : '12px', marginTop: '10px' }}>
              <FiberManualRecordIcon fontSize='small' />
              <b>  {totalWords} words </b>
            </h6>
          </div>
          <div>
            <IconButton
              // className={`${style.btn_trns} px-2`}
              onClick={handleListen}
            // title={"Listen"}
            style={{ width:props.mobileView ? '15px' : '', marginRight: props.mobileView ? '5px' : ''}}
            >
              <LightTooltip title="Listen">
                <VolumeUp size={"20px"} style={{ color: "brown" , fontSize:  props.mobileView ? '15px' : '' }} />
              </LightTooltip>
            </IconButton>
            {showPauseResumeToggle && (
              <>
              <LightTooltip  title={isPlaying ? "Pause" : "Resume"}>
                <IconButton
                  onClick={handleTogglePlay}
                  
                >
                  {isPlaying ? (
                    <Pause size={"17px"} style={{ color: "#7C7C7C", fontSize:  props.mobileView ? '15px' : '' }} />
                  ) : (
                    <PlayArrow size={"17px"} style={{ color: "#7C7C7C", fontSize:  props.mobileView ? '15px' : '' }} />
                  )}
                </IconButton>
                </LightTooltip>
                <LightTooltip title={"Playback Speed"}>
                <Slider
                  value={speed}
                  marks
                  min={0.25}
                  max={2}
                  step={0.25}
                  onChange={(_, newValue) => handleSpeedChange(_, newValue)}
                  onDragStart={() => setIsSliderMoving(true)}
                  onDragEnd={() => setIsSliderMoving(false)}
                  valueLabelDisplay="auto"
                  valueLabelFormat={(value) => `${value}x`}
                  getAriaValueText={(value) => `${value}x`}
                  style={{ width: "80px", margin:'0px 10px 0px 0px' }}
                />
                </LightTooltip>
              </>
            )}
            </div>
            <div>

            <IconButton
              // title={"Copy"}
              style={{ width: props.mobileView ? '15px' : "23px", height: "30px", marginRight: props.mobileView ? '5px' : "10px" }}
              onClick={handleCopyEditorTranslator}
            >
              <LightTooltip title="Copy">
                <ContentCopyIcon style={{ color: "blue", fontSize:  props.mobileView ? '15px' : '' }} />
              </LightTooltip>
            </IconButton>

            <IconButton
              style={{ width: props.mobileView ? '15px' : "23px", height: "30px", marginRight: props.mobileView ? '5px' : "10px" }}
              onClick={handleDownloadEditorTranslator}
            >
              <LightTooltip title="Download">
                <DownloadIcon style={{ color: "green", fontSize:  props.mobileView ? '15px' : '' }} />
              </LightTooltip>
            </IconButton>

            <LightTooltip title="Replace">
              <IconButton
                title={"Replace"}
                // style={{
                //   width: "90px",
                //   height: "30px",
                //   borderRadius: "15px",
                //   border: "1px solid",
                //   fontSize: "13px",
                //   color: "white",
                //   backgroundColor: "#3d83fa",
                //   marginRight: "5px",
                // }}
                style={{width: props.mobileView ? '15px' : "23px", height: "30px", marginRight: props.mobileView ? '5px' : "10px" }}
                onClick={handleReplace}
              >
                <SwapHorizIcon  style={{fontSize:  props.mobileView ? '15px' : '' , color:props.isDarkMode?"white":""}}/>
              </IconButton>
            </LightTooltip>
            <LightTooltip title="Delete">
              <IconButton
                // title={"Delete"}

                // style={{
                //   width: "90px",
                //   height: "30px",
                //   borderRadius: "15px",
                //   border: "1px solid",
                //   fontSize: "13px",
                //   color: "white",
                //   backgroundColor: "#3d83fa",
                //   marginRight: "5px",
                // }}
                style={{color:'red', width: props.mobileView ? '15px' : "23px", height: "30px", marginRight: props.mobileView ? '5px' : "10px" }}
                onClick={handleDelete}
              >
                <DeleteIcon style={{fontSize:  props.mobileView ? '15px' : ''}} />
              </IconButton>
            </LightTooltip>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoAuther_TranslatorPage;

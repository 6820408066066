import React, { useEffect, useState } from "react";
import "./BreadCrumbs.css";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Link } from "react-router-dom";
const Example = ({ l1, l2, l3, dashboardlink, documentLink }) => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  useEffect(() => {
      const updateTheme = () => {
        const theme = localStorage.getItem('ThemeSwitch');
        setIsDarkMode(theme === 'true');
      };
      updateTheme();
      window.addEventListener('storage', updateTheme);
      const originalSetItem = localStorage.setItem;
      localStorage.setItem = function (key, value) {
        originalSetItem.apply(this, arguments);
        if (key === 'ThemeSwitch') {
          updateTheme(); 
        }
      };
      return () => {
        window.removeEventListener('storage', updateTheme);
        localStorage.setItem = originalSetItem; 
      };
    }, []);
  return (
    <div className="ft_sm" style={{width:"98%",margin:"auto", }}>
      <Breadcrumb>
        <BreadcrumbItem active color="primary">
          <Link className="ft_sm" to={dashboardlink}>
            {l1}
          </Link>
        </BreadcrumbItem>
        {l3 ? <BreadcrumbItem active>
          <Link className="ft_sm m-0" to={documentLink}>
            {l2}
          </Link>
        </BreadcrumbItem> : <BreadcrumbItem>{l2}</BreadcrumbItem>}
        {l3 && <BreadcrumbItem style={{color: isDarkMode ? "white" : ""}}>{l3}</BreadcrumbItem>}
      </Breadcrumb>
    </div>
  );
};

export default Example;

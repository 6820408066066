export const showChatArea = () => {
    return {
        type: 'SHOW_CHAT_AREA'
    };
};

export const showContactsArea = () => {
    return {
        type: 'SHOW_CONTACTS'
    };
};
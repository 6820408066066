import React, { useEffect, useState } from "react";
import "./Main.css";
import Chat from "../Chat/Chat";
import { useContext } from "react";
import { ChatContext } from "../../../contexts/ChatContext";
import { useSelector, useDispatch } from 'react-redux';
import { showChatArea } from "../../../redux/manageChatArea/chatAreaStatus.action";


const Main = () => {
  const dispatch = useDispatch();

  const { showChats, showContacts } = useSelector((state) => state.manageChat);

  const { selectedInboxId } = useContext(ChatContext);

  useEffect(() => {
    
    if (selectedInboxId !== null) {
      // alert("inside ")
      dispatch(showChatArea());
    }
  }, [selectedInboxId, dispatch]);

  console.log(showContacts,'showChats:', showChats);

  // setSelectedContactId(InboxId ? InboxId : null);
  // setSelectedInboxId(InboxId ? InboxId : GroupInboxId ? GroupInboxId : null);
  // setSelectedGroupId(GroupInboxId ? GroupInboxId : null);

  return selectedInboxId === null ? (
    <div className="main">
      <h1>Welcome</h1>
      <p>Click on an inbox to start chatting!</p>
    </div>
  ) : (
    <Chat />
  );
};

export default Main;

import React, { Fragment, useRef, useEffect, useState } from "react";
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { IconButton, Tooltip } from "@material-ui/core";

const GenericThemeSwitch = ({ preserveRasters = true, storeKey = "ThemeSwitch" }) => {
  const cssString = `
        html { filter: invert(0%); }
      `;
  const rasterCss =
    'img, video, [style*="url("] { filter: invert(0%) }';
 
  const isDeclarationSupported = (property, value) => {
    const prop = property + ":",
      el = document.createElement("test"),
      mStyle = el.style;
    el.style.cssText = prop + value;
    return mStyle[property];
  };
 
  const supported = useRef(!!isDeclarationSupported("filter", "invert(100%)"));
 
  const [css, setCss] = useState(cssString);
  const [active, setActive] = useState(
    localStorage.getItem(storeKey) === "true" ||
      (!localStorage.getItem(storeKey) &&
        matchMedia("(prefers-color-scheme: dark)").matches)
  );

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); 

  useEffect(() => {
    if (preserveRasters) {
      setCss(`${cssString} ${rasterCss}`);
    }
    return () => {
      setCss(cssString);
    };
  }, [preserveRasters]);
 
  useEffect(() => {
    localStorage.setItem(storeKey, active);
  }, [active, storeKey]);
  useEffect(() => {
    document.documentElement.classList.toggle("dark-mode", active);
  }, [active]);

  const toggle = () => {
    setActive(a => !a);
  };
 
  return (
    supported.current && (
      <Fragment>
        <IconButton
          aria-pressed={active}
          onClick={toggle}
          style={{  backgroundColor:'transparent', padding:'5px 10px', borderRadius:'50px'}}
        >
          <Tooltip title="Switch theme">
          <DarkModeIcon style={{color:"#1976d2"}}/>
          </Tooltip>
        </IconButton>
        <style media={active ? "screen" : "none"}>
          {active ? css.trim() : css}
        </style>
      </Fragment>
    )
  );
};
 
export default GenericThemeSwitch;
 
 
import React, { useEffect, useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import dayjs from 'dayjs';
import { toast, ToastContainer } from "react-toastify";



export default function DatePickerMui({ fullWidth, disabled, onChange,
    label, value, defaultValue,onChangeYear, isDarkMode

}) {
    const theme = createTheme({
        components: {
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        backgroundColor: isDarkMode? "gray":"none",
                        color: isDarkMode?"white": '#646464',
                        fontFamily: 'Montserrat, sans-serif',
                        fontWeight: '500',
                        fontSize: '12px',
                        height: '40px',
                        borderRadius: '8px',
                        '&:focus': {
                            color: '#646464',
                            outline: 'none',
                            boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.2)',
                        },
                    },
                    notchedOutline: {
                        borderColor: 'black',
                    },
                },
            },
        },
    });
    // const currentYear = new Date().getFullYear()
    const currentYear = dayjs()
    console.log("currentYear2",currentYear)

    function generateDayjsObject(date) {
        const dayjsObject = dayjs(date);
        return {
            $D: dayjsObject.date(),
            $H: dayjsObject.hour(),
            $L: dayjsObject.locale(),
            $M: dayjsObject.month(),
            $W: dayjsObject.day(),
            $d: dayjsObject.format(),
            $isDayjsObject: true,
            $m: dayjsObject.minute(),
            $ms: dayjsObject.millisecond(),
            $s: dayjsObject.second(),
            $u: undefined,
            $x: {},
            $y: dayjsObject.year(),
        };
    }

    const date = new Date(2024, 0, 1);
    console.log("dayjsObject", generateDayjsObject(date));

    const theDate = {
        M: {
            $D: new Date().getDay(),
            $H: 0,
            $L: "en",
            $M: 1,
            $W: 5,
            $d: new Date(),
            $isDayjsObject: true,
            $m: 0,
            $ms: 0,
            $s: 0,
            $u: undefined,
            $x: {},
            $y: new Date().getFullYear()
        }
    }

    const adapter = new AdapterDayjs();
    const today = adapter.format(adapter.date(), 'MM/DD/YYYY');
    // const [startDate, setStartDate] = useState(new Date())
    const [startDate, setStartDate] = useState()
    console.log("adaptertoday", today, "startDate", startDate);
    console.log("startDatestringify", JSON.stringify(startDate));

    const handleDatePicker = (e) => {
        // let value = e.target.value
        let year = e.$y
        console.log("handleDatePicker", e, JSON.stringify(e), e.$y,e.$d)
        setStartDate(e)
        if(year == 1902 || JSON.stringify(e) == null){
            return
        }
        if(Math.abs(year).toString().length == 4){
            if(year <= currentYear){
            onChangeYear(year)
            }
        }
    }

    return (
        <>
        <ToastContainer />
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker label={"Year"} views={['year']}
                    onChange={handleDatePicker}
                    value={startDate}
                    disableFuture={true}
                    // defaultValue={dayjs('2022-07-17')}
                defaultValue={currentYear}
                />
            </LocalizationProvider>
        </ThemeProvider >
        </>
    );
}
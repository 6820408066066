import React, { useEffect, useState, useRef } from 'react';
import { Editor, EditorState, ContentState, RichUtils } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { Button, IconButton } from '@material-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Slider from '@mui/material/Slider';
import { getCheckSummariser } from '../../../../services/summariserService';
import { toast, ToastContainer } from "react-toastify";
import './CoAuther_SummariserPage_CSS.css'
import Loader from '../../../../utils/loader';
import { useSelector } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { stateToHTML } from 'draft-js-export-html';
import { convertToHTML } from 'draft-convert'


const CustomTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'blue',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);



const CoAuther_SummariserPage = (props) => {
  const userDetails = useSelector((user) => user)
  const userID = userDetails.user.currentUser._id
  const [loading, setLoading] = useState(false);
  // const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const maxWords = 1000;
  const [secondaryeditorState, setSecondaryEditorState] = useState(EditorState.createEmpty());
  const [secondaryeditorStateSummarizer, setSecondaryEditorStateSummarizer] = useState(EditorState.createEmpty());
  const [editorState2, setEditorState2] = useState(() => EditorState.createEmpty());
  const [totalWords, setTotalWords] = useState(0);
  const [sliderValue, setSliderValue] = useState(20);
  const [sliderText, setSliderText] = useState("Shorten")
  const [maxCountDisplayed, setMaxCountDisplayed] = useState(false)
  const [customPrompt, setCustomPrompt] = useState("")
  const [paragraphState, setParagraphState] = useState()
  const [editorStatePara, setEditorStatePara] = useState("");
  const [isKeyword, setIsKeyword] = useState(false)
  const [APIData, setAPIData] = useState(false);
  const [isBulletPoints, setIsBulletPoints] = useState(false)
  const [isParagraph, setIsParagraph] = useState(false)
  const [isCustom, setIsCustom] = useState(false)
  const [activeLink, setActiveLink] = useState(null);
  const [righteditorContent, setRightEditorContent] = useState(props.righteditorContent)
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);


  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
 
  // Update windowWidth on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
 
    window.addEventListener('resize', handleResize);
    // if(window.innerWidth <768){
    //   setSidebarOpen(!isSidebarOpen)
    // }
 
    return () => window.removeEventListener('resize', handleResize);
  }, []);
 
  const sliderStyle = {
    width: "100%",
    marginTop:"4px",
    color: props.isDarkMode ? '#fff' : '#1976D2', // White color for dark mode, black otherwise
  };

  useEffect(() => {
    console.log("props", props.CallSummarise)
    grammarCheckSummarizer()
  }, [props.CallSummarise])

  const grammarCheckSummarizer = async (type) => {
    console.log("editorState_", props.editorState, props.editorState);
    // let content = props.editorState.getCurrentContent();
    // let plainText = content.getPlainText();
    let plainText = props.editorState
    let data = {
      // text: `Please improve paraphrasing for the provided text ${type !== undefined ? `like a ${type} tone` : ""} ${editorState.getCurrentContent().getPlainText()}`,
      text: plainText,
      content: "",
      prompts: "one",
      userId: userID,
      isCoAuthor: true
    }
    setLoading(true)
    try {
      // if (!props.editorState.getCurrentContent().getPlainText().trim() ) {
      if (props.editorState == "") {
        toast.warn("Please enter text")
      } else {
        let summarizerResponse = await getCheckSummariser(data)
        console.log("summarizerResponse", summarizerResponse)
        if (summarizerResponse.data !== "") {
          let responseOutput = summarizerResponse?.data?.output
          let concatenatedOutput = responseOutput.join(" ");
          // let wordLength = responseOutput.split(" ")
          console.log("summarizerResponse_", responseOutput[0]);
          const contentState = ContentState.createFromText(concatenatedOutput);
          const newEditorState = EditorState.createWithContent(contentState);
          setRightEditorContent(newEditorState);
          setTotalWords(newEditorState.getCurrentContent().getPlainText().trim().split(/\s+/).length)
        }
      }
    }
    catch (e) {
      console.log("e", e)
    }
    setLoading(false)
  }


  useEffect(() => {
    setRightEditorContent(props.righteditorContent);
  }, [props.righteditorContent]);


  const deleteFunctionEditor = () => {

    setRightEditorContent(EditorState.createEmpty());
    props.handleDeleteinSummarizer(true)

  };
  // const handleParagraph = () => {
  //   const contentState = ContentState.createFromText(editorStatePara);
  //   console.log("Paragraph text:", contentState.getPlainText());
  //   // Update the state or perform any other necessary actions with the content
  //   setRightEditorContent(EditorState.createWithContent(contentState));
  // }; // working

  const handleParagraph = () => {
    let hasText = righteditorContent.getCurrentContent().hasText();
    if (!hasText) {
      toast.info('No content to convert to paragraph');
      return;
    }
    let text = righteditorContent.getCurrentContent().getPlainText();
    let cleanedText = text.replace(/\s+/g, ' ').trim(); // Replace multiple spaces with a single space
    let newContent = ContentState.createFromText('\n' + cleanedText);
    let newEditorState = EditorState.createWithContent(newContent);
    setRightEditorContent(newEditorState);
  };


  // shorten/long
  const summarizeByPercentage = async (value) => {
    console.log("Value_", value)
    //summarize by percentage
    // four, five
    let promptValue = "four"
    let newValue
    if (value == 0) {
      newValue = 80
      promptValue = "four"
    }
    else if (value <= 30) {
      newValue = 60
      promptValue = "four"
    }
    else if (value <= 50) {
      newValue = 80
      promptValue = "five"
    }
    else if (value >= 60) {
      newValue = 100
      promptValue = "five"
    }

    console.log("newValue", newValue)
    let sendData = {
      text: newValue.toString() + "%",
      // content: props.editorState.getCurrentContent().getPlainText(),
      content: props.editorState,
      prompts: promptValue,
      userId: userID,
      isCoAuthor: true
    }
    console.log("sendData", sendData)
    setLoading(true)
    try {
      let response = await getCheckSummariser(sendData)
      console.log("summarizeByPercentage", response)
      let responseOutput = response?.data?.output
      let concatenatedOutput = responseOutput.join(" ");
      const contentState = ContentState.createFromText(concatenatedOutput);
      const newEditorState = EditorState.createWithContent(contentState);
      setRightEditorContent(newEditorState);
      setEditorStatePara(newEditorState.getCurrentContent().getPlainText())
      console.log(newEditorState, "new editor state");
      setTotalWords(newEditorState.getCurrentContent().getPlainText().trim().split(/\s+/).length)
    }
    catch (e) {
      console.log("summarizeByPercentageerror", e)
    }
    setLoading(false)
  }


  const handleLinkClick = (linkType) => {
    console.log("Inside handlelink", linkType);
    setActiveLink(linkType);
    if (linkType === "bulletPoints") {
      console.log("bullet selected");
      setAPIData(true)
      setIsBulletPoints(true)
      setIsParagraph(false)
    }
    else if (linkType === "paragraph") {
      setAPIData(false)
      setIsBulletPoints(false)
      setIsParagraph(true)
      setIsCustom(false)
      setIsKeyword(true)
      handleParagraph()
    }
    else if (linkType == "custom") {
      setIsBulletPoints(false)
      setIsParagraph(false)
      setIsCustom(true)
      setIsKeyword(false)
    }
  };

  const handleOutputChangeSummarizer = (newEditorState) => {
    console.log("opChange_", newEditorState)
    // let value = e.target.value
    // setHistory([...history.slice(0, currentIndex + 1), value]);
    // setCurrentIndex(currentIndex + 1);
    setRightEditorContent(newEditorState)
  }
  const handleBeforeInput = (chars, editorState) => {
    console.log(editorState, "handelbeforInput function data")
    const contentState = editorState.getCurrentContent();
    const currentText = contentState.getPlainText();
    const wordCount = currentText.trim().split(/\s+/).length;

    if (wordCount >= maxWords) {
      return 'handled';
    }
    return 'not-handled';
  };
  // ----- delete ------
  // ----- delete ------
  const handleDeleteClick = () => {
    console.log("inside delete");
    setEditorState2(EditorState.createEmpty());
    deleteFunctionEditor()
    setTotalWords(0);
    setSliderValue(0);
    setCustomPrompt("");
    setEditorStatePara("");
    // resetTranscript();
  };

  // ---- download ----
  const handleDownload = () => {
    console.log("inside download");
    console.log(editorState2, "edit state");
    console.log(editorState2.getCurrentContent(), "get current content");
    console.log(righteditorContent.getCurrentContent().getPlainText(), "plain text");

    if (!righteditorContent.getCurrentContent().getPlainText()) {
      toast.warn(`You don't have text, to download`);
      return;
    }
    const blob = new Blob([righteditorContent.getCurrentContent().getPlainText()], { type: 'text/plain' });
    console.log(blob, "blob");
    const anchor = document.createElement('a');
    anchor.href = URL.createObjectURL(blob);
    anchor.download = 'text_content.doc';
    anchor.click();
    URL.revokeObjectURL(anchor.href);
  };
  //  ----- copy to clipboard ---

  const handleCopyEditor2 = () => {
    let contentState = righteditorContent.getCurrentContent();
    let sentence = contentState.getPlainText();

    if (sentence === "") {
      toast.warn(`You don't have text, to copy`);
      return;
    }

    // Convert the content state to HTML
    let html = convertToHTML(contentState);

    // Create a temporary element to hold the HTML content
    let tempElement = document.createElement("div");
    tempElement.innerHTML = html;
    document.body.appendChild(tempElement);

    // Select the content of the temporary element
    let range = document.createRange();
    range.selectNodeContents(tempElement);
    let selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);

    // Copy the selected content to the clipboard
    try {
        document.execCommand('copy');
        console.log("Async: Copying to clipboard was successful!");
        toast.info("Your text Copied!");
    } catch (err) {
        console.error("Async: Could not copy text: ", err);
    }

    // Clean up the temporary element
    document.body.removeChild(tempElement);
}

  // const handleCopyEditor2 = () => {
  //   const contentState = righteditorContent.getCurrentContent();
  //   const sentence = stateToHTML(contentState);
  
  //   if (sentence === "") {
  //     toast.warn(`You don't have text to copy`);
  //     return;
  //   }
  
  //   navigator.clipboard.writeText(sentence).then(
  //     function () {
  //       console.log("Async: Copying to clipboard was successful!");
  //       if (sentence) toast.info("Your text has been copied!");
  //     },
  //     function (err) {
  //       console.error("Async: Could not copy text: ", err);
  //     }
  //   );
  // };



  const handleApplyBullets = () => {
    // setAPIData(true)
    // setAPIData(false)
    let hasText = righteditorContent.getCurrentContent().hasText();
    if (!hasText) {
      toast.info("No content to add bullets")
      return
    }
    setIsBulletPoints(true)
    setIsParagraph(false)
    setIsCustom(false)
    setIsKeyword(true)

    // let text = editorState2.getCurrentContent().getPlainText();
    // let sentences = text.split('. ');
    // let newText = sentences.join('.\n');
    // let newContentState = ContentState.createFromText(newText);
    // let newEditorState = EditorState.createWithContent(newContentState);
    // setEditorState2(newEditorState)

    // Get the current text from the editor
    let text = righteditorContent.getCurrentContent().getPlainText();

    // Remove all empty lines
    let lines = text.split('\n').filter(line => line.trim() !== '');
    let textWithoutEmptyLines = lines.join('\n');

    // Split the text at every full stop
    const sentenceSplitPattern =/(?<!\b(?:[A-Z][a-z]*\.?\s?)+)\.\s+/;

    let sentences = textWithoutEmptyLines.split(sentenceSplitPattern).filter(sentence => sentence.trim() !== '').map(sentence => sentence.replace(/\n/g, ''));

    // Add a newline after every sentence and join them back together
    let formattedText = sentences.join('.\n');

    // Create a new content state with the formatted text
    let newContent = ContentState.createFromText(formattedText);

    // Set the new content state in the editor
    // let newEditorState = EditorState.push(editorState2, newContent);
    let newEditorState = EditorState.createWithContent(newContent);
    let newEditorStateExt = newEditorState.getCurrentContent()
    console.log("newEditorStateExt", newEditorStateExt.getPlainText())
    let firstBlock = newEditorStateExt.getFirstBlock()
    let lastBlock = newEditorStateExt.getLastBlock()
    let firstBlockKey = firstBlock.getKey();
    let lastBlockKey = lastBlock.getKey();
    let firstBlockLength = firstBlock.getLength();
    let lastBlockLength = lastBlock.getLength();
    let selection = newEditorState.getSelection().merge({
      anchorKey: firstBlockKey,
      anchorOffset: 0,
      focusKey: lastBlockKey,
      focusOffset: lastBlockLength,
    })
    let selectedState = EditorState.forceSelection(newEditorState, selection)
    setParagraphState(selectedState)

    let selectionState = selectedState.getSelection();
    let contentState = selectedState.getCurrentContent();
    let currentBlock = contentState.getBlockForKey(selectionState.getStartKey());
    // Check if the selection is not collapsed and spans the entire block
    // if (!selectionState.isCollapsed() && selectionState.hasFocus &&
    //     selectionState.getStartOffset() === 0 && selectionState.getEndOffset() === currentBlock.getLength()) {

    // Toggle the block type to unordered-list-item
    let newEditorState2 = RichUtils.toggleBlockType(selectedState, 'unordered-list-item');
    setRightEditorContent(newEditorState2);
    // }

  }

  const handleSliderChange = (event, newValue) => {
    // Calculate the percentage
    const percentage = ((newValue - 0) / (100 - 0)) * 100;
    // Update the state
    setSliderValue(newValue);
    setSliderText(newValue >= 60 ? "Expand" : "Shorten")
    summarizeByPercentage(newValue)
    // Use the percentage as needed
    console.log('Percentage:', percentage);
  };




  return (
    <>
      <Loader loading={loading} />
      <div className='Maindiv'>

        <div style={{color:props.isDarkMode?"whitesmoke":"#1976D2"}} className='headtag'>
          Summary Length
        </div>
        <div className='headtag_flex'>
          <div  style={{color:props.isDarkMode?"whitesmoke":""}} className='headtag_inner_div'>Short</div>
          <div className='slider_div'> 
          <Slider
            defaultValue={20}
            step={20}
            marks
            min={0}
            max={60}
            style={sliderStyle}
            valueLabelDisplay="off"
            onChange={handleSliderChange}
          />
          </div>
          <div style={{color:props.isDarkMode?"whitesmoke":""}} className='headtag_inner_div'>Long</div>
        </div>
      </div>

      <div className='top_button_style'>
        <Button
          // variant="outlined"
          // color="primary"
          style={{ fontSize: "10px", marginRight: "15px" , color:props.isDarkMode?"whitesmoke":"" }}
          onClick={handleApplyBullets}
          className='OutlinedDarkBtn2' 
        >
          Bullet Points
        </Button>

        <Button
          // variant="contained"
          // color="primary"
          style={{ fontSize: "10px" }}
          onClick={() => handleLinkClick("paragraph")}
          className='OutlinedDarkBtn2' 
        >
          Paragraph
        </Button>
      </div>

      <div className='editorDiv' >
        <Editor
          editorState={righteditorContent}
          onChange={handleOutputChangeSummarizer}
          handleBeforeInput={handleBeforeInput}
          placeholder="Please enter your text."
          readOnly={true}
        />
      </div>

      <div className='bottomButtons' style={{ display: 'flex', justifyContent: 'space-between', paddingLeft:props.mobileView ?"2px":"",paddingRight:props.mobileView ?"2px":"" }}>
        <div style={{width:windowWidth < 768 ?"50%" : "",display:windowWidth < 768 ? "flex" : ""}}>
          <CustomTooltip title="Delete">
            <IconButton
              style={{
                width: props.mobileView ? '25px' : '35px',
                height: props.mobileView ? '25px' : '35px',borderRadius: '5px', marginRight: '5px',
                border: isHovered ? '1px solid grey' : '1px solid black',
                color: isHovered ? 'grey' : 'red',
                backgroundColor:props.isDarkMode?"white":""

              }}
              onClick={handleDeleteClick}
              onMouseOver={() => setIsHovered(true)} onMouseOut={() => setIsHovered(false)}
            >
              <DeleteIcon />
            </IconButton>
          </CustomTooltip>
          <CustomTooltip title="Download">
            <IconButton

              style={{
                width: props.mobileView ? '25px' : '35px',
                height: props.mobileView ? '25px' : '35px', borderRadius: '5px', marginRight: '5px',
                border: isHovered2 ? '1px solid grey' : '1px solid black',
                color: isHovered2 ? 'grey' : 'green',
                   backgroundColor:props.isDarkMode?"white":""
              }}
              onClick={handleDownload}
              onMouseOver={() => setIsHovered2(true)} onMouseOut={() => setIsHovered2(false)}
            >
              <DownloadIcon />
            </IconButton>
          </CustomTooltip>
          <CustomTooltip title="Copy">
            <IconButton

              style={{
                width: props.mobileView ? '25px' : '35px',
                height: props.mobileView ? '25px' : '35px', borderRadius: '5px', marginRight: '5px',
                border: isHovered3 ? '1px solid grey' : '1px solid black',
                color: isHovered3 ? 'grey' : 'blue',
                   backgroundColor:props.isDarkMode?"white":""
              }}
              onClick={handleCopyEditor2}
              onMouseOver={() => setIsHovered3(true)} onMouseOut={() => setIsHovered3(false)}
            >
              <ContentCopyIcon />
            </IconButton>
          </CustomTooltip>
        </div>

        <div style={{ width:"fit-content",display:"flex",alignItems:"center",color:props.isDarkMode?"white": '#1976D2', fontSize: props.mobileView ? '10px' : '13px', marginTop: props.mobileView ? '5px': '10px', }}  >
          <h6 >
            <FiberManualRecordIcon fontSize='small' />
            <b>  {totalWords} words </b>
          </h6>
        </div>
      </div>
    </>
  );
};
export default CoAuther_SummariserPage;
import React, { useState, useEffect } from 'react';
import { Form } from "react-bootstrap";
import { Modal } from "antd";
import { Typography, Accordion, AccordionSummary, AccordionDetails, } from '@material-ui/core';
import {Button } from 'react-bootstrap';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import citationService from '../../services/citationService';
import { ContributorForm } from './CitationCard';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import _ from 'lodash';
import './Citation-1.css'



const UpdateCitationModal = ({ citationId, citations, editFormData, fetchCitationDetails,  
                              editShow, setEditShow , handleEditModalClose, contributors, editModalTitle, publicationYear, setPublicationYear,publicationMonth, setPublicationMonth,publicationDay, setPublicationDay,accessYear, setAccessYear,accessMonth, setAccessMonth,accessDay, setAccessDay,publicationYearError,setPublicationYearError, accessYearError, setAccessYearError,publicationDayError, 
                              setPublicationDayError,accessDayError, setAccessDayError}) => {

    const months = [
      "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December",
    ];
   
    const [editTitle, setEditTitle] = useState()
    const [editDes, setEditDes] = useState()
    const [editWeb, setEditWeb] = useState()
    const [editUrl, setEditUrl] = useState()
    const [editDoi, setEditDoi] = useState()
    const [editJournalName, setEditJournalName] = useState()
    const [editEdition, setEditEdition] = useState()
    const [editPublisher, setEditPublisher] = useState()
    const [editArticleNumber, setEditArticleNumber] = useState()
    const [editVolumeNumber, setEditVolumeNumber] = useState()
    const [editIssueNumber, setEditIssueNumber] = useState()
    const [editPage, setEditPage] = useState()
    const [editLibDatabase, setEditLibDatabase] = useState()
    const [editISBN, setEditISBN] = useState()
    const [editISSN, setEditISSN] = useState()
    const [editContributors, setEditContributors] = useState([...contributors]);
    const [existingData, setExistingData] = useState({
      heading: "",
      title: "",
      description: "",
      websiteName: "",
      contributors: [],
      url: "",
      doi: "",
      journalName: "",
      edition: "",
      publisher: "",
      articleNumber: "",
      volumeNumber: "",
      issueNumber: "",
      page: "",
      libraryDatabase: "",
      ISBN: "",
      ISSN: "",
      publicationDate: {
        year: "",
        month: "",
        day: "",
      },
      accessDate: {
        year: "",
        month: "",
        day: "",
      },
    });

    // const [publicationYearError, setPublicationYearError] = useState('');
    // const [accessYearError, setAccessYearError] = useState('');
    // const [publicationDayError, setPublicationDayError] = useState('');
    // const [accessDayError, setAccessDayError] = useState('');

    // const [publicationYear, setPublicationYear] = useState('');
    // const [publicationMonth, setPublicationMonth] = useState('');
    // const [publicationDay, setPublicationDay] = useState('');

    // const [accessYear, setAccessYear] = useState('');
    // const [accessMonth, setAccessMonth] = useState('');
    // const [accessDay, setAccessDay] = useState('');


    const handleEditTitle = (e) => {
      setEditTitle(e.target.value)
    }
    const handleEditDes = (e) => {
      setEditDes(e.target.value)
    }
    const handleEditWeb = (e) => {
      setEditWeb(e.target.value)
    }
    const handleEditUrl = (e) => {
      setEditUrl(e.target.value)
    }
    const handleEditDoi = (e) => {
      setEditDoi(e.target.value)
    }
    const handleEditJournalName = (e) => {
      setEditJournalName(e.target.value)
    }
    const handleEditEdition = (e) => {
      setEditEdition(e.target.value)
    }
    const handleEditPublisher = (e) => {
      setEditPublisher(e.target.value)
    }
    const handleEditArticleNumber = (e) => {
      setEditArticleNumber(e.target.value)
    }
    const handleEditVolumeNumber = (e) => {
      setEditVolumeNumber(e.target.value)
    }
    const handleEditIssueNumber = (e) => {
      setEditIssueNumber(e.target.value)
    }
    const handleEditPage = (e) => {
      setEditPage(e.target.value)
    }
    const handleEditLibDatabase = (e) => {
      setEditLibDatabase(e.target.value)
    }
    const handleEditISBN = (e) => {
      setEditISBN(e.target.value)
    }
    const handleEditISSN = (e) => {
      setEditISSN(e.target.value)
    }
   


    const currentYear = new Date().getFullYear();

    const calculateMaxDay = (month, year) => {
      switch (month) {
        case "January":
        case "March":
        case "May":
        case "July":
        case "August":
        case "October":
        case "December":
          return 31;
        case "April":
        case "June":
        case "September":
        case "November":
          return 30;
        case "February":
          // Check for leap year
          return (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0)) ? 29 : 28;
        default:
          return 31; // Default to 31 if month is not recognized (for safety)
      }
    };

    const [editPublicationDate, setEditPublicationDate] = useState({
      year: editFormData?.publicationDate?.year || '',
      month: editFormData?.publicationDate?.month || '',
      day: editFormData?.publicationDate?.day || '',
    });

    const [editAccessDate, setEditAccessDate] = useState({
      year: editFormData?.accessDate?.year || '',
      month: editFormData?.accessDate?.month || '',
      day: editFormData?.accessDate?.day || '',
    });

    const handleEditPublicationDateValidation = (field, value) => {
      setEditPublicationDate((prevDate) => ({
        ...prevDate,
        [field]: value,
      }));
    };
    
    const handleEditAccessDateValidation = (field, value) => {
      setEditAccessDate((prevDate) => ({
        ...prevDate,
        [field]: value,
      }));
    };

  
    const handleEditPublicationYearChange = (field, inputYear) => {
      // Check if the input is not empty and is a valid year or month
      if (
        inputYear === '' ||
        (parseInt(inputYear, 10) > 0 && /^\d{0,4}$/.test(inputYear) && parseInt(inputYear, 10) <= currentYear)
      ) {
        setPublicationYearError('');
        setPublicationYear(inputYear);
    
        // Additional check for day validity based on the new year and month
        const inputMonth = publicationMonth;
        const inputDay = publicationDay;
        const maxDay = calculateMaxDay(inputMonth, inputYear);
    
        if (inputDay !== "" && (inputDay < 1 || inputDay > maxDay)) {
          setPublicationDayError('Invalid day');
        } else {
          setPublicationDayError('');
          handleEditPublicationDateValidation(field, inputYear);
        }
      } else {
        setPublicationYearError('Invalid year');
      }
    };


    const handleEditPublicationMonthChange = (field, inputMonth) => {
      
      setPublicationMonth(inputMonth);
    
      // Validate the day based on the new month and year
      const inputYear = publicationYear;
      const inputDay = publicationDay;
      const maxDay = calculateMaxDay(inputMonth, inputYear);
    
      if (inputDay !== "" && (inputDay < 1 || inputDay > maxDay)) {
        setPublicationDayError('Invalid day');
      } else {
        setPublicationDayError('');
        handleEditPublicationDateValidation(field, inputMonth);
      }
    };


    const handleEditPublicationDayChange = (e) => {
      const inputDay = parseInt(e.target.value, 10);
      const inputMonth = publicationMonth;
      const inputYear = publicationYear;
      
      let maxDay = 31; // Default maximum days
      
      // Update maxDay based on the month
      if (inputMonth === "April" || inputMonth === "June" || inputMonth === "September" || inputMonth === "November") {
        maxDay = 30;
      } else if (inputMonth === "February") {
        // Check for leap year
        maxDay = (inputYear % 4 === 0 && (inputYear % 100 !== 0 || inputYear % 400 === 0)) ? 29 : 28;
      }
      
      // Check if the input is not empty and within the valid range
      if (inputDay === "" || inputDay < 1 || inputDay > maxDay) {
        setPublicationDayError('Invalid day');
      } else {
        setPublicationDayError('');
        setPublicationDay(inputDay);
        handleEditPublicationDateValidation('day', inputDay);
      }
    };
    
  
    const handleEditAccessYearChange = (field, inputYear) => {
      // Check if the input is not empty and is a valid year or month
      if (
        inputYear === '' ||
        (parseInt(inputYear, 10) > 0 && /^\d{0,4}$/.test(inputYear) && parseInt(inputYear, 10) <= currentYear)
      ) {
        setAccessYearError('');
        setAccessYear(inputYear);
    
        // Additional check for day validity based on the new year and month
        const inputMonth = accessMonth;
        const inputDay = accessDay;
        const maxDay = calculateMaxDay(inputMonth, inputYear);
    
        if (inputDay !== "" && (inputDay < 1 || inputDay > maxDay)) {
          setAccessDayError('Invalid day');
        } else {
          setAccessDayError('');
          handleEditAccessDateValidation(field, inputYear);
        }
      } else {
        setAccessYearError('Invalid year');
      }
    };


    const handleEditAccessMonthChange = (field, inputMonth) => {
      
      setAccessMonth(inputMonth);
    
      // Validate the day based on the new month and year
      const inputYear = accessYear;
      const inputDay = accessDay;
      const maxDay = calculateMaxDay(inputMonth, inputYear);
    
      if (inputDay !== "" && (inputDay < 1 || inputDay > maxDay)) {
        setAccessDayError('Invalid day');
      } else {
        setAccessDayError('');
        handleEditAccessDateValidation(field, inputMonth);
      }
    };


    
    const handleEditAccessDayChange = (e) => {
      const inputDay = parseInt(e.target.value, 10);
      const inputMonth = accessMonth;
      const inputYear = accessYear;
      
      let maxDay = 31; // Default maximum days
      
      // Update maxDay based on the month
      if (inputMonth === "April" || inputMonth === "June" || inputMonth === "September" || inputMonth === "November") {
        maxDay = 30;
      } else if (inputMonth === "February") {
        // Check for leap year
        maxDay = (inputYear % 4 === 0 && (inputYear % 100 !== 0 || inputYear % 400 === 0)) ? 29 : 28;
      }
      
      // Check if the input is not empty and within the valid range
      if (inputDay === "" || inputDay < 1 || inputDay > maxDay) {
        setAccessDayError('Invalid day');
      } else {
        setAccessDayError('');
        setAccessDay(inputDay);
        handleEditAccessDateValidation('day', inputDay);
      }
    };

  
    
    
  
    const handleEditContributorChange = (id, field, value) => {
      setEditContributors((prevContributors) =>
        prevContributors.map((contributor) =>
          contributor.id === id ? { ...contributor, [field]: value } : contributor
        )
      );
    };
  
    const handleEditAddMember = () => {
      setEditContributors((prevContributors) => [
        ...prevContributors,
        { id: uuidv4(), position: '', firstName: '', middleName: '', lastName: '', suffix: '' },
      ]);
    };
  
    const handleEditDeleteMember = (id) => {
      setEditContributors((prevContributors) => prevContributors.filter((contributor) => contributor.id !== id));
    };
  
    useEffect(() => {
      if (citations.length > 0 && undefined !== citationId) {
        console.log("citations", citations, citationId)
        let data = citations.filter((obj) => obj._id == citationId)
        console.log("data", data)
        let { _id, title, description, websiteName, url, publicationDate, accessDate, contributors,doi, journalName, edition, publisher, articleNumber, issueNumber, volumeNumber, page, libraryDatabase, ISBN, ISSN } = data[0]
        setEditTitle(title)
        setEditDes(description)
        setEditWeb(websiteName)
        setEditUrl(url)
        setEditDoi(doi)
        setEditJournalName(journalName)
        setEditEdition(edition)
        setEditPublisher(publisher)
        setEditArticleNumber(articleNumber)
        setEditIssueNumber(issueNumber)
        setEditVolumeNumber(volumeNumber)
        setEditPage(page)
        setEditLibDatabase(libraryDatabase)
        setEditISBN(ISBN)
        setEditISSN(ISSN)
        setEditPublicationDate({
          year: publicationDate?.year || '',
          month: publicationDate?.month || '',
          day: publicationDate?.day || '',
        });
        setEditAccessDate({
          year: accessDate?.year || '',
          month: accessDate?.month || '',
          day: accessDate?.day || '',
        });
        setEditContributors([...contributors]);
        setExistingData({
          title: title,
          description: description,
          websiteName: websiteName,
          url: url,
          doi: doi,
          journalName: journalName,
          edition: edition,
          publisher: publisher,
          articleNumber: articleNumber,
          volumeNumber: volumeNumber,
          issueNumber: issueNumber,
          page: page,
          libraryDatabase: libraryDatabase,
          ISBN: ISBN,
          ISSN: ISSN,
          publicationDate: publicationDate,
          accessDate: accessDate,
          contributors: contributors
      })

      }
    }, [editShow])
  
  
  
    const handleUpdateCitation = async () => {
      try {
        const updatedDetails = {
          title: editTitle,
          description: editDes,
          websiteName: editWeb,
          url: editUrl,
          doi: editDoi,
          journalName: editJournalName,
          edition: editEdition,
          publisher: editPublisher,
          articleNumber: editArticleNumber,
          volumeNumber: editVolumeNumber,
          issueNumber: editIssueNumber,
          page: editPage,
          libraryDatabase: editLibDatabase,
          ISBN: editISBN,
          ISSN: editISSN,
          publicationDate: editPublicationDate,
          accessDate: editAccessDate,
          contributors: editContributors,
        };

        if(_.isEqual(updatedDetails, existingData)){
          console.log("lplplpl", _.isEqual(updatedDetails, existingData))
          
          toast.error('No changes detected')
          return
        }

        if (publicationDayError === "Invalid day" || accessDayError === "Invalid day"){
          toast.error("Invalid date. Please provide correct date")
          return
        }

        if (updatedDetails.title===""){
          toast.error("Please provide citation title.")
          return
        }
        
  
        const response = await citationService.updateCitationDetails(citationId, updatedDetails);
  
        if (response.status === 200) {
          console.log("Citation updated successfully");
          toast.success("Updated Successfully")
          setEditShow(false)
          fetchCitationDetails();
          
          setPublicationYearError('')
      setAccessYearError('')
      setPublicationDayError('')
      setAccessDayError('')
      setPublicationDay('')
      setPublicationMonth('')
      setPublicationYear('')
      setAccessDay('')
      setAccessMonth('')
      setAccessYear('')
        } else {
          console.error("Error updating citation:", response);
         
        }
      } catch (error) {
        console.error("Unexpected error updating citation:", error);
        
      }

      

    };
  
    return (
      <>
        <Modal
        title = {editModalTitle}
        centered
        width={"65%"}
        style={{ margin: '10px', position: 'relative', zIndex: 5000}}
        open={editShow}
        
        onCancel={handleEditModalClose}
        footer={
        <div className="pt-2" style={{right: '30px', display:'flex', justifyContent:'end', width:'92%', borderTop:'1px solid grey'}}>
          <Button variant="primary" onClick={handleUpdateCitation}>
            Update Citation
          </Button>
        </div>
    }
        

        >
          <div style={{overflowY:"auto",maxHeight:'55vh', overflowX:"hidden"}}>
            <Form >
              {/* Text Fields */}

              {(editModalTitle === 'Book Chapter' || editModalTitle === "Webpage" || editModalTitle === 'Image' || editModalTitle === 'Video' || editModalTitle === "Journal Article" || editModalTitle === "Book" || editModalTitle === 'Online Dictionary Entry' || editModalTitle === 'Online News Article' || editModalTitle === 'Report' || editModalTitle === 'Website') && (
              <Form.Group controlId="formTitle" className="row justify-content-evenly">
                <Form.Label className="col-md-3 pt-2 pl-5 zzz">Title</Form.Label>
                <Form.Control
                  className='col-md-8'
                  type="text"
                  value={editTitle}
                  onChange={handleEditTitle}
                />
              </Form.Group>
              )}


              {(editModalTitle === "Journal Article" ) && (
              <Form.Group controlId="formJournalName" className="row justify-content-evenly">
                <Form.Label className="col-md-3 pt-2 pl-5 zzz">Journal Name</Form.Label>
                <Form.Control
                  className='col-md-8'
                  type="text"
                  value={editJournalName}
                  onChange={handleEditJournalName}
                />
              </Form.Group>
              )}


              {(editModalTitle === "Webpage" || editModalTitle === 'Image' ) && (
              <Form.Group controlId="formDescription" className="row justify-content-evenly">
                <Form.Label className="col-md-3 pt-2 pl-5 zzz">Description</Form.Label>
                <Form.Control
                  className='col-md-8'
                  type="text"
                  value={editDes}
                  onChange={handleEditDes}
                />
              </Form.Group>
              )}


              {(editModalTitle === "Webpage" || editModalTitle === 'Image' || editModalTitle === 'Video' || editModalTitle === 'Online Dictionary Entry' || editModalTitle === 'Online News Article' || editModalTitle === 'Report') && (
              <Form.Group controlId="formWebsiteName" className="row justify-content-evenly">
                <Form.Label className="col-md-3 pt-2 pl-5 zzz">Website Name</Form.Label>
                <Form.Control
                  className='col-md-8'
                  type="text"
                  value={editWeb}
                  onChange={handleEditWeb}
                />
              </Form.Group>
              )}


  
              {/* Contributors */}
              
          {(editModalTitle === "Webpage" || editModalTitle === 'Image' || editModalTitle === 'Video' || editModalTitle === "Journal Article" || editModalTitle === "Book" || editModalTitle === 'Book Chapter' || editModalTitle === 'Online Dictionary Entry' || editModalTitle === 'Online News Article' || editModalTitle === 'Report') && (        
          <Form.Group controlId="formContributors" className="pl-4">
            <Accordion style={{ width: '93.3%' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className='zzz'>Contributors</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ flexDirection: 'column' }}>
                {editContributors.map((contributor, index) => (
                  <div key={index} style={{ width: '100%', marginBottom: '10px' }}>
                    <ContributorForm
                      contributor={contributor}
                      handleDelete={() => handleEditDeleteMember(contributor.id)}
                      handleInputChange={(field, value) =>
                        handleEditContributorChange(contributor.id, field, value)
                      }
                    />
                  </div>
                ))}
                <div style={{ width: '100%', marginBottom: '10px' }}>
                  <Button variant="primary" onClick={handleEditAddMember}>Add Member +</Button>
                </div>
              </AccordionDetails>
            </Accordion>
          </Form.Group>
          )}
  

            {(editModalTitle === 'Book Chapter' || editModalTitle === "Book") && (
              <Form.Group controlId="formEdition" className="row justify-content-evenly">
                <Form.Label className="col-md-3 pt-2 pl-5 zzz">Edition</Form.Label>
                <Form.Control
                  className='col-md-8'
                  type="text"
                  value={editEdition}
                  onChange={handleEditEdition}
                />
              </Form.Group>
              )}


              {(editModalTitle === 'Book Chapter' || editModalTitle === "Journal Article" || editModalTitle === "Book" ) && (
              <Form.Group controlId="formVolumeNumber" className="row justify-content-evenly">
                <Form.Label className="col-md-3 pt-2 pl-5 zzz">Volume Number</Form.Label>
                <Form.Control
                  className='col-md-8'
                  type="text"
                  value={editVolumeNumber}
                  onChange={handleEditVolumeNumber}
                />
              </Form.Group>
              )}    


               {(editModalTitle === "Journal Article" ) && (
              <Form.Group controlId="formIssueNumber" className="row justify-content-evenly">
                <Form.Label className="col-md-3 pt-2 pl-5 zzz">Issue Number</Form.Label>
                <Form.Control
                  className='col-md-8'
                  type="text"
                  value={editIssueNumber}
                  onChange={handleEditIssueNumber}
                />
              </Form.Group>
              )}  


              {(editModalTitle === "Journal Article" ) && (
              <Form.Group controlId="formLibDatabase" className="row justify-content-evenly">
                <Form.Label className="col-md-3 pt-2 pl-5 zzz">Library Database</Form.Label>
                <Form.Control
                  className='col-md-8'
                  type="text"
                  value={editLibDatabase}
                  onChange={handleEditLibDatabase}
                />
              </Form.Group>
              )}


              {(editModalTitle === 'Book Chapter' || editModalTitle === "Journal Article" ) && (
              <Form.Group controlId="formPage" className="row justify-content-evenly">
                <Form.Label className="col-md-3 pt-2 pl-5 zzz">Page</Form.Label>
                <Form.Control
                  className='col-md-8'
                  type="text"
                  value={editPage}
                  onChange={handleEditPage}
                />
              </Form.Group>
              )}

            {(editModalTitle === 'Book Chapter' || editModalTitle === "Journal Article" || editModalTitle === "Book" ) && (
              <Form.Group controlId="formDOI" className="row justify-content-evenly">
                <Form.Label className="col-md-3 pt-2 pl-5 zzz">DOI</Form.Label>
                <Form.Control
                  className='col-md-8'
                  type="text"
                  value={editDoi}
                  onChange={handleEditDoi}
                />
              </Form.Group>
              )}

            {(editModalTitle === "Webpage" || editModalTitle === 'Image' || editModalTitle === 'Video' || editModalTitle === "Journal Article" || editModalTitle === "Book" || editModalTitle === 'Book Chapter' || editModalTitle === 'Online Dictionary Entry' || editModalTitle === 'Online News Article' || editModalTitle === 'Report' || editModalTitle === 'Website') && (
              <Form.Group controlId="formPublicationDate" className="row justify-content-evenly">
                <Form.Label className="col-md-3 pt-2 pl-5 zzz">Publication Date</Form.Label>
                  
                <div className="col-md-3">
                <Form.Control
                  type="number"
                  placeholder="Year"
                  style={{ marginRight: '20px' }}
                  value={publicationYear || editPublicationDate.year}
                  onChange={(e) => handleEditPublicationYearChange('year', e.target.value)}
                />{publicationYearError && <div style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{publicationYearError}</div>}
                </div>
                <Form.Control
                  className="col-md-2"
                  as="select"
                  placeholder="Month"
                  style={{ marginRight: '8px' }}
                  value={publicationMonth || editPublicationDate.month}
                  onChange={(e) => handleEditPublicationMonthChange("month", e.target.value)}    
                >
                  <option value="" disabled>
                    Month
                  </option>
                  {months.map((month) => (
                    <option key={month} value={month}>
                      {month}
                    </option>
                  ))}
                </Form.Control>
                <div className="col-md-3">
                <Form.Control
                  
                  type="number"
                  placeholder="Day"
                  value={publicationDay || editPublicationDate.day}
                  onChange={handleEditPublicationDayChange}    
                />{publicationDayError && <div style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{publicationDayError}</div>}
                 </div>
              </Form.Group>
                )}


             
  
              {(editModalTitle === "Webpage" || editModalTitle === 'Video' || editModalTitle === 'Online Dictionary Entry' || editModalTitle === 'Online News Article' || editModalTitle === 'Report' || editModalTitle === 'Website') && (

              <Form.Group controlId="formAccessDate" className="row justify-content-evenly">
                <Form.Label className="col-md-3 pt-2 pl-5 zzz">Access Date</Form.Label>
                <div className="col-md-3">
                <Form.Control
                  type="number"
                  placeholder="Year"
                  min={1}
                  style={{ marginRight: '20px' }}
                  value={accessYear || editAccessDate.year}
                  onChange={(e) => handleEditAccessYearChange("year", e.target.value)}
                />{accessYearError && <div style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{accessYearError}</div>}
                </div>
                <Form.Control
                  className="col-md-2"
                  as="select"
                  placeholder="Month"
                  style={{ marginRight: '8px' }}
                  value={accessMonth || editAccessDate.month}
                  onChange={(e) => handleEditAccessMonthChange("month", e.target.value)}    
                >
                  <option value="" disabled>
                    Month
                  </option>
                  {months.map((month) => (
                    <option key={month} value={month}>
                      {month}
                    </option>
                  ))}
                </Form.Control>
                <div className="col-md-3">
                <Form.Control

                  type="number"
                  placeholder="Day"
                  value={accessDay || editAccessDate.day}
                  onChange={handleEditAccessDayChange}    
                />{accessDayError && <div style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{accessDayError}</div>}
                </div>
              </Form.Group>
              )}


              {(editModalTitle === 'Book Chapter' || editModalTitle === "Book" || editModalTitle === 'Online News Article' || editModalTitle === 'Report' || editModalTitle === 'Website') && (
              <Form.Group controlId="formPublisher" className="row justify-content-evenly">
                <Form.Label className="col-md-3 pt-2 pl-5 zzz">Publisher</Form.Label>
                <Form.Control
                  className='col-md-8'
                  type="text"
                  value={editPublisher}
                  onChange={handleEditPublisher}
                />
              </Form.Group>
              )}



              {/* URL */}
              {(editModalTitle === "Webpage" || editModalTitle === 'Image' || editModalTitle === 'Video' || editModalTitle === "Journal Article" || editModalTitle === "Book" || editModalTitle === 'Book Chapter' || editModalTitle === 'Online Dictionary Entry' || editModalTitle === 'Online News Article' || editModalTitle === 'Report' || editModalTitle === 'Website') && (

              <Form.Group controlId="formURL" className="row justify-content-evenly">
                <Form.Label className="col-md-3 pt-2 pl-5 zzz">URL</Form.Label>
                <Form.Control
                  className='col-md-8'
                  type="text"
                  value={editUrl}
                  onChange={handleEditUrl}
                />
              </Form.Group>

                )}


            {(editModalTitle === 'Book Chapter' || editModalTitle === "Book" ) && (
              <Form.Group controlId="formISBN" className="row justify-content-evenly">
                <Form.Label className="col-md-3 pt-2 pl-5 zzz">ISBN</Form.Label>
                <Form.Control
                  className='col-md-8'
                  type="text"
                  value={editISBN}
                  onChange={handleEditISBN}
                />
              </Form.Group>
              )}


            {(editModalTitle === 'Book Chapter' || editModalTitle === "Book" ) && (
              <Form.Group controlId="formISSN" className="row justify-content-evenly">
                <Form.Label className="col-md-3 pt-2 pl-5 zzz">ISSN</Form.Label>
                <Form.Control
                  className='col-md-8'
                  type="text"
                  value={editISSN}
                  onChange={handleEditISSN}
                />
              </Form.Group>
              )}


              {(editModalTitle === 'Journal Article' ) && (
              <Form.Group controlId="formArticleNumber" className="row justify-content-evenly">
                <Form.Label className="col-md-3 pt-2 pl-5 zzz">Article Number or eLocater</Form.Label>
                <Form.Control
                  className='col-md-8'
                  type="text"
                  value={editArticleNumber}
                  onChange={handleEditArticleNumber}
                />
              </Form.Group>
              )}




              </Form>

          
            </div>
            </Modal>
  
      </>
    );
  };

  export default UpdateCitationModal;
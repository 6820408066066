import React, { useState } from "react";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { Card, CardContent, CardActions, Button, Typography, Box, Menu, MenuItem } from "@mui/material";
import "./SenderMessage.css";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
const SenderMessage = ({ message, deleteParticularMessage, handleDownload }) => {
  const dateTime = new Date(message.createdAt);
  const formattedDateTime = dateTime.toLocaleString();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };


  const handleClose = () => {
    setAnchorEl(null);
  };


  const renderMessageContent = () => {
    if (message.contentType === "file") {
      // Render content for file type message
      return (
        <div className="sendermessage">
          
          <Card sx={{ bgcolor:'#F2F1EB !important' , width:'100% !important'}} py={2}>
            <Box display='flex' justifyContent='end' py={0.5}>
              <MoreHorizIcon onClick={handleClick} />
              <Menu
                id="long-menu"
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    width: '20ch',
                  },
                }}
              >
                <MenuItem key="delete" onClick={deleteParticularMessage}>
                   Delete
                </MenuItem>
              </Menu>
            </Box>
            <Box display='flex' flexDirection='row' justifyContent='space-between' p={1}>
              <Typography>{message.fileName}</Typography>
              {
                message?.download ?
                  <CheckCircleIcon sx={{ fontSize: '40px', cursor: 'pointer' }} />
                  :
                  <DownloadForOfflineIcon sx={{ fontSize: '40px', cursor: 'pointer' }} onClick={handleDownload} />
              }            
            </Box>
            <Box py={1} display='flex' justifyContent='end'>
              <Typography variant="caption" fontSize='10px'>
                {formattedDateTime} 
              </Typography>
              {/* <DoneAllIcon sx={{ color: 'green' }} /> */}
          </Box>
        </Card>
        </div>
      );
    } else if (message.contentType === "audio") {
      // Render content for audio type message
      return (
        <div className="sendermessage">
          <Card sx={{ bgcolor: '#F2F1EB !important', width: '100% !important' }} py={2}>
            <Box display='flex' justifyContent='end' py={0.5}>
              <MoreHorizIcon onClick={handleClick}  />
              <Menu
                id="long-menu"
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    width: '20ch',
                  },
                }}
              >
                <MenuItem key="delete" onClick={deleteParticularMessage}>
                  Delete
                </MenuItem>
              </Menu>
            </Box>
            <Box display='flex' flexDirection='row' justifyContent='space-between' p={1}>
              <audio controls>
                <source src={message.content} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
            </Box>
            <Box py={1} display='flex' justifyContent='end'>
              <Typography variant="caption" fontSize='10px'>
                {formattedDateTime}
              </Typography>
            </Box>
          </Card>
        </div>
      );
    } else {
      return (
        <div className="sendermessage">
          <Box display='flex' justifyContent='end' py={0.5}>
            <MoreHorizIcon onClick={handleClick}  />
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  width: '20ch',
                },
              }}
            >
              <MenuItem key="delete" onClick={deleteParticularMessage}>
                Delete
              </MenuItem>
            </Menu>
          </Box>
          <p>{message.content}</p>
          <Box py={1} display='flex' justifyContent='end'>
            <Typography variant="caption" fontSize='10px'>
              {formattedDateTime}
            </Typography>
          </Box>
        </div>
      );
    }
  };

  return renderMessageContent();
};

export default SenderMessage;

import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  Box,
  Modal,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./FAQ.css";
import GotQuestions from "../static/GotQuestions.png";
import Nodata from "../static/Nodata.png";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import VideoModel from "./VideoModel";
import Grammar from "../static/Grammar.mp4";
import ParaphraserVideo from "../static/ParaphraserVideo.mp4";
import PlagiarismVideo from "../static/PlagiarismVideo.mp4";
import TeamsVideo from "../static/TeamsVideo.mp4";
import TranslatorVideo from "../static/TranslatorVideo.mp4";
import CoAuthor1Video from "../static/CoAuthor1Video.mp4";
import { sendQuestions } from "../services/userService";
import { toast } from "react-toastify";
import { getVideoUrl } from "../services/document";

const faqData = [
  {
    question: "How do I upload a document for grammar checking?",
    answer:
      "You can upload a document by clicking on the Upload button on the homepage, then selecting the file from your device.",
  },
  {
    question: "How does the grammar check feature work?",
    answer:
      "The grammar check feature scans your document for errors and provides suggestions for corrections in real-time.",
    videoUrl: "Grammar.mp4",
  },
  {
    question: "Can I customize the suggestions provided by the grammar check?",
    answer:
      "Yes, you can customize suggestions by adjusting the settings in the references section.",
  },
  {
    question: "How do I use the paraphrasing tool?",
    answer:
      "Paste your text into the paraphrasing tool and select the paraphrase button to get rewritten content.",
    videoUrl: "ParaphraserVideo.mp4",
  },
  {
    question:
      "What options are available for setting the tone in paraphrasing?",
    answer:
      "You can choose from various predefined tones like formal, casual, or professional from the tone settings.",
    videoUrl: "ParaphraserVideo.mp4",
  },
  {
    question: "How can I summarize a long document?",
    answer:
      "Upload your document and select the Summarize option to get a concise summary of your content.",
  },
  {
    question: "Is it possible to translate documents to multiple languages?",
    answer:
      "Yes, you can translate documents into multiple languages by using the Translator feature and selecting the desired language.",
  },
  {
    question: "How do I navigate to the blogs page?",
    answer:
      "You can find the blogs page in the main menu under the Resources section.",
  },
  {
    question: "Can I write and publish my own blogs?",
    answer:
      "Yes, you can write and submit your blogs for approval, and once approved they will be published on the platform.",
  },
  {
    question: "How do I create or join a team?",
    answer:
      "Navigate to the Teams page, where you can create a new team or join an existing one by invitation.",
    videoUrl: "TeamsVideo.mp4",
  },
  {
    question: "What features are available on the Teams page?",
    answer:
      "The Teams page allows for group chats, document sharing, and collaborative editing.",
    videoUrl: "TeamsVideo.mp4",
  },
  {
    question: "How do I check for plagiarism in my document?",
    answer: "Upload your document and use the Plagiarism Checks feature to scan for any copied content.",
    videoUrl: "PlagiarismVideo.mp4",
  },
  {
    question: "How do I save my document after editing?",
    answer:
      "Click on the Save button in the document editor to save your changes.",
  },
  {
    question: "Can I download my document after editing it on the platform?",
    answer:
      "Yes, you can download your edited document in various formats like PDF or Word.",
  },
  {
    question: "How do I get notified about changes or comments on document?",
    answer:
      "Enable notifications in your account settings to receive updates on changes or comments on your document.",
  },
  {
    question: "Is there a limit to the number of documents i can upload?",
    answer:
      "There is no limit to the number of documents you can upload; however storage space may vary based on your subscription plan.",
  },
  {
    question: "How do I access my previously uploaded documents?",
    answer:
      "Go to the My Documents section in your account to access all your uploaded documents.",
  },
  {
    question: "Can I collaborate with others on a document?",
    answer:
      "Yes, you can share your document with team members and collaborate in real-time.",
    videoUrl: "CoAuthor1Video.mp4",
  },
  {
    question: "How do I change my account settings?",
    answer:
      "Navigate to Account Settings in the main menu to update your preferences and personal information.",
  },
  {
    question: "Where can I find tutorials or help guides?",
    answer:
      "You can find the blogs page in the main menu under the Resources section.",
  },
];

const FAQ = () => {
  const [search, setSearch] = useState("");
  const [question, setQuestion] = useState("");
  const [open, setOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [noResultsDialogOpen, setNoResultsDialogOpen] = useState(false);
  const email = sessionStorage.getItem("currentUserEmail");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isDarkMode, setIsDarkMode] = useState(false);
  useEffect(() => {
      const updateTheme = () => {
        const theme = localStorage.getItem('ThemeSwitch');
        setIsDarkMode(theme === 'true');
      };
      updateTheme();
      window.addEventListener('storage', updateTheme);
      const originalSetItem = localStorage.setItem;
      localStorage.setItem = function (key, value) {
        originalSetItem.apply(this, arguments);
        if (key === 'ThemeSwitch') {
          updateTheme(); 
        }
      };
      return () => {
        window.removeEventListener('storage', updateTheme);
        localStorage.setItem = originalSetItem; 
      };
    }, []);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleQuestionChange = (e) => {
    setQuestion(e.target.value);
  };
  function arrayBufferToBase64(arrayBuffer) {
    let binary = '';
    const bytes = new Uint8Array(arrayBuffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }
  
  const handleOpen = async (fileName) => {
    try {
      const { blob, contentType } = await getVideoUrl(fileName);
      const arrayBuffer = await blob.arrayBuffer(); 
      const base64String = arrayBufferToBase64(arrayBuffer);
      const videoUrl = `data:${contentType};base64,${base64String}`;
      setVideoUrl(videoUrl);
      setOpen(true);
    } catch (error) {
      console.error("Error fetching video URL:", error);
      toast.error("Failed to retrieve video URL");
    }
  };
  
  const handleClose = () => {
    setOpen(false);
    setVideoUrl("");
  };
  const handleNodataClose = () => {
    setNoResultsDialogOpen(false);
    setManualClose(true); 
  };

  const handleSubmit = async () => {
    if (!question) {
      toast.warn("Please enter your question.");
    } else {
      let details = {
        userEmail: email,
        message: question,
      };
      console.log(details);
      let response = await sendQuestions(details);
      console.log(response);
      if (response.status === 200) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    }
    setQuestion("");
  };

  const filteredFaqs = faqData.filter((faq) =>
    faq.question.toLowerCase().includes(search.toLowerCase())
  );
  const [manualClose, setManualClose] = useState(false);

  useEffect(() => {
    if (filteredFaqs.length === 0 && !manualClose) {
      setNoResultsDialogOpen(true);
    }
  }, [filteredFaqs, manualClose]);

  useEffect(() => {
    if (filteredFaqs.length > 0) {
      setManualClose(false);
    }
  }, [filteredFaqs]);
  return (
    <Container className="faq-container">
      <div className="faqpage" style={{flexDirection: windowWidth > 767 ? "row" : "column-reverse"}}>
        <div className="leftcontent"  style={{width: windowWidth > 767 ? "50%" : "100%",padding:windowWidth<=767?"5px":'20px'}}>
          <Typography className="main-title" variant="h4" gutterBottom>
            Frequently Asked Questions
          </Typography>
          {windowWidth < 601 && 
          <div style={{color: isDarkMode?"white":"#1976d2", fontSize:"1.6rem", marginBottom: "15px"}}>
            Frequently Asked Questions
          </div>}
          <TextField
            label="Search"
            variant="outlined"
            fullWidth
            value={search}
            onChange={handleSearchChange}
            sx={{
              marginBottom: "20px",
              "& .MuiOutlinedInput-root": {
                borderRadius: "20px",
                backgroundColor: isDarkMode ? "#141414" : "",
                "& fieldset": {
                  borderColor: isDarkMode ? "gray" : "default", 
                  borderRadius: "20px",
                },
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon sx={{ color: isDarkMode ? "white" : "#1976d2" }} /> 
                </InputAdornment>
              ),
            }}
          />

          <div className="faqCards" style={{paddingRight: windowWidth > 767 ? "10px" : "0px"}}>
            {filteredFaqs.length > 0 ? (
              filteredFaqs.map((faq, index) => (
                <Accordion
                  key={index}
                  sx={{
                    position: "relative",
                    marginTop: "15px",
                    border: "1px solid #ccc",
                    backgroundColor: isDarkMode ? "#141414" : "white", // Darker shade close to black
                    borderRadius: "15px",
                    boxShadow: "rgba(0, 0, 0, 0.5) 0px 3px 8px",
                    overflow: "hidden",
                    "&::before": {
                      content: '""',
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "8px",
                      height: "100%",
                      backgroundColor: "#1976d2",
                      zIndex: 1,
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: isDarkMode ? "white" : "#1976d2", }} />}
                    sx={{
                      backgroundColor: isDarkMode ? "#141414" : "white", // Darker shade of summary
                      border: "1px solid #ccc",
                      padding: "10px 15px",
                      borderRadius: "15px",
                    }}
                  >
                    <Typography sx={{ color: isDarkMode ? "white" : "#1976d2", fontWeight: isDarkMode?'normal': "bold" }}>
                      {faq.question}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      padding: "15px",
                      backgroundColor: isDarkMode ? "#141414" : "white", // Darker shade for details
                      border: "1px solid #ccc",
                      borderRadius: "15px",
                    }}
                  >
                    <Typography sx={{ color: isDarkMode ? "white" : "black", fontWeight:isDarkMode?'normal':'' }}>{faq.answer}</Typography>
                    {faq.videoUrl && (
                      <button
                        className="sendButton"
                        style={{marginTop:'10px', width:'120px'}}
                        onClick={() => handleOpen(faq.videoUrl)}
                      >
                        View Demo
                      </button>
                    )}
                  </AccordionDetails>
                </Accordion>
              ))
            ) : (
              <Modal
                open={noResultsDialogOpen}
                onClose={handleNodataClose}
                aria-labelledby="no-results-title"
                aria-describedby="no-results-description"
              >
                <Box className="noSearchDialogContainer">
                  <Box className="noSearchDialogContent">
                    <img src={Nodata} className="noSearchImage" alt="No Data" />
                    <Typography id="no-results-title" className="noSearchTitle">
                      {"We couldn't find an answer to your question."}
                    </Typography>
                    <Typography
                      id="no-results-description"
                      className="NosearchSubtitle"
                      sx={{ color: isDarkMode?"lightgray":'' }}
                    >
                      Would you like to ask us directly? Please reach out to us
                      to submit your question.
                    </Typography>
                    <Box className="noSearchDialogActions">
                      <Button onClick={handleNodataClose} variant="outlined">
                        No Thanks
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Modal>
            )}
          </div>
        </div>
        <div className="rightcontent" style={{width: windowWidth > 767 ? "50%" : "100%", marginLeft: windowWidth > 767 ? "30px" : "0px",marginBottom: windowWidth > 767 ? "0px" : "20px"}}>
          <div style={{display: windowWidth > 767 ? "block" : "none"}}>
            <img src={GotQuestions} alt="FAQ Image" className="faqImage" />
          </div>
          <Box className="questionBox" style={{width: windowWidth > 767 ? "90%" : "100%",}}>
            <Typography className="questionTitle" style={{fontSize: windowWidth > 767 ? "25px" : "2.125rem" }}>Got Questions?</Typography>
            <Typography
              className="questionSubtitle"
              variant="subtitle1"
              gutterBottom
            >
              Reach out anytime! Our team is ready to assist.
            </Typography>
            <TextField
              label="Enter your question"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              value={question}
              onChange={handleQuestionChange}
              className="questionField"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: isDarkMode ? "gray" : "default", 
                  },
                }
              }}
            />
          </Box>
          <div className="sendbtn" style={{ width: windowWidth > 767 ? undefined : "100%"}}>
            <button
              onClick={handleSubmit}
              className="sendButton"
              style={{width : windowWidth > 767 ? "110px" : "100%"}}
            >
              Send
            </button>
          </div>
        </div>
      </div>
      <VideoModel open={open} handleClose={handleClose} videoUrl={videoUrl} isDarkMode={isDarkMode} />
    </Container>
  );
};

export default FAQ;

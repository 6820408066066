import React, { useState, useEffect } from "react";
import { PieChart } from "react-minimal-pie-chart";
import SelectDropdownMui from "./SelectDropdownMui";


const PieChartComp = ({ chartHeading, pieChartData, isSelectDropdownShown, pieChartSelectOptions, selectDropdownDisabled,
    pieChartHandleChange, pieChartValue, pieChartSelectLabel, isDarkMode
}) => {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
 
    // Update windowWidth on resize
    useEffect(() => {
      const handleResize = () => setWindowWidth(window.innerWidth);
   
      window.addEventListener('resize', handleResize);
      // if(window.innerWidth <768){
      //   setSidebarOpen(!isSidebarOpen)
      // }
   
      return () => window.removeEventListener('resize', handleResize);
    }, []);


    console.log("PieChartCompPieChartData", pieChartData)
    const data2 = [
        { title: "Onesdsad", value: 20, color: "blue" },
        { title: "Two", value: 60, color: "skyblue" },
        { title: "Three", value: 20, color: "green" }
    ]

    // const data = [
    //     { id: 0, value: 10, label: 'series A eries A eries A' },
    //     { id: 1, value: 15, label: 'series B' },
    //     { id: 2, value: 20, label: 'series C' },
    // ];

    const emptyList = [{ name: "Empty", value: "Empty" }]

    return (
        <>
            <div
                // className="container-fluid"
                style={{
                    backgroundColor: isDarkMode?"#2A2A2A": "#ffffff",
                    color:isDarkMode?"#ECE6F0": "",
                    borderRadius: "8px",
                    // boxShadow: "2px 2px 5px -2px #000000",
                    padding: "2px",
                    height:windowWidth < 768 ? "28rem" : "20rem",
                    // width:"50vw"
                    // height: pieChartData.length > 0 && pieChartData.some((obj)=> obj.value > 0) && "280px"
                }}
            >
                <div className="row p-2">
                    <div className="col-md-7">
                        <h6 className='p-2'
                            style={{
                                color:isDarkMode?"#ECE6F0": "#171717",
                                fontFamily: "Poppins, sans-serif",
                                fontWeight: 600,
                                fontSize: "0.9rem"
                            }}
                        >{chartHeading}</h6>
                    </div>
                    <div className="col-md-5">
                        {isSelectDropdownShown &&
                            <SelectDropdownMui
                                optionsList={pieChartSelectOptions}
                                onChange={pieChartHandleChange}
                                value={pieChartValue}
                                selectLabel={pieChartSelectLabel}
                                disabled={selectDropdownDisabled}
                                isDarkMode={isDarkMode}
                            />
                        }
                    </div>
                </div>
                {pieChartData.length > 0 && pieChartData.some((obj) => obj.value > 0) ?
                    <div className="row">
                        <div className="col-md-8 px-4">
                            <div
                            style={{height:"18rem"}}
                            >
                                <PieChart
                                    viewBoxSize={[130, 130]}
                                    data={pieChartData}
                                    label={(props) => `${props.dataEntry["value"]}%`}
                                    labelStyle={{
                                        fontSize: "0.46rem",
                                        fontFamily: "sans-serif",
                                        fill: "#ffffff"
                                    }}
                                    labelPosition={70}
                                    animate
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
                                {pieChartData.map((entry, index) => (
                                    <li key={`item-${index}`} style={{ display: "flex", alignItems: "center" }}>
                                        <svg width="20" height="20">
                                            <circle cx="10" cy="10" r="8" fill={entry.color} />
                                        </svg>
                                        {/* <span style={{ marginLeft: "8px" }}>{entry.value}</span> */}
                                        <span style={{ marginLeft: "8px", fontSize: "0.9rem" }}>{`${entry.title}`}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    :
                    <div
                        className="p-3"
                        style={{ fontWeight: "bold" }}>
                        No data available
                    </div>
                }
            </div>
        </>
    )
}

export default PieChartComp

import React, { useRef } from 'react';
import { useState } from 'react';
import Switch from '@mui/material/Switch';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CachedIcon from '@mui/icons-material/Cached';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import MicIcon from '@mui/icons-material/Mic';
import SearchIcon from '@mui/icons-material/Search';
import { getSearchApi } from '../../../services/coAuthorService';
// import { Button } from 'react-bootstrap';
import Loader from '../../../utils/loader';
import { toast } from 'react-toastify';
import { useSelector } from "react-redux";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { useEffect } from 'react';
import { Button, IconButton } from '@material-ui/core';
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { getSearchHistory, deleteSearchSuggestion } from '../../../services/document';
import DeleteIcon from '@mui/icons-material/Delete';
import { option_ } from '../../Editor/utilize';
import HistoryIcon from '@mui/icons-material/History';
const label = { inputProps: { 'aria-label': 'Switch demo' } };


const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'blue',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

export const CoAuthor_Searchcomponent = (props) => {
  const userDetails = useSelector((user) => user)
  const userID = props.trial ? props.userId : userDetails.user.currentUser._id
  const email = sessionStorage.getItem("currentUserEmail");
  console.log(email, "email from 1");
  const [isAskActive, setIsAskActive] = useState(true);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [activeButton, setActiveButton] = useState("ask");
  const [showSerchTextValue, setSerchTextValue] = useState()
  const [showQuestiontextevalue, setQuestiontextValue] = useState()
  const [showSearchResponseContainer, setSearchResponseContainer] = useState(false)
  const [showSearchResponse, setSearchResponse] = useState({
    title: '',
    description: '',
    author: '',
    website: ''
  });
  const [showQuestionResponse, setQuestionResponse] = useState(null)
  const [loading, setLoading] = useState(false);
  const [authorName, setAuthorName] = useState()
  const [saveToNotesHovered, setSaveToNotesHovered] = useState({});
  const [copyIconHovered, setCopyIconHovered] = useState({});
  const { transcript, resetTranscript } = useSpeechRecognition();
  const [micOutput, setmicOutput] = useState()
  const [isRecording, setIsRecording] = useState(false);
  const [searchHistoryForSearchOption, setSearchHistoryForSearchOption] = useState([]);
  const [searchHistoryForAskOption, setSearchHistoryForAskOption] = useState([]);
  const [showHistory, setShowHistory] = useState(false);
  const inputRef = useRef(null);
  const [hoverIndex, setHoverIndex] = useState(null);


  const [selectedWebsite, setSelectedWebsite] = useState("");


  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [isDarkMode, setIsDarkMode] = useState(false);
  useEffect(() => {
      const updateTheme = () => {
        const theme = localStorage.getItem('ThemeSwitch');
        setIsDarkMode(theme === 'true');
      };
      updateTheme();
      window.addEventListener('storage', updateTheme);
      const originalSetItem = localStorage.setItem;
      localStorage.setItem = function (key, value) {
        originalSetItem.apply(this, arguments);
        if (key === 'ThemeSwitch') {
          updateTheme(); 
        }
      };
      return () => {
        window.removeEventListener('storage', updateTheme);
        localStorage.setItem = originalSetItem; 
      };
    }, []);

  // Update windowWidth on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);
    // if(window.innerWidth <768){
    //   setSidebarOpen(!isSidebarOpen)
    // }

    return () => window.removeEventListener('resize', handleResize);
  }, []);


  // Handle dropdown change
  const handleDropdownChange = (event) => {
    setSelectedWebsite(event.target.value);
  };

  // Handle button click to open the selected website
  const handleButtonClick = () => {
    if (selectedWebsite) {
      const url = selectedWebsite.includes('https') ? selectedWebsite : `https://${selectedWebsite}`;
      window.open(url, "_blank");
    }
  };







  const handleSaveToNotesMouseEnter = (index) => {
    setSaveToNotesHovered(prevState => ({ ...prevState, [index]: true }));
  };

  const handleSaveToNotesMouseLeave = (index) => {
    setSaveToNotesHovered(prevState => ({ ...prevState, [index]: false }));
  };

  const handleCopyIconMouseEnter = (index) => {
    setCopyIconHovered(prevState => ({ ...prevState, [index]: true }));
  };

  const handleCopyIconMouseLeave = (index) => {
    setCopyIconHovered(prevState => ({ ...prevState, [index]: false }));
  };

  const handleCssClick = (button) => {
    if (button === 'ask') {
      if (isAskActive === true) {
        setIsAskActive((prev) => prev);
      } else {
        setIsAskActive((prev) => !prev);
      }
      setIsSearchActive(false); // Reset Search
      setActiveButton(button)
    } else if (button === 'search') {
      if (isSearchActive === true) {
        setIsSearchActive((prev) => prev);
      } else {
        setIsSearchActive((prev) => !prev);
      }
      setIsAskActive(false); // Reset Ask
      setActiveButton(button)
    }
  };


  const handleSerchText = (e) => {
    let micValue = e.target.value
    setmicOutput(micValue)
    resetTranscript()

    // alert(e.target.value)
  }

  const handleQuestionText = (e) => {
    setQuestiontextValue(e.target.value)
  }

  const handleQuestionApi = async () => {
    try {
      setShowHistory(false)
      let data = {
        text: showQuestiontextevalue,
        type: "search",
        option: "askAQuestion",
        userId: userID,
        isCoAuthor: true
        // text: `"Provide response for this question"${showQuestiontextevalue}`
      };
      setLoading(true)
      const response = await getSearchApi(data)
      if (response) {
        console.log(response, "backend response ")
        setQuestionResponse(response.output)
        setLoading(false)
      } else {
        console.log("response of backend ")
      }
    }
    catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const handleSearchApi = async () => {
    try {
      setShowHistory(false)
      let data = {
        // text: `"Please generate the citation references for the following text and output should as per the following schema where corrections is an array schema -{"replacements": {"corrections": [{"Title": "<<Title>>","Description": <<Description of the text>>,"Author": <<Name of the Author>>,"Website": "<<URL reference of the provided text>>""}]}} text -"${showSerchTextValue}`
        // text: `Please generate the citation references for the following text. The output should follow this schema where corrections is an array: {"replacements": {"corrections": [{"Title": "<<Title>>","Description": "<<Description of the text>>","Author": "<<Name of the Author>>","Website": "<<URL reference of the provided text>>"}]}}. Text -"${micOutput}"`,
        text: `${micOutput}`,
        type: "search",
        option: "searchPrompt",
        userId: userID,
        isCoAuthor: true
      };


      setLoading(true)
      const response = await getSearchApi(data);
      console.log("web", response)
      if (response.output.length > 0) {
        // Assuming you want the first correction from the array
        const firstCorrection = response.output[0];
        // const secondCorrection = JSON.parse(firstCorrection)
        const secondCorrection = JSON.parse(firstCorrection);
        const thirdCorrection = secondCorrection.replacements.corrections[0]
        const { Title, Description, Author } = thirdCorrection
        const Website1 = response.url
        console.log("webbbb   ", Website1)
        console.log(secondCorrection, "firstCorrection")
        // Update the state with the extracted data
        setSearchResponse({
          title: Title,
          description: Description,
          author: Author,
          website: Website1
        });
        setSearchResponseContainer(true)
        setSerchTextValue()
        setLoading(false)
      } else {
        console.log("Invalid or missing data in the API response");
      }
    } catch (error) {
      console.log(error);
      setLoading(false)
    }
  };



  // const handleSearchApi = async ()=>{
  //   try{
  //    let data = {
  //       text: `"Please generate the citation references for the following text and output should  as per the following schema where corrections is an array schema -{"replacements": {"corrections": [{"Title": "<<Title>>","Description": <<Description of the text>>,"Author": <<Name of the Author>>,"Website": "<<URL reference of the provided text>>""}]}} text - "${showSerchTextValue}`
  //     };
  //     const  response =  await getSearchApi(data)
  //     if(response){
  //       console.log(response,"backend response ")
  //       setSearchResponse(response.output)
  //     }else{
  //       console.log("response of backend ")
  //     }
  //   }
  //   catch(error){
  //     console.log(error)
  //   }
  // }

  const citeAuthorName = () => {
    if (showSearchResponseContainer && showSearchResponse.author) {
      props.autherName(showSearchResponse.author)
      console.log(showSearchResponse.author, "author name")
    }
    else {
      toast.info('Author name not found ')
    }
  }

  //qusetions response  copy text
  const handleCopyText = (text) => {
    let sentence = text
    if (sentence == "") {
      toast.warn(`You don't have text, to copy`);
      return;
    }
    navigator.clipboard.writeText(sentence).then(
      function () {
        console.log("Async: Copying to clipboard was successful!");
        if (sentence) toast.info("Your text Copied!");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  }

  const handleAllCopyText = (textArray) => {

    // Join the array items with two new lines between them
    const combinedText = textArray?.join('\n\n');

    // Copy the combined text to the clipboard
    navigator.clipboard.writeText(combinedText).then(
      function () {
        console.log("Async: Copying to clipboard was successful!");
        toast.info("Your text has been copied!");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  }

  //qusetions response save to notes
  const saveToNotes = async (text) => {
    try {
      console.log(email, text, "text");
      let sessionData = sessionStorage?.getItem('notesData') || "";
      // sessionData = sessionData + "\n\n" + text;
      sessionData = sessionData === "" ? text : `${sessionData}\n_______________________________________________________\n\n${text}`;
      sessionStorage.setItem('notesData', sessionData);
      toast.info(`Notes saved successfully.`);

    } catch (error) {
      console.log(error);
    }
  }

  const saveAllToNotes = async (textList) => {
    try {

      console.log(email, textList, "textList");

      // Join the list items into a single string with each item on a new line
      const combinedText = textList.join('\n\n');

      let sessionData = sessionStorage.getItem('notesData') || "";

      // If sessionData is not empty, append a separator before the new content
      sessionData = sessionData === "" ? combinedText : `${sessionData}\n_______________________________________________________\n\n${combinedText}`;

      sessionStorage.setItem('notesData', sessionData);

      toast.info("All notes saved successfully.");
    } catch (error) {
      console.log(error);
    }
  }


  // Copy text for search response
  const copySearchResponse = () => {
    const { title, description, author, website } = showSearchResponse;
    const textToCopy = `Title: ${title}\nDescription: ${description}\nAuthor: ${author}\nWebsite: ${website}`;
    copyToClipboard(textToCopy);
  }

  // Save to notes for search response

  const searchResponseSaveToNotes = async () => {
    const { title, description, author, website } = showSearchResponse;

    // Ensure website is treated as a string or an array
    const websiteArray = Array.isArray(website) ? website : website.split(',');
    const formattedWebsites = websiteArray.map((url, index) => `\n\n ${index + 1}. ${url.trim()}`).join('');

    const textToSave = `_______________________________________________________\n\nTitle: ${title}\n\nDescription: ${description}\n\nAuthor: ${author}\n\nWebsite:${formattedWebsites}`;
    await saveToNotesForSearch(textToSave);
  };


  // Helper function to copy text to clipboard
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      function () {
        console.log("Async: Copying to clipboard was successful!");
        if (text) toast.info("Your text Copied!");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  }

  // Helper function to save text to notes
  const saveToNotesForSearch = async (text) => {
    try {
      console.log(email, text, "text");
      let sessionData = sessionStorage.getItem('notesData') || "";
      // sessionData = sessionData + "\n\n" + text;
      sessionData = sessionData === "" ? text : `${sessionData}\n\n${text}`;
      sessionStorage.setItem('notesData', sessionData);
      toast.info(`Notes saved successfully.`);
    } catch (error) {
      console.log(error);
    }
  }

  /////////////////////////////////////////MIC FUNCTIONALITY/////////////////////////////

  const handleMicClick = () => {
    if (SpeechRecognition.browserSupportsSpeechRecognition()) {
      if (isRecording) {
        SpeechRecognition.stopListening();

        // setmicOutput(transcript); // Use the transcript when recording stops
      } else {
        SpeechRecognition.startListening({ continuous: true });
      }
      setIsRecording(!isRecording);
    } else {
      toast.error("Speech recognition is not supported by your browser.");
    }
  };


  const handleTranscriptChange = () => {
    // This function will be called whenever the transcript changes
    if (transcript) {
      setmicOutput(transcript);
    }
  };

  const fetchSearchHistory = async () => {
    const option = activeButton === 'ask' ? 'askAQuestion' : 'searchPrompt';
    console.log(option, "option");
    let data = {
      option
    }
    try {
      const response = await getSearchHistory(data)
      console.log(response, "response123");
      if (option === 'askAQuestion') {
        setSearchHistoryForAskOption(response.data.askQuestionInputs);
      } else {
        setSearchHistoryForSearchOption(response.data.searchInputs);
      }
      setShowHistory(true);

    } catch (error) {
      console.error('Failed to fetch search history:', error);
    }
  };
  console.log(searchHistoryForSearchOption, "searchhistory");
  console.log(searchHistoryForAskOption, "searchAskHistory");


  const handleSelectHistoryItem = (item) => {
    console.log(item, "item");
    if (isSearchActive) {
      setmicOutput(item)
    }
    if (isAskActive) {
      setQuestiontextValue(item);
    }
    setShowHistory(false);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setShowHistory(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [inputRef]);


  const handleRemoveSearchHistory = async (searchString) => {
    try {
      let data;
      let updateFunction;
      if (isSearchActive) {
        data = {
          option: "searchPrompt",
          searchString
        }
        updateFunction = setSearchHistoryForSearchOption;
      }
      if (isAskActive) {
        data = {
          option: "askAQuestion",
          searchString
        }
        updateFunction = setSearchHistoryForAskOption;
      }
      const response = await deleteSearchSuggestion(data)
      console.log(response, "res from delete")
      updateFunction(prevHistory => prevHistory.filter(item => item !== searchString));
      toast.success("Search suggestion removed successfully.")
    } catch (error) {
      toast.error('Internal server error.')
    }
  }


  const handleMouseEnter = index => {
    setHoverIndex(index);
  };

  const handleMouseLeave = () => {
    setHoverIndex(null);
  };

  useEffect(() => {
    // props.setTrackIsModalOpen(true)
    handleTranscriptChange();
    console.log(transcript, "transcript");
  }, [transcript]);

  return (
    <div>
      <Loader loading={loading} />
      <div>
        {/* Content to be displayed when 'Research' is clicked */}
        <div className="button-container" style={{ width: windowWidth < 500 ? "70%" : "", fontSize: windowWidth < 500 ? "8px" : "13px" }}>
          <button
            // style={{width:windowWidth < 500 ? "100px" : ""}}
            // className={activeButton === 'ask' ? 'active' : ''}
            className={`my-button ${activeButton === 'ask' ? 'active' : ''}`}
            onClick={() => handleCssClick('ask')}
            style={{ padding: activeButton === "ask" ? "3px" : "", }}
          >
            Ask a question
          </button>
          <button
            // style={{width:windowWidth < 500 ? "120px" : ""}}
            // className={activeButton === 'search' ? 'active' : ''}
            className={`my-button ${activeButton === 'search' ? 'active' : ''}`}
            onClick={() => handleCssClick('search')}
          >
            Search
          </button>
        </div>

        {isAskActive &&
          <div>
            {/* <div className='Switch_css'>
              <div>Academic</div><div><Switch {...label} defaultChecked /></div>
            </div> */}

            <div className='Input_div' style={{ position: "relative", zIndex: 10 }} ref={inputRef}>
              <input
                className='darkSearchInput'
                style={{
                  margin: "auto",
                  width: props.mobileView ? '100%' : '406px',
                  padding: '10px 25px 10px 5px',
                  paddingRight: '25px',
                  boxSizing: 'border-box',
                }}
                onChange={handleQuestionText}
                placeholder='Ask Your question'
                onFocus={fetchSearchHistory}
                value={showQuestiontextevalue}
              />
              {showHistory && searchHistoryForAskOption.length > 0 && (
                <div className="history-dropdown" style={{
                  position: "absolute",
                  width: "100%",
                  top: "100%",
                  left: 0,
                  padding: "6px",
                  zIndex: 1000,
                  borderRadius: "10px",
                  boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                  background: "white",
                }}>
                  {searchHistoryForAskOption.map((item, index) => (
                    <div key={index} style={{
                      display: "flex",
                      marginBottom: "3px",
                      padding: "5px",
                      justifyContent: "space-between",
                      alignItems: "center",
                      backgroundColor: hoverIndex === index ? '#f0f0f0' : 'transparent'
                    }}
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={handleMouseLeave}>
                      <div onClick={() => handleSelectHistoryItem(item)} style={{ cursor: "pointer" }}>
                        <HistoryIcon
                          className="point mr-3"
                          style={{ fontSize: "18px", color: hoverIndex === index ? 'black' : 'gray' }}
                        />
                        {item}
                      </div>
                      <LightTooltip title="Delete">
                        <DeleteIcon
                          className="point ml-2"
                          style={{ fontSize: "18px", color: "red", cursor: "pointer" }}
                          onClick={() => handleRemoveSearchHistory(item)}
                        />
                      </LightTooltip>
                    </div>
                  ))}
                </div>
              )}
              {showQuestiontextevalue &&
                <div onClick={() => { setQuestiontextValue('') }} style={{
                  position: "absolute",
                  top: "11px",
                  right: "50px",
                  width: "18px",
                  height: "18px",
                  fontSize: "10px",
                  backgroundColor: "#1976D2",
                  color: "white",
                  borderRadius: "10px",
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                  <CloseIcon style={{ cursor: "pointer" }} />
                </div>
              }
              <div onClick={() => { handleQuestionApi() }} style={{
                position: "absolute",
                top: "11px",
                right: "15px",
                width: "18px",
                height: "18px",
                fontSize: "10px",
                backgroundColor: "#1976D2",
                color: "white",
                borderRadius: "10px",
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                <ArrowForwardIcon style={{ cursor: "pointer" }} />
              </div>
            </div>
            {/* <div className='Suggetion_div'>
              <div style={{ fontWeight: "500" }}>
                Suggestions
              </div>
              <div style={{ width: "24px", height: "24px" }}>
                <CachedIcon />
              </div>
            </div> */}

            <div className='Service_bottom' >
              {
                showQuestionResponse && showQuestionResponse.length > 0 && (
                  <>
                    <div style={{ height: '50px', display: 'flex', justifyContent: 'space-between' }}>
                      <div>
                        {/* <LightTooltip title="Save All to notes"> */}
                        <Button
                          className='whiteText'
                          style={{
                            fontWeight: "bold",
                            border: "none",
                            backgroundColor: "transparent", // Set the default background color
                          }}
                          onMouseEnter={(e) => (e.target.style.backgroundColor = "#3DED97")}
                          onMouseLeave={(e) => (e.target.style.backgroundColor = "transparent")}
                          onClick={() => saveAllToNotes(showQuestionResponse)}
                        >
                          Save All to notes
                        </Button>
                        {/* </LightTooltip> */}

                      </div>
                      <div>

                        <LightTooltip title="Copy All">
                          <Button
                            style={{
                              fontWeight: "bold",
                              color: "black",
                              border: "none",
                              backgroundColor: "transparent",
                            }}
                            // title='Copy'
                            onMouseEnter={(e) => (e.target.style.backgroundColor = "#3DED97")}
                            onMouseLeave={(e) => (e.target.style.backgroundColor = "transparent")}
                            onClick={() => handleAllCopyText(showQuestionResponse)}
                          >
                            <ContentCopyIcon style={{ color: "blue" }} />
                          </Button>
                        </LightTooltip>

                      </div>
                    </div>
                    {showQuestionResponse.map((data, index) => (
                      <div className='queResponse bgGray blackBorder' key={index} style={{ width: "90%" }}>
                        <p className='whiteText'>{data}</p>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: "2px 0px" }}>
                          <LightTooltip title="Save to notes">
                            <Button
                              className='whiteText'
                              style={{
                                fontWeight: "bold",
                                backgroundColor: saveToNotesHovered[index] ? '#3DED97' : 'inherit',
                                transition: "background-color 0.3s",
                                border: "none"
                              }}
                              // title="Save to notes"
                              onClick={() => saveToNotes(data)}
                              onMouseEnter={() => handleSaveToNotesMouseEnter(index)}
                              onMouseLeave={() => handleSaveToNotesMouseLeave(index)}
                            >
                              Save to notes
                            </Button>
                          </LightTooltip>
                          <LightTooltip title="Copy">
                            <Button
                              style={{
                                fontWeight: "bold",
                                backgroundColor: copyIconHovered[index] ? '#3DED97' : 'inherit',
                                transition: "background-color 0.3s",
                                color: "black",
                                border: "none"
                              }}
                              // title='Copy'
                              onClick={() => handleCopyText(data)}
                              onMouseEnter={() => handleCopyIconMouseEnter(index)}
                              onMouseLeave={() => handleCopyIconMouseLeave(index)}
                            >
                              <ContentCopyIcon style={{ color: "blue" }} />
                            </Button>
                          </LightTooltip>
                        </div>
                      </div>
                    )

                    )
                    }
                  </>
                )

              }
            </div>
          </div>
        }

        {isSearchActive &&
          <>
            <div>
              <div className='Input_div' style={{ marginTop: "40px", position: 'relative' }} ref={inputRef}>
                <input
                  className='darkSearchInput'
                  style={{
                    margin: "auto",
                    width: props.mobileView ? '100%' : '406px',
                    padding: '10px 25px 10px 5px',
                    paddingRight: '25px',
                    boxSizing: 'border-box',
                  }}
                  value={micOutput}
                  onChange={handleSerchText}
                  placeholder='Search anything or paste the url  '
                  onFocus={fetchSearchHistory}
                />
                {showHistory && searchHistoryForSearchOption.length > 0 && (
                  <div className="history-dropdown"
                    style={{
                      position: "absolute",
                      width: "100%",
                      top: "100%",
                      left: 0,
                      padding: "6px",
                      zIndex: 1000,
                      borderRadius: "10px",
                      boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                      background: "white",
                    }}
                  >
                    {searchHistoryForSearchOption.map((item, index) => (
                      <div key={index} style={{
                        display: "flex",
                        marginBottom: "3px",
                        padding: "6px",
                        justifyContent: "space-between",
                        alignItems: "center",
                        backgroundColor: hoverIndex === index ? '#f0f0f0' : 'transparent'
                      }}
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={handleMouseLeave}>
                        <div onClick={() => handleSelectHistoryItem(item)} style={{ cursor: "pointer" }}>
                          <HistoryIcon
                            className="point mr-3"
                            style={{ fontSize: "18px", color: hoverIndex === index ? 'black' : 'gray' }}
                          />
                          {item}
                        </div>
                        <LightTooltip title="Delete">
                          <DeleteIcon
                            className="point ml-2"
                            style={{ fontSize: "18px", color: "red", cursor: "pointer" }}
                            onClick={() => handleRemoveSearchHistory(item)}
                          />
                        </LightTooltip>
                      </div>
                    ))}
                  </div>
                )}
                {/* <div style={{ position: "absolute", top: "10px", left: "6px", fontSize: "8px" }}>
                  <  SearchIcon />
                </div> */}

                <IconButton style={{ position: "absolute", right: "15px", fontSize: "8px", color: isRecording ? 'red' : 'grey', borderColor: 'white' }} onClick={handleMicClick} >
                  <LightTooltip title="Mic">
                    <MicIcon />
                  </LightTooltip>
                </IconButton>
                {micOutput &&
                  <IconButton style={{ position: "absolute", right: "45px", fontSize: "8px", color: 'grey', borderColor: 'white', top: '-2px' }} onClick={() => setmicOutput('')} >
                    <CloseIcon />
                  </IconButton>
                }
                {/* <IconButton onClick={handleMicClick} style={{ position: "absolute", top: "10px", right: "15px", fontSize: "8px" }}>
                  <MicIcon />
                </IconButton> */}




              </div>

              <div style={{ width: "90%", margin: "auto", marginTop: "10px", display: 'flex', justifyContent: 'center' }}>
                <button className='searchBtn' onClick={() => { handleSearchApi() }} style={{ width: "120px", color: "#1976D2", border: "1px solid #1976D2", borderRadius: "12px" }}>Search</button>
              </div>
            </div>

            <div className='Service_bottom' >

              {
                showSearchResponseContainer && (

                  <div className='Suggestion_tag_div bgGray blackBorder' style={{ display: 'flex', flexDirection: "column", margin: '10px', width: props.mobileView ? '95%' : '' }} >
                    <p className='whiteText' style={{ fontSize: props.mobileView ? '12px' : "15px", fontWeight: "600", fontFamily: "serif", margin: '5px' }}> {showSearchResponse.title}</p>
                    <p className='whiteText' style={{ fontSize: props.mobileView ? '10px' : "", margin: '5px' }}> {showSearchResponse.description}</p>
                    <p className='whiteText' style={{ fontSize: props.mobileView ? '10px' : "", margin: '5px' }}> {showSearchResponse.author}</p>


                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                      {/* Dropdown to select a website */}
                      <select
                        onChange={handleDropdownChange}
                        value={selectedWebsite}
                        style={{
                          padding: '8px',
                          borderRadius: '4px',
                          border: '1px solid #1976D2',
                          backgroundColor: isDarkMode ? "darkgray" : 'white',
                          color: isDarkMode ? 'white' : 'green',
                          margin: '5px',
                          cursor: 'pointer',
                          fontSize: props.mobileView ? '11px' : '14px',
                          width: props.mobileView ? '75%' : '200px',
                        }}
                      >
                        <option value="">Select a website</option>
                        {showSearchResponse.website.map((site, index) => (
                          <option key={index} value={site}>
                            {site}
                          </option>
                        ))}
                      </select>

                      {/* Explore button */}
                      <Button
                        style={{ width: "120px", backgroundColor: isDarkMode ? "darkgray" : "white", color:isDarkMode ? "white" : "green", border: "1px solid #1976D2", borderRadius: "8px", cursor: 'pointer', margin: '5px', fontSize: props.mobileView ? '11px' : '' }}
                        onClick={handleButtonClick}
                      >
                        Explore
                      </Button>
                    </div>

                    <Button onClick={() => { citeAuthorName() }} style={{ width: "120px", backgroundColor: isDarkMode ? "darkgray" : "white", color:isDarkMode ? "white" : "green", border: "1px solid #1976D2", margin: '5px', fontSize: props.mobileView ? '11px' : '' }}>Cite</Button>
                    <Button onClick={() => { searchResponseSaveToNotes(showSearchResponse) }} style={{ width: "120px", backgroundColor: isDarkMode ? "darkgray" : "white", color:isDarkMode ? "white" : "green", border: "1px solid #1976D2", margin: '5px', fontSize: props.mobileView ? '11px' : '' }}>Save to notes</Button>
                    <Button onClick={() => { copySearchResponse() }} style={{ width: "120px", backgroundColor: isDarkMode ? "darkgray" : "white", color:isDarkMode ? "white" : "green", border: "1px solid #1976D2", margin: '5px', fontSize: props.mobileView ? '11px' : '' }}>Copy</Button>
                  </div>

                )
              }
            </div>

          </>
        }


      </div>
    </div>
  )
}

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import DocumentList from "../components/DocumentList";
import PaymentReport from "./PaymentReport";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

// Update styles to handle dark mode
const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    flexGrow: 1,
    backgroundColor: props.isDarkMode ? "black" : theme.palette.background.paper,
    color: props.isDarkMode ? "white" : "black",
  }),
  tabs: (props) => ({
    backgroundColor: props.isDarkMode ? "black" : theme.palette.background.paper,
    "& .MuiTabs-indicator": {
      backgroundColor: props.isDarkMode ? "#007bff" : theme.palette.primary.main, 
    },
  }),
  tab: (props) => ({
    color: props.isDarkMode ? "white" : theme.palette.text.primary, 
    "&:hover": {
      color: props.isDarkMode ? "#007bff" : theme.palette.primary.main, 
    },
    "&.Mui-selected": {
      color: props.isDarkMode ? "#007bff" : theme.palette.primary.main, 
      borderBottom: props.isDarkMode ? "2px solid #007bff" : "none", 
      border: "none", 
    },
  }),
}));

export default function SimpleTabs(props) {
  const { data } = props;
  const [value, setValue] = React.useState(0);
  const [searchVal, setsearchVal] = React.useState("");
  const [isDarkMode, setIsDarkMode] = useState(false);
  
  const classes = useStyles({ isDarkMode }); 

  useEffect(() => {
    const updateTheme = () => {
      const theme = localStorage.getItem("ThemeSwitch");
      setIsDarkMode(theme === "true");
    };
    updateTheme();
    window.addEventListener("storage", updateTheme);
    
    const originalSetItem = localStorage.setItem;
    localStorage.setItem = function (key, value) {
      originalSetItem.apply(this, arguments);
      if (key === "ThemeSwitch") {
        updateTheme();
      }
    };
    
    return () => {
      window.removeEventListener("storage", updateTheme);
      localStorage.setItem = originalSetItem;
    };
  }, []);

  useEffect(() => {
    console.log(data);
    setsearchVal(data);
  }, [data]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          className={classes.tabs}
          indicatorColor="primary"
          textColor="primary"
          aria-label="simple tabs example"
        >
          <Tab className={classes.tab} label="Documents" {...a11yProps(0)} />
          <Tab className={classes.tab} label="Payments" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <DocumentList searchDetail={data} isDarkMode={isDarkMode} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <PaymentReport />
      </TabPanel>
    </div>
  );
}
